import React, {
  useState, useEffect
} from "react";
import { Link } from "react-router-dom";
import edit from "../Images/edit.png";
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Elogo from '../Images/wp_images/Eternaview-Logo1.png';
import datingBlueLogo from "../Images/wp_images/datingBlueLogo.png";

import { register } from "../redux/actions";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login_user_info,save_token } from "../redux/actions";
import FacebookPixel from "../MetaComponents/Facebookpixel";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function RegisterComponent(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state);
  const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
  //const [showLoader, setShowLoader] = useState(false);
  let [email, setEmail] = useState('')
  const [fbTrackenable,setTracenable] = useState(false)
  let [emailError, setEmailError] = useState('')
  let [password, setPassword] = useState('')
  let [passwordError, setPasswordError] = useState('')
  let [cpassword, setConfirmPassword] = useState('')
  let [cpasswordError, setConfirmPasswordError] = useState('')
  let [inputtype, setInputType] = useState(false);
  let [inputtype1, setInput1Type] = useState(false);
  let [termsConditions, setTermsType] = useState(false);
  let [loaded, setLoaded] = useState(false);
  let [keep_updated, setKeepUpdated] = useState(true);

  useEffect(() => {
    if (auth.auth.loginUserToken != "") {
      if(props.history)
      props.history.push('/');
    }
    setInputType(false);
  }, []);

  function showPassword() {
    setInputType(!inputtype);
  };


  const handleLogin = () =>{
    if(props.openLoginPopUp){
        props.openLoginPopUp()
    }
    else{
        props.history.push("/oauth/sign-in/")
    }

}

  function showConfirmPassword() {
    setInput1Type(!inputtype1);
  };

  function handleEmailInput(event) {
    setEmail(event.target.value)
    setEmailError(validate('email', event.target.value))
  };

  function handlePasswordInput(event) {
    setPassword(event.target.value)
    setPasswordError(validate('password', event.target.value))
  };

  function handleConfirmPasswordInput(event) {
    setConfirmPassword(event.target.value)
    setConfirmPasswordError(validate('cpassword', event.target.value))
  };

  function handleTermsConditions(event) {
    setTermsType(event.target.value);
  }

  function validate(name, value) {
    switch (name) {
      case "email":
        if (!value || value.trim() === "") {
          return "Email is required.";
        } else {
          return "";
        }
      case "password":
        if (!value || value.trim() === "") {
          return "Password is required.";
        } else {
          var reg = /^(?=.*[0-9])(?=.*[!@#$%^&!%^&:;*=])[a-zA-Z0-9!@#$%^&!%^&:;*=]{8,15}$/;
          var test = reg.test(value);
          if (test) {
            return "";
          } else {
            return "Please enter a password with 8-15 characters, including at least one number and one special character (!@#$%^&!%^&:;*=).";
          }
        }
      case "cpassword":
        if (!value || value.trim() === "") {
          return "Confirm Password is required.";
        } else {
          return "";
        }
      default: {
        return "";
      }
    }
  };
  function setEvents()
  {
    setKeepUpdated(!keep_updated);
  }
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if(validate("password",password)){
      toast("Please enter a strong password.", {
        position: "top-right",
        autoClose: 3000,
        type: 'error',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }

    if (password != cpassword) {
      toast("Please check your passwords.", {
        position: "top-right",
        autoClose: 3000,
        type: 'error',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }
    if (termsConditions == false) {
      toast("Please agree to the Privacy Policy and Terms of Use in order to register.", {
        position: "top-right",
        autoClose: 3000,
        type: 'error',
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }
    setLoaded(true);
    let payload = {
      "email": email,
      "password": password,
      "password_confirm": cpassword,
      "keep_updated":keep_updated
    }
   

    dispatch(register(payload, (result) => {
      setLoaded(false);
      if (result.error) {
        toast(result.error, {
          position: "top-right",
          autoClose: 10000,
          type: 'error',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

      // return;
      } else if(!result.error) {
        if(result.bearer_token){
          sessionStorage.setItem("token",result.bearer_token)
          dispatch(save_token(result.bearer_token));
          dispatch(login_user_info({email:email}))
        }
        // toast("Thank you. Please click the verification link sent to your email address.")
        setTracenable(true)
        toast("You are now registered!", {
          position: "top-right",
          autoClose: 3000,
          type: 'success',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
        if(props.closeSignupPopup){
          props.closeSignupPopup()
        }
        localStorage.setItem("signuptrack",props.signUpRedirectUrl)
        props.history.push(props.signUpRedirectUrl);
      }

    }));

  };


  return (

      <div className="register-popup login-popup-custom">
            {
              fbTrackenable&&
              <FacebookPixel trackName={"CompleteRegistration"}/>
            }
           <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
            <div className="login_inner4 login_inner_new_design">
              <h4 className={props?.customClass?.modalTop ? "modalh4 sign_in_title" :""}>Register with <img className="e-logo" src={splitLocation[1]=='dating'?datingBlueLogo:Elogo} alt="elogo" /></h4>
      <div className="input_outer3">
              <div className="input_outer99">
                <input type="email" name="email" required placeholder="Email" value={email} onChange={handleEmailInput} />
                <p className="sign-errMsg">{emailError}</p>
              </div>

              <div className="input_outer99">
                {inputtype == false &&
                  <input type="password" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                }
                {inputtype == true &&
                  <input type="text" name="password" placeholder="Password" required value={password} onChange={handlePasswordInput} />
                }

                {inputtype == false && <img src={edit} onClick={showPassword} />}
                {inputtype == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showPassword}></i>}
                <p className="sign-errMsg">{passwordError}</p>
              </div>

              <div className="input_outer99">
                {inputtype1 == false &&
                  <input type="password" name="cpassword" placeholder="Confirm Password" required value={cpassword} onChange={handleConfirmPasswordInput} />
                }
                {inputtype1 == true &&
                  <input type="text" name="cpassword" placeholder="Confirm Password" required value={cpassword} onChange={handleConfirmPasswordInput} />
                }

                {inputtype1 == false && <img src={edit} onClick={showConfirmPassword} />}
                {inputtype1 == true && <i className="fa fa-eye-slash" aria-hidden="true" onClick={showConfirmPassword}></i>}
                <p className="sign-errMsg">{cpasswordError}</p>
              </div>

              <div className="user45">
                <p>
                  <label className="container98 new_user5">I agree to Eternaview’s <Link to="/home/privacy-policy/">Privacy Policy</Link> and  <Link to="/home/terms-of-use/">Terms of Use</Link>
                    <input type="checkbox" value={termsConditions} onChange={handleTermsConditions} />
                    <span className="checkmark"></span>
                  </label>

                  <label className="container98 new_user5">Yes, please keep me updated on Eternaview News, Events and Offers
                    <input type="checkbox" defaultChecked onChange={(e)=>setEvents(e)} />
                    <span className="checkmark"></span>
                  </label>
                </p>
              </div>

              <div className="log_outer">
                {loaded == false && <button className="Puchase_now_btn_new" type="submit">Register</button>}
                {loaded == true && <button className="Puchase_now_btn_new" type="button">Registering...</button>}
              </div>

              <div className="checkbox custom_check">
                {/* <label className="container98">Remember me
                <input type="checkbox" />
                <span className="checkmark"></span>
              </label> 
              <Link to="/auth/reset-password">Forgot password?</Link>*/}
              </div>

              <p className="new_user5">Already have an account? 
              <a data-toggle="modal" onClick={()=>{handleLogin()}} style={{color:"#1b9df5",cursor:"pointer"}}>Log in</a>
              {/* <Link to="/oauth/sign-in/"> Log in</Link> */}
              </p>
            </div>
      </div>
          </Form>
      </div>
  )
};

export default RegisterComponent;
