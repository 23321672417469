import React, { useEffect, useState } from "react"
import {
  verifyUserSubscription,setNavigationBanner,profile
} from "../redux/actions/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import connection from "../config/connection";
const NotificationBar = () =>{
    const auth = useSelector((state) => state);
    const dispatch = useDispatch();
    const sessionDetails = sessionStorage.getItem("notification")
    const [isVisible,setIsVisible] = useState("false")
    const [profileDetails,setProfileDetails] = useState()

    const getProfile = (token) => {
      if (!auth.auth.loginUserToken) {
        auth.auth.loginUserToken = sessionStorage.getItem("token");
      }
      dispatch(profile({ token: auth.auth.loginUserToken}, (result) => {
        if (result.data) {
          setProfileDetails(result.data)
          verifyUserSubscriptions(result.data)
        } 
      }));
    };
    
    const closeNotification = () =>{
      updateBannerDetails(false)
        setIsVisible(false)
        sessionStorage.setItem("notification",false)
    }

    const updateBannerDetails = (status) =>{
      dispatch(setNavigationBanner( {is_banner:status},auth.auth.loginUserToken,(res)=>{
        console.log("Resp in banner ->",res);
      }))
    }
    const verifyUserSubscriptions = (profileDetails) => {
      if (!auth.auth.loginUserToken) {
        auth.auth.loginUserToken = sessionStorage.getItem("token");
      }
      dispatch(
        verifyUserSubscription(auth.auth.loginUserToken, (res) => {
          if (res.is_active == false) {
            localStorage.clear();
            window.location.href = connection.homePage + `?access_token=${auth.auth.loginUserToken}`
            // this.props.history.replace("/");
          } else {
            if (res.data.length > 0 && sessionDetails != "false" && profileDetails.is_banner) {
              setIsVisible("true")
              updateBannerDetails(true)
              // res.data.map((mod, ind) => {
              //   if(mod.updated_details.progress_status != "complete"){
                 
              //   }
              // })
              // setPlanVerify(res.data);
            }else{
              setIsVisible("false")
            }
          }
        })
      );
    };

    useEffect(()=>{
      getProfile()
    },[])
    return(
        <div className="notification_bar" 
        style={{
            display: (isVisible == "true")? "block" : "none",
          }}
        >
         If you wish to record, edit, or share your Eternaview (once completed), you can find it on the 
         <a> My Collections page
            </a>

            <button
              onClick={()=>{closeNotification()}}
              type="button"
              className="close gift_header_cross"
            >
              &times;
            </button>
        </div>
    )
}

export default NotificationBar