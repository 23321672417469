import React, { useEffect } from 'react';
import { environment, FaceBook_Token } from '../config/connection';
import ReactPixel from 'react-facebook-pixel';
import { sharePixelTrack } from '../redux/actions';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
const FacebookPixel = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state);
  const { trackName } = props
  const { track } = ReactPixel
  useEffect(() => {
    // if (environment === 'production') {
    if (environment === "production") {
      //alert(trackName);
      //alert('component');
      ReactPixel.init("1499380397556107");
      ReactPixel.track(trackName, {
        value: 100,
        currency: "USD",
      });
      const facebookObj = {
        access_token: FaceBook_Token,
        pixel_id: "1499380397556107",
        event_name: trackName,
        event_time: Math.floor(Date.now() / 1000),
        event_source_url: "https://www.eternaview.com/",
        action_source: "website",
        fbp: Cookies.get("_fbp"),
      };
      if (auth.auth.loginUserInfo?.email) {
        facebookObj.email = auth.auth.loginUserInfo.email;
      }
      dispatch(sharePixelTrack(facebookObj, (result) => { }));
    }
  }, [environment]);
  return null;
};

export default FacebookPixel;
