
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactUs } from "../../redux/actions/index";
import { Form } from "reactstrap";

function ContactUs(props) {
   const dispatch = useDispatch();

   let [name, setName] = useState('')
   let [email, setEmail] = useState('')
   let [phone, setPhone] = useState('')
   let [query, setQuery] = useState('');
   let [loaded, setLoaded] = useState(false);
   const auth = useSelector(state => state);

   function handleEmailInput(event) {
      setEmail(event.target.value)
   };

   function handleNameInput(event) {
      setName(event.target.value)
   }

   function handlePhoneInput(event) {
      setPhone(event.target.value)
   }

   function handleQueryInput(event) {
      setQuery(event.target.value)
   }

   const handleFormSubmit = (event) => {
      event.preventDefault();
      setLoaded(true);
      let payload = {
         "name": name,
         "email": email,
         "query": query
      }

      dispatch(contactUs(payload, auth.auth.loginUserToken, (res) => {
         setLoaded(false);
         if(res.msg) {
            toast('Thank you for contacting Eternaview. Your query will be answered shortly.', {
               position: "top-right",
               autoClose: 3000,
               type: 'success',
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: false,
               draggable: false,
               progress: undefined,
            });
            setEmail('');
            setName('');
            setPhone('');
            setQuery('');
         }

      }));

   };

   return (
      <section class="cretae_home5 contact_outer">
         <div class="container">
            <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
               <div class="row">
                  <div class="col-md-8 offset-md-2 col-sm-12">
                     <div class="contact_title">Contact Us</div>
                     {/* <p class="contact_p">You may have received a link in an emailed invitation or otherwise had a link shared with you. If so, please click that link and it will bring you to the Eternaview you may want to see.Otherwise, please look around and click on any of these sample or public Eternaviews you can interact with. They may also give you some ideas for the types of Eternaviews you may want to create.</p> */}
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-4">
                     <label class="custom_label">Name</label>
                     <input type="text" class="custom_input" name="name" required placeholder="John" value={name} onChange={handleNameInput} />
                  </div>
                  <div class="col-md-4">
                     <label class="custom_label">Email</label>
                     {/* <input class="custom_input" type="text" placeholder="John@gmail.com" /> */}
                     <input type="email" class="custom_input" name="email" required placeholder="John@gmail.com" value={email} onChange={handleEmailInput} />
                  </div>
                  <div class="col-md-4">
                     <label class="custom_label">Phone Number</label>
                     <input type="text" class="custom_input" name="phone" placeholder="1234567890" value={phone} onChange={handlePhoneInput} />
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-12">
                     <label class="custom_label">Message</label>
                     <textarea class="custom_input" placeholder="Write here" value={query} onChange={handleQueryInput} required></textarea>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-12">
                     {loaded == false && <button class="Puchase_now_btn_new" type="submit">Submit</button>}
                     {loaded == true && <button class="Puchase_now_btn_new" type="button">Submitting...</button>}
                  </div>
               </div>
            </Form>
         </div>
      </section>
   )
};

export default ContactUs;
