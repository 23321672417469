import React, { useEffect } from "react";
import { redeemlogin } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useParams,useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Circles } from "react-loader-spinner";
import connection from "../../config/connection";

const RedeemGifts = () =>{
    const dispatch = useDispatch()
    const params = useParams();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const getLoginDetails = () =>{
        dispatch(redeemlogin({"gift_token":params.token }, (res) => {
            if(res.bearer_token){
                sessionStorage.setItem("token",res.bearer_token);
                if(splitLocation[1]=='dating')
                    {
                        window.location.href = connection.datingHomePage + `?access_token=${res.bearer_token}`
                    }
                    else
                    {
                        window.location.href = connection.homePage + `?access_token=${res.bearer_token}`
                    }
            }
        }))
    }


    useEffect(()=>{
getLoginDetails()
    },[])
    return(
        <>
        <Circles wrapperClass="circle-spinner"/>
         <section class="thankyou-outer">
            <div class="container inner-container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-bottom">
                                <p class="p-text">We are just fetching your details just wait for few seconds</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default RedeemGifts