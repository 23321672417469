import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import loadingImage from "../../../Images/buffer.gif";
import vector_audio from "../../../Images/interact_audio.jpg";
import { isAndroid, isIOS, isMobile, isTablet } from "react-device-detect";
import "videojs-contrib-quality-levels"; // videoJs Quality levels **
import "videojs-hls-quality-selector"; // videojs Quality Selector **
import { isSafari } from "react-device-detect";
import { compose } from "redux";
let videoJsOptions = {
  html5: {
    vhs: {
      overrideNative: true,
      nativeAudioTracks: true,
      nativeVideoTracks: true,
      nativeTextTracks: true,
    },
  },
  controls: false,
  bigPlayButton: false,
  autoplay: true,
  responsive: true,
  fluid: true,
  width: "auto",
  height: "auto",
  // muted: true,
};

let videoJsOptions1 = {
  html5: {
    vhs: {
      nativeAudioTracks: true,
      nativeVideoTracks: true,
      nativeTextTracks: true,
      overrideNative: true,
    },
  },
  controls: false,
  bigPlayButton: false,
  autoplay: true,
  responsive: true,
  fluid: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
};

const InteractVideoGrandpaDemo = (props) => {

  
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState(null);
  const [videoDetails,setVideoDetails] = useState({currentTime:0,totalVideoTime:0,isAboutToend:false})
  const [isAudioAvlbl,setAudioSource] = useState(true);

  const [grandpaPleasantWaiting,setGrandpaPleasantWaiting] = useState(false);

  const [videoStyle, setVideoStyle] = useState({
    width: false,
    height: null,
    objectFit: "cover",
  });
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const videoNode = useRef(null);
  const newVideo = useRef(null);
  const playerRef = useRef(null);
  const newPlayerRef = useRef(null);
  const updateNode = () =>{
    setGrandpaPleasantWaiting(true);
    setVideoDetails({...videoDetails,isAboutToend:true})
    props.updateVjsSrc()
  }
  const myCallback = () => {
    props.setInteractResponse(true);
    props.setAudioTranscript();

    videoNode.current.currentTime = 0; // Reset the second video to the beginning
    videoNode.current.play(); // Play the second video
  };

  // Managing Video Frame base on Device and Orientation
  const manageStyle = () => {
    if (
      props?.detail?.screen_mode == "portrait" &&
      props?.deviceDetails?.deviceOrentation == "portrait"
    ) {
      setVideoStyle({
        objectFit: "cover",
        height: props?.deviceDetails.height,
      });
    } else if (
      props?.deviceDetails?.deviceOrentation == "landscape" &&
      (isMobile || isTablet)
    ) {
      let div;
      div = document.getElementById("myVideo");
      if (div.offsetWidth == 0) {
        div = document.getElementById("secondVideo");
      }
      const width = div.offsetWidth;
      const height = (width * 9) / 16;
      setVideoStyle({
        objectFit: "fill",
        height: height,
      });
    } else if (
      props?.detail?.screen_mode == "portrait" &&
      props?.deviceDetails?.deviceOrentation == "portrait" &&
      isTablet // For Ipad Width Handling
    ) {
      setVideoStyle({
        objectFit: "fill",
        height: props?.deviceDetails.height,
        width: 75,
      });
    } else if (
      props?.detail?.screen_mode == "portrait" &&
      props?.deviceDetails?.deviceOrentation == "landscape" &&
      isTablet // For Ipad Width Handling
    ) {
      setVideoStyle({
        objectFit: "cover",
        width: 87,
      });
    } else if (
      props?.detail?.screen_mode == "landscape" &&
      props?.deviceDetails?.deviceOrentation == "portrait" &&
      isMobile // For Ipad Width Handling
    ) {
      const reduceWidth = props.deviceDetails.width * 0.07;
      const attain_height =
        (parseInt(props.deviceDetails.width - reduceWidth) * 9) / 16;
      setVideoStyle({
        objectFit: "fill",
        height: attain_height,
        width: 100,
      });
    } else if (
      props?.detail?.screen_mode == "landscape" &&
      props?.deviceDetails?.deviceOrentation == "portrait" &&
      !isTablet
    ) {
      const reduceWidth = props.deviceDetails.width * 0.07;
      const attain_height =
        (parseInt(props.deviceDetails.width - reduceWidth) * 9) / 16;
      setVideoStyle({
        objectFit: "fill",
        height: attain_height,
      });
    } else if (
      props?.detail?.screen_mode == "landscape" &&
      props?.deviceDetails?.deviceOrentation == "portrait" &&
      isTablet
    ) {
      setVideoStyle({
        objectFit: "fill",
        width: 100,
        height: 388,
      });
    } else if (
      props?.detail?.screen_mode == "landscape" &&
      props?.deviceDetails?.deviceOrentation == "landscape" &&
      isTablet
    ) {
      // alert(props.deviceDetails.width)
      const reduceWidth = props.deviceDetails.width;
      const attain_height =
        (parseInt(props.deviceDetails.width - reduceWidth) * 9) / 16;
      setVideoStyle({
        objectFit: "fill",
        width: 87,
        height: attain_height,
      });
    } else if (
      props?.detail?.screen_mode == "landscape" &&
      props?.deviceDetails?.deviceOrentation == "landscape" &&
      !isTablet
    ) {
      setVideoStyle({
        objectFit: "fill",
      });
    } else {
      setVideoStyle({});
    }
  };
  // --- Ended ----
  
  useEffect(() => {
    
    if (videoNode.current) {
      playerRef.current = videojs(
        videoNode.current,
        videoJsOptions,
        function onPlayerReady() {
          console.log("onPlayerReady", this);
        }
      );

      playerRef.current.on("deviceReady", () => {
        console.log("device is ready!");
      });
    }
    if (newVideo.current) {
      newPlayerRef.current = videojs(
        newVideo.current,
        videoJsOptions1,
        function onPlayerReady() {
          console.log("onPlayerReady", this);
        }
      );
      newPlayerRef.current.on("deviceReady", () => {
        console.log("device is ready!");
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
      if (newPlayerRef.current) {
        newPlayerRef.current.dispose();
      }
    };
  }, []);

  const QualityUpdate = () => {
    // newPlayerRef.current.hlsQualitySelector({
    //   displayCurrentQuality: true
    // });
    newPlayerRef.current.on("play", function () {
      let qualityLevels = newPlayerRef.current.qualityLevels();
      qualityLevels.on("addqualitylevel", function (event) {
        let qualityLevel = event.qualityLevel;
        if (qualityLevel.height >= 720) {
          qualityLevel.enabled = true;
        } else {
          qualityLevel.enabled = false;
        }
      });
    });
  };

  useEffect(() => {
    if (
      props.deviceDetails.deviceOrentation == "landscape" &&
      (isMobile || isTablet)
    ) {
      scrollToVideo();
    }
    if (
      props.deviceDetails.deviceOrentation == "portrait" &&
      props?.detail?.screen_mode == "portrait" &&
      (isMobile || isTablet)
    ) {
      scrollToVideo();
    }
    manageStyle();
    if (
      props.recording &&
      newPlayerRef.current.src() !== props.videoUrl &&
      props.videoUrl !== ""
    ) {
      newPlayerRef.current.src({
        src: props.videoUrl,
        type: props.videoType,
      });
      if(isAndroid){
        QualityUpdate();
      }
  
    }
  }, [props.recording, props.videoUrl, props.videoType]);
  useEffect(() => {
    manageStyle();
  }, [props.deviceDetails]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.src({
        src: props.orgUrl,
        type: props.videoType,
      });
      playerRef.current.on("play", function () {
        let qualityLevels = playerRef.current.qualityLevels();
        qualityLevels.on("addqualitylevel", function (event) {
          let qualityLevel = event.qualityLevel;
          if (qualityLevel.height >= 720) {
            qualityLevel.enabled = true;
          } else {
            qualityLevel.enabled = false;
          }
        });
      });

      playerRef.current.play();
    }
  }, [props.orgUrl, props.videoType]);

  const scrollToVideo = (targetDivRef) => {
    const element = document.getElementById("myVideo");
    element.scrollIntoView({ behavior: "smooth", inline: "nearest" });
  };

 const handleTimeUpdate = () => {
  setVideoDetails({...videoDetails,currentTime:videoNode.current.currentTime})

  };

  const handleLoadedMetadata = () => {
    setVideoDetails({...videoDetails,totalVideoTime:videoNode.current.duration})
  };

  function getAudioOutputDevices() {
    navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
        const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
        if(audioInputDevices.length === 0 || audioOutputDevices === 0){
          setAudioSource(false)
        }
        console.log('Audio output devices:', audioOutputDevices);
        console.log('Audio input devices:', audioInputDevices);
        // You can now use the list of audio output devices as needed
      })
      .catch(err => {
        console.error('Error enumerating devices:', err);
      });
  }

  useEffect(()=>{
    getAudioOutputDevices()
  },[])

  useEffect(() => {
      const checkMicrophonePermission = async () => {
        try {
          // Request microphone permission
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          
          // Permission granted
          setHasMicrophonePermission(true);
  
          // Release the stream
          stream.getTracks().forEach(track => track.stop());
          console.log(stream,"<- stream");
          // videoNode.current.play();
        } catch (error) {
          // Permission denied or error
          console.error('Microphone permission denied or error', error);
          setHasMicrophonePermission(false);
        }
      };
  
      checkMicrophonePermission();
    }, []);

    const handleContextMenu = (e) => {
      e.preventDefault();
      setMenuVisible(true);
      setMenuPosition({ x: e.clientX, y: e.clientY });
    };
  
    const handleMenuItemClick = (action) => {
      // Handle menu item click actions
      switch (action) {
        // Define your actions here
        default:
          break;
      }
      // Hide the menu after handling the action
      setMenuVisible(false);
    };

  return (
    <>
      {/* {  props.recording === false && props.orgUrl !== ""
      // !props.recording && props.orgUrl === "" 
      && (
              <img className="interact-img" src={loadingImage} />   
      )} */}
      {/* {
       props.isApiCallSuccess&& props.recording === true &&  props.no_transcoding ===true&&
        <div className="interact-img">
        <div className="iinteract-img-ii">
          <img src={loadingImage} />
          <p>The video is still being processed. </p>
          <p>It should be ready in a couple minutes or less.</p>
        </div>
      </div>
      } */}
      {props.isApiCallSuccess &&
        props.recording === true &&
        props.videoUrl === "" && (
          <div className="interact-img">
            <div className="iinteract-img-ii">
              <img src={loadingImage} />
              <p>The video is still being processed. </p>
              <p>It should be ready in a couple minutes or less.</p>
            </div>
          </div>
        )}
      {props.isApiCallSuccess && !props.recording && props.orgUrl === "" && (
        <div className="interact-img">
          <div className="iinteract-img-ii">
            <img src={loadingImage} />
            <p>The video is still being processed. </p>
            <p>It should be ready in a couple minutes or less.</p>
          </div>
        </div>
      )}

      {!props.isApiCallSuccess && (
        <div className="interact-img">
          <div className="iinteract-img-ii">
            <img src={loadingImage} />
            {/* <p>The video is still being processed. </p>
              <p>It should be ready in a couple minutes or less.</p> */}
          </div>
        </div>
      )}

      <div
        className={
          props.recording === false &&
          props.orgUrl !== "" &&
          props.isApiCallSuccess
            ? ""
            : "congratulation-video-hide"
        }
      >
        <div
          style={videoStyle ? videoStyle : { objectFit: "cover" }}
          data-vjs-player
          ref={(node) => {
            if (node && videoStyle) {
              if (videoStyle.height) {
                node.style.setProperty(
                  "height",
                  `${videoStyle.height}px`,
                  "important"
                );
              }
              if (node && videoStyle.width) {
                node.style.setProperty(
                  "width",
                  `${videoStyle.width}%`,
                  "important"
                );
              }
            }
          }}
        >
          <video
           onContextMenu={handleContextMenu}
            style={ {
              opacity: props.isPleasentUR&& videoNode?.current?.duration-0.5 <= videoNode?.current?.currentTime  ? 0 : 1, 
              transition: 'opacity 1s ease-in-out',
              objectFit :  videoStyle.objectFit ?videoStyle.objectFit : "cover"  }}
            id="myVideo"
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleLoadedMetadata}
            ref={videoNode}
            className="video-js vjs-default-skin custom_video_js"
            options={videoJsOptions}
            playsInline
            muted={true}
            loop={props.category=='Squirrel'?true:grandpaPleasantWaiting==false?false:true}
            preload="metadata"
            onEnded={() => updateNode()}
          ></video>
        </div>
      </div>
      <div
        className={
          props.recording === true &&
          props.videoUrl !== "" &&
          props.isApiCallSuccess
            ? ""
            : "congratulation-video-hide"
        }
        onContextMenu={handleContextMenu}
      >
        <div
          ref={(node) => {
            if (node && videoStyle) {
              node.style.setProperty(
                "height",
                `${videoStyle.height}px`,
                "important"
              );
            }
            if (node && videoStyle.width) {
              node.style.setProperty(
                "width",
                `${videoStyle.width}%`,
                "important"
              );
            }
          }}
          style={videoStyle ? videoStyle : {}}
          data-vjs-player
        >
          <video
            preload="metadata"
            style={
              videoStyle.objectFit
                ? { objectFit: videoStyle.objectFit }
                : { objectFit: "cover" }
            }
            id="secondVideo"
            ref={newVideo}
            className="video-js vjs-default-skin custom_video_js"
            options={videoJsOptions1}
            playsInline
            onEnded={() => myCallback()}
          ></video>
        </div>
      </div>
      {/* {menuVisible && (
        <div
          className="custom-menu"
          style={{ top: menuPosition.y, left: menuPosition.x,backgroundColor:"red",width:"50%" }}
        >
          <div className="menu-item" onClick={() => handleMenuItemClick("action1")}>
            View Full Screen
          </div>
        </div>
      )} */}
    </>
  );
};

export default InteractVideoGrandpaDemo;
