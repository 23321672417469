import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { getCategories, getCategoriesOpen, getSubCategories, getCreateQuestion, getSubCategoriesByCat, getSubCategoriesByCatOpen, scanQuestions, scanQuestionsOpen, verifyUserSubscription } from "../../redux/actions";
import { blogPosts } from "../../redux/actions/index";
import videojs from "video.js";
import $ from 'jquery';
import FacebookPixel from "../../MetaComponents/Facebookpixel";
import connection from "../../config/connection";
import { Circles } from "react-loader-spinner";
const videoJsOptions6 = {
   controls: true,
   bigPlayButton: true,
   autoplay: false,
   responsive: true,
   aspectRatio: '16:9',
   width: "auto",
   height: "auto",
   fluid: true,
   sources: [{
      src: 'https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8',
      type: 'application/x-mpegURL'
   }]
};

const videoJsOptions7 = {
   controls: true,
   bigPlayButton: true,
   autoplay: false,
   responsive: true,
   aspectRatio: '16:9',
   width: "auto",
   height: "auto",
   fluid: true,
   sources: [{
      src: 'https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8',
      type: 'application/x-mpegURL'
   }]
};

class CreateIntro extends Component {
   constructor(props) {
      super(props);

      this.state = {
         currentActiveplan:{},
         isThankyouOpen:false,
         isSignUpOpen:false,
         categories: [],
         subcategories: [],
         category: '0',
         subcategory: '0',
         typecreate: '0',
         data: {},
         blogs: [],
         nextbtn: false,
         selectedOption: null,
         moduleQuestions: [],
         catName: '',
         planVerify: '',
         verifyPlanDetail: {},
         loginModelOpenStatus: false,
         loginRedirectUrl: '',
         baseName:"dating",
      };
      this.baseName = '';
   }
   setScroll(e) {
      $('#accorscroll').animate({
         scrollTop: $(e.target).offset().top - 500
      }, 1000);
   }

   verifyUserSubscription = (cb) =>{
      if(!this.props.auth.loginUserToken || !sessionStorage.getItem("token")){
        // this.props.history.push({ pathname: `/home/pricing-plans`})
         this.props.history.push({ pathname: `/${this.baseName}/pricing-plans`})

      }else{
         this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
            if (res.is_active == false) {
               localStorage.clear();
               window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
               // this.props.history.replace("/");
            } else {
               if (res.data) {
                  if (res.data.length > 0) {
                     res.data.map((mod, ind) => {
                           if(mod.updated_details.progress_status != "complete"){
                              this.setState({currentActiveplan:mod})
                              this.loadSelectChange({id:mod.subscribe_plan__category__id})
                            }
                     });
                  }
                  this.setState({ verifyPlanDetail: res.data });
                  if(cb){
                     cb(res.data)
                  }
               }
            }
   
         });
      }

   }

   componentDidMount() {

      const { location } = this.props;
      const { pathname } = location;
      const splitLocation = pathname.split("/");
      if(splitLocation[1]=='dating')
      {
        this.setState({ baseName:'dating' });
        this.baseName = 'dating';
      }
      else
      {
        this.setState({ baseName:'home' });
        this.baseName = 'home';
      }
      
      this.onCreateEternView()

      localStorage.removeItem('typecreate');

      this.props.getCategoriesOpen((res) => {
         if (res.data && res.data.length > 0) {
            this.setState({ categories: res.data });
            // this.setState({ subcategories: res.data[0].sub_categories });
            const lagecy_data = res.data.filter((item, i) => item.name == "Legacy");

            console.log(lagecy_data, 'lagecy_data');
            // this.loadSelectChange(lagecy_data[0]);
         }
      });

      if (this.props.auth.loginUserToken != "") {
         this.props.getCategories({ token: this.props.auth.loginUserToken }, (res) => {
            if (res.data && res.data.length > 0) {
               this.setState({ categories: res.data });
               // this.setState({ subcategories: res.data[0].sub_categories });


            }
         });

         this.verifyUserSubscription()

         
      }
      console.log(this.state.categories, 'this.state.categories');

   }

   checkSection = (e) => {
      if(e)
      e.preventDefault();

      if (this.props.auth.loginUserToken == "") {
         //   history.goBack();
         toast('Please log in first in order to create an Eternaview', {
            position: "top-right",
            autoClose: 3000,
            type: 'error',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
         });
         return;
      } else {
         this.props.history.push(`/${this.baseName}/create/practice-test/`);
         //this.props.history.push('/create/practice-test/');
      }
   }

   nextCreate = (param) => {
      let catName = this.state.catName;
      let active_plan=Object.keys(this.state.currentActiveplan).length ? this.state.currentActiveplan :param?param:{}
      if(Object.keys(active_plan).length>0  &&active_plan?.active_status == "active" && active_plan?.status == "paid" ){
         const typecreate = active_plan.subscribe_plan__plan_type == 'video_audio'? 0 :1
         localStorage.setItem('typecreate', typecreate);
         this.props.history.push(
           `/${this.baseName}/create/eternaview/${this.state.category}+${this.state.subcategory}+${typecreate}+${active_plan.views}`
          );
          /* this.props.history.push(
            `/create/eternaview/${this.state.category}+${this.state.subcategory}+${typecreate}+${active_plan.views}`
          ); */
       }
      else {
         this.props.history.push({ pathname: `/${this.baseName}/pricing-plans`})
      }

   }

   openSignupPopUp = (ret) => {
      // $('#myLoginPopup').hide();
      this.setState({isSignUpOpen:true});
      this.setState({loginModelOpenStatus:false});
      
    }


   closeLoginPopup = (ret) => {
     if(this.state.loginRedirectUrl=='create')
     {
      this.verifyUserSubscription( this.nextCreate)
     }

     else if(this.state.loginRedirectUrl=='practice'){
      this.checkSection();
     }
     else
     {
      window.location.reload();
     }
      $('#myLoginPopup').modal('toggle');

   }

   closeSignupPopup = (ret) => {
      this.setState({isSignUpOpen:false,isThankyouOpen:true})
    }

    openLoginPopUp = () =>{
      this.setState({isThankyouOpen:false})
      this.setState({ loginModelOpenStatus: true });
      this.setState({isSignUpOpen:false})
    }
   

   loadSelectChange = (item) => {
      //  event.preventDefault();
      this.setState({ typecreate: 0 }); // To set video default

      // if(!this.state.verifyPlanDetail.subscribe_plan__category__id || this.state.verifyPlanDetail.subscribe_plan__category__id == event.target.value) {
      this.setState({ ['category']: item.id });
      let cat = item.id;
      // this.setState({'category':cat});
      this.props.getSubCategoriesByCatOpen(item.id, (res) => {
         this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
         this.setState({ subcategory: res.data.sub_categories[0].id });
      });
   }

   onSelectchange = (event) => {
      event.preventDefault();

      if (event.target.name == 'category') {
         // if(!this.state.verifyPlanDetail.subscribe_plan__category__id || this.state.verifyPlanDetail.subscribe_plan__category__id == event.target.value) {
         this.setState({ [event.target.name]: event.target.value });
         let cat = event.target.value;
         this.props.getSubCategoriesByCatOpen(event.target.value, (res) => {
            this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
            this.setState({ subcategory: res.data.sub_categories[0].id });
         });

         this.props.scanQuestionsOpen({}, `&question_scan=True`, (res) => {
            let con = res.data, questions = [], match = res.transition_name;
            if (res.data.length > 0) {
               con.map((data, index) => {
                  if (data.id == cat) {
                     data.modules.map((mod, ind) => {
                        if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                           questions.push(mod);
                        }
                     });
                  }

               });
               this.setState({ moduleQuestions: questions });
            }
         });
      }
   }


   onTypeSelected = (e) => {
      this.setState({ typecreate: e.target.value });
      this.setState({ nextbtn: true });
   }
  isLoggedIn = (redirectUrl) => {
      if(redirectUrl != "practice"){
         sessionStorage.setItem("giftType",1)
         //this.props.history.push('/home/pricing-plans/')     // Remove if section if clients need login pop for create my etenaview button as well
         this.props.history.push({ pathname: `/${this.baseName}/pricing-plans`})


      }else{
         this.setState({ loginRedirectUrl: redirectUrl });
         this.setState({ loginModelOpenStatus: true });
      }

  
   }

   isLoggedIn2 = (e) => {
      e.preventDefault();

      if (this.props.auth.loginUserToken == "") {
         toast('Please log in first in order to create an Eternaview', {
            position: "top-right",
            autoClose: 3000,
            type: 'error',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
         });
      }
   }

   noRefCheck(selectedList, selectedItem) {
      console.log(selectedList);
      console.log(selectedItem);
   }
   onHandPopClick = () => {
      let cat = 2;
      this.setState({ category: cat });
      this.props.getSubCategoriesByCatOpen(cat, (res) => {
         this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
         this.setState({ subcategory: res.data.sub_categories[0].id });
      });
      this.props.scanQuestionsOpen({}, `&question_scan=True`, (res) => {
         let con = res.data, questions = [], match = res.transition_name;
         if (res.data.length > 0) {
            con.map((data, index) => {
               if (data.id == parseInt(cat)) {

                  data.modules.map((mod, ind) => {
                     if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                        questions.push(mod);
                     }
                  });
               }

            });

            console.log('+++++++++questions++++++++++');
            console.log(questions);
            this.setState({ moduleQuestions: questions });
         }
      });
      /*   } */
      document.getElementById('select-category').value = cat;

      /* Blogs */

      this.props.blogPosts('', (result) => {
         this.setState({ blogs: result.data });
      });


   }
   goToFaq = (e) => {
      e.preventDefault();

     // this.props.history.push('/home/faq/');
      this.props.history.push({ pathname: `/${this.baseName}/faq/`})


   }
   goToBlog = (e) => {
      e.preventDefault();
      this.props.history.push({ pathname: `/${this.baseName}/blogs`})

     // this.props.history.push('/home/blogs/');
   }

   redirectToDemo = () => {
      window.open('/#eterna_work', '_blank').focus();
   }

   onCreateEternView = () =>{
      this.verifyUserSubscription( this.nextCreate)
      
   }



   render() {
      return (


         <section className="cretae_home5 new_create_home5">
            <Circles wrapperClass="circle-spinner"/>
             <FacebookPixel trackName={"ViewContent"}/>
         </section>

      );
   }
}


const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
   getCategories: bindActionCreators(getCategories, dispatch),
   getCategoriesOpen: bindActionCreators(getCategoriesOpen, dispatch),

   getSubCategories: bindActionCreators(getSubCategories, dispatch),
   getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
   getSubCategoriesByCat: bindActionCreators(getSubCategoriesByCat, dispatch),
   getSubCategoriesByCatOpen: bindActionCreators(getSubCategoriesByCatOpen, dispatch),


   scanQuestions: bindActionCreators(scanQuestions, dispatch),
   scanQuestionsOpen: bindActionCreators(scanQuestionsOpen, dispatch),


   verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
   blogPosts: bindActionCreators(blogPosts, dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateIntro);
