import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toastr } from "react-redux-toastr";
import practices_first from "../../Images/practices_first.png";
import play from "../../Images/play.png";
import crete_page from "../../Images/crete_page.jpg";
import hand_outer from "../../Images/hand_outer.png";
import { toast } from "react-toastify";
import arrow_right from "../../Images/arrow_right.png";
import ctre45 from "../../Images/ctre45.png";
import blog1 from "../../Images/blog1.png";
import connection from "../../config/connection";
import {
  getCategories,
  getSubCategories,
  getCreateQuestion,
  getSubCategoriesByCat,
  scanQuestions,
  verifyUserSubscription,
} from "../../redux/actions";
import Multiselect from "multiselect-react-dropdown";
import { Redirect } from "react-router-dom";
import videojs from "video.js";
import $ from "jquery";
const videoJsOptions6 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

class CreateIntroDating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      subcategories: [],
      category: "0",
      subcategory: "0",
      typecreate: "0",
      data: {},
      nextbtn: false,
      selectedOption: null,
      moduleQuestions: [],
      catName: "",
      planVerify: "",
      verifyPlanDetail: {},
    };
  }
  setScroll(e) {
    $("#accorscroll").animate(
      {
        scrollTop: $(e.target).offset().top - 500,
      },
      1000
    );
  }

  componentDidMount() {
    if (localStorage.getItem("pass") === "match") {
    } else {
      this.props.history.push("/home/securelogin");
    }
    this.player6 = videojs(
      this.video6Node,
      videoJsOptions6,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player6.on("deviceReady", () => {
      console.log("device is ready!");
    });

    localStorage.removeItem("typecreate");
    if (this.props.auth.loginUserToken != "") {
      this.props.getCategories(
        { token: this.props.auth.loginUserToken },
        (res) => {
          if (res.data && res.data.length > 0) {
            this.setState({ categories: res.data });
            // this.setState({ subcategories: res.data[0].sub_categories });
          }
        }
      );

      this.props.verifyUserSubscription(
        this.props.auth.loginUserToken,
        (res) => {
          console.log(res);
          if (res.is_active == false) {
            localStorage.clear();
            // this.props.history.replace("/");
            window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
          } else {
            if (res.data) {
              this.setState({ verifyPlanDetail: res.data });
              // res.data.map((mod, ind) => {
              //    if (mod.active_status == "active" && mod.is_eternaview_created == false && mod.) {

              //    }
              // });
              //   this.setState({ planVerify: res.data.active_status, verifyPlanDetail : res.data });
            }
          }
        }
      );
    }
  }

  checkSection = (e) => {
    e.preventDefault();

    if (this.props.auth.loginUserToken == "") {
      //   history.goBack();
      toast("Please log in first in order to create an Eternaview", {
        position: "top-right",
        autoClose: 3000,
        type: "error",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    } else {
      this.props.history.push("/create/practice-test/");
    }
  };

  nextCreate = (e) => {
    e.preventDefault();

    let catName = this.state.catName;
    let redirection = false;
    let viewId = "";

    if (this.state.category != "0" && this.state.subcategory != "0") {
      localStorage.setItem("typecreate", this.state.typecreate);
      let typecreate = this.state.typecreate == 0 ? "video_audio" : "audio";
      // return <Redirect to={{
      //    pathname: `/home/plans/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}`,
      //    state: { catName }
      // }} />
      // console.log(this.state.category, this.state.verifyPlanDetail); return
      if (this.state.verifyPlanDetail.length > 0) {
        this.state.verifyPlanDetail.map((mod, ind) => {
          if (this.state.category == mod.subscribe_plan__category__id) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate &&
                mod.updated_details.category == this.state.category
              ) {
                redirection = true;
                viewId = mod.views;
                return;
              }
            } else {
              alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              );
            }
          } else if (mod.subscribe_plan__category__id == 9999) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate &&
                mod.updated_details.category == this.state.category
              ) {
                redirection = true;
                viewId = mod.views;
                return;
              }
            } else {
              alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              );
            }
          }
        });
      }

      console.log(redirection);

      if (redirection == true) {
        this.props.history.push(
          `/create/eternaview/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}+${viewId}`
        );
      } else {
        this.props.history.push({
          pathname: `/home/plans/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}`,
          state: { catName },
        });
      }

      // if (this.state.planVerify == 'active') {
      //    if (!this.props.auth.moduleCheck.is_complete || this.props.auth.moduleCheck.is_complete == false) {
      //       this.props.history.push(`/create/eternaview/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}`);
      //    } else {
      //       toast('Apologies! but you have already created eternaview.', {
      //          position: "top-right",
      //          autoClose: 3000,
      //          type: 'error',
      //          hideProgressBar: false,
      //          closeOnClick: true,
      //          pauseOnHover: true,
      //          draggable: false,
      //          progress: undefined,
      //       });
      //    }
      // } else {
      //    this.props.history.push({ pathname: `/home/plans/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}`, state: { catName } });
      // }
    } else {
      if (this.state.nextbtn == true) {
        toast(
          "Please make selection of category and move forward to create eternaview.",
          {
            position: "top-right",
            autoClose: 3000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );
      } else {
        toast("Please select category to move further.", {
          position: "top-right",
          autoClose: 3000,
          type: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
  };

  onSelectchange = (event) => {
    event.preventDefault();

    if (event.target.name == "category") {
      // if(!this.state.verifyPlanDetail.subscribe_plan__category__id || this.state.verifyPlanDetail.subscribe_plan__category__id == event.target.value) {
      this.setState({ [event.target.name]: event.target.value });
      let cat = event.target.value;
      this.props.getSubCategoriesByCat(
        event.target.value,
        this.props.auth.loginUserToken,
        (res) => {
          this.setState({
            subcategories: res.data.sub_categories,
            catName: res.data.name,
          });
          this.setState({ subcategory: res.data.sub_categories[0].id });
        }
      );

      this.props.scanQuestions(
        {},
        `&question_scan=True`,
        this.props.auth.loginUserToken,
        (res) => {
          let con = res.data,
            questions = [],
            match = res.transition_name;
          if (res.data.length > 0) {
            con.map((data, index) => {
              if (data.id == cat) {
                data.modules.map((mod, ind) => {
                  if (
                    mod.subcategory_name !=
                      "Interaction Facilitation Part 1 (required)" &&
                    mod.subcategory_name != "Interaction Facilitation Part 2" &&
                    mod.subcategory_name != "Pleasantries" &&
                    mod.subcategory_name !=
                      "Interaction Facilitation & Pleasantries (required)"
                  ) {
                    questions.push(mod);
                  }
                });
              }
            });
            this.setState({ moduleQuestions: questions });
          }
        }
      );
      //} else { // when plan category is different
      // if(this.state.verifyPlanDetail.subscribe_plan__plan_type == "video_audio" || this.state.verifyPlanDetail.subscribe_plan__plan_type == "video&audio") {
      //    toast('You cannot select this category because you have subscribed to different plan.', {
      //       position: "top-right",
      //       autoClose: 3000,
      //       type: 'error',
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: false,
      //       progress: undefined,
      //    });
      //    this.setState({typecreate : '0'});

      // } else if(this.state.verifyPlanDetail.subscribe_plan__plan_type == "audio") {
      //    this.setState({typecreate : '1'});
      // }
      //}
    }
  };

  onTypeSelected = (e) => {
    this.setState({ typecreate: e.target.value });
    this.setState({ nextbtn: true });
  };

  isLoggedIn = (e) => {
    e.preventDefault();

    if (this.props.auth.loginUserToken == "") {
      toast("Please log in first in order to create an Eternaview", {
        position: "top-right",
        autoClose: 3000,
        type: "error",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  noRefCheck(selectedList, selectedItem) {
    console.log(selectedList);
    console.log(selectedItem);
  }

  goToFaq = (e) => {
    e.preventDefault();

    this.props.history.push("/home/faq/");
  };
  goToBlog = (e) => {
    e.preventDefault();

    this.props.history.push("/home/blogs/");
  };

  redirectToDemo = () => {
    window.open("/#eterna_work", "_blank").focus();
  };

  render() {
    const {
      category,
      subcategory,
      subcategories,
      typecreate,
      selectedOption,
      moduleQuestions,
    } = this.state;

    return (
      <section className="cretae_home5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="videi78">
                <div className="bor_ox">
                  <img src={crete_page} />
                  <div className="btn7">
                    {this.props.auth.loginUserToken != "" && (
                      <button
                        className="btn"
                        data-toggle="modal"
                        data-target="#myModal-demo"
                      >
                        Create my Eternaview!
                      </button>
                    )}
                    {/* {this.props.auth.loginUserToken != "" && <Link to="/home/plans/"><button className="btn">Create my Eternaview Now!</button></Link>} */}
                    {this.props.auth.loginUserToken == "" && (
                      <button className="btn" onClick={this.isLoggedIn}>
                      Create my Eternaview!
                      </button>
                    )}
                  </div>
                </div>

                <ul>
                  <li>
                    <a
                      href="javascript:void();"
                      className="btn-practice-see-demo"
                      onClick={this.checkSection}
                    >
                      <img src={practices_first} />
                      Practice First
                    </a>
                  </li>

                  {/* <li><a href="javascript:void(0);" onClick={() => this.redirectToDemo()}><img src={play}  />See Demo</a></li> */}
                  <li>
                    <a
                      href="javascript:void(0);"
                      className="btn-create-see-demo"
                      data-toggle="modal"
                      data-target="#demomodal"
                    >
                      <img src={play} />
                      See Demo
                    </a>
                  </li>
                  <div
                    class="modal fade"
                    id="demomodal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="demomodalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="demomodalLabel">
                            How does Eternaview Work?
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body demo-video-body">
                          <video
                            style={{ height: "auto" }}
                            id="a"
                            ref={(node) => (this.video6Node = node)}
                            className="video-js vjs-default-skin custom_video_js"
                            options={videoJsOptions6}
                            playsInline
                          ></video>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal" id="myModal-demo">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close custom_close3"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>

                        <div className="modal-body">
                          <section className="doiect_outer">
                            <div className="container">
                              <div className="modal-header">
                                <h4>Directions: </h4>
                              </div>
                              <div className="modal-body">
                                <p>
                                  -To create your Eternaview, you will be given
                                  many questions (~175 for Legacy Eternaviews
                                  and ~75 for Dating Eternaviews) broken down
                                  into several modules.
                                </p>

                                <p>
                                  -However, you may skip questions within each
                                  module or even whole modules - some modules
                                  will not be relevant to many creators.
                                </p>

                                <p>
                                  -Note that you will likely want to create your
                                  Eternaview over multiple sittings. You can
                                  “Take a Break” any time after hitting "Submit"
                                  on your most recent recorded answer. All of
                                  your answers will be saved and you can return
                                  later to the same spot.
                                </p>

                                <p>
                                  -You will have the ability to retake any of
                                  your recordings right after you make them. You
                                  will also be able to retake any of your
                                  recordings, or answer any questions you
                                  skipped the first time, at the end of the
                                  recording process and again at a later date.
                                </p>

                                <p>
                                  -Feel free to move your recording device
                                  around to demonstrate any of your answers -
                                  how you cook, how you garden, how you play the
                                  piano! Have fun with it!
                                </p>

                                <p>
                                  -Note that for audio-only Eternaviews, a
                                  couple questions may not apply or, at a
                                  minimum, you won’t be able to demonstrate
                                  visually.
                                </p>

                                <p>
                                  -While we don’t set hard limits, we would
                                  generally recommend you keep your answers to
                                  three minutes or less for both technical
                                  reasons and so you don’t lose your audience
                                  when they come to interact with your
                                  recordings. For these same reasons, in no case
                                  should you record an answer longer than five
                                  minutes.
                                </p>

                                <p>-Let’s get started.</p>
                              </div>
                            </div>
                          </section>
                        </div>

                        <div className="modal-footer">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#myModal2"
                            data-dismiss="modal"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="righty65">
                <h5>
                  Eternaview is a perfect way to create a recording of your
                  voice, your face, your mannerisms, your personality, your life
                  story. It's for those who love you and for those who will
                  someday wish they knew you. So that they can talk to you -
                  yes, talk to you - not only why you are still here, but also
                  long after you are gone!
                </h5>
                <p className="bg67">
                  Eternaview is also a great, low-pressure way for you to let a
                  potential romantic or friend connection get to know you!
                </p>
                <div className="bottom_tex4">
                  <a href="#" data-toggle="modal" data-target="#myModal12">
                    <img src={hand_outer} />
                  </a>
                  <h4>
                    Click here to see the questions that come with each
                    Eternaview or for helpful tips in our Blog and FAQ!
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal custom_mdodel112" id="myModal12">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body cu678">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="box activer4">
                      <h4>Frequently Asked Questions</h4>
                      <div className="few3_outer">
                        <i className="fas fa-circle"></i>
                        <p>Dummy lorem ipsum dolor text?</p>
                      </div>
                      <div className="few3_outer no_bo675">
                        <i className="fas fa-circle"></i>
                        <p>Dummy lorem ipsum dolor text?</p>
                      </div>
                      <div className="btn78">
                        <a href="" data-dismiss="modal" onClick={this.goToFaq}>
                          View all
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="box">
                      <h4>Scan Modules & Questions in Template</h4>
                      <div className="custtom_according acc_cus1 template_cus">
                        <div className="bs-example">
                          <div className="accordion" id="accordionExample">
                            {/* {this.state.subcategory == '0' &&  */}
                            <div className="row spe6 no-specing cat_row">
                              <div className="col-md-12">
                                <div className="tex45">
                                  {/* <h5>Select Category:</h5> */}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="tex45">
                                  <select
                                    onChange={this.onSelectchange}
                                    name="category"
                                    value={category}
                                    required
                                  >
                                    <option value="0">Select Category</option>
                                    {this.state.categories &&
                                      this.state.categories.map(
                                        (con, index) => {
                                          return (
                                            <option
                                              key={`category${index}`}
                                              value={con.id}
                                            >
                                              {con.name}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                </div>
                              </div>
                            </div>
                            {/* } */}

                            {this.state.subcategory != "0" &&
                              moduleQuestions?.map((subcat, ind) => {
                                return (
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id={"headingOne" + ind}
                                    >
                                      <h2 className="mb-0">
                                        <button
                                          type="button"
                                          className="btn btn-link"
                                          data-toggle="collapse"
                                          data-target={"#collapseOne" + ind}
                                        >
                                          {subcat.subcategory_name}
                                          <i className="fa fa-angle-down"></i>{" "}
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id={"collapseOne" + ind}
                                      className="collapse"
                                      aria-labelledby={"headingOne" + ind}
                                      data-parent="#accordionExample"
                                    >
                                      <div className="card-body cus56">
                                        {subcat.questions.map((ques, index) => {
                                          return <p>{ques.question} </p>;
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="box">
                      <h4>Demo Video</h4>
                      <div className="img6_outer">
                        <img
                          src={ctre45}
                          onClick={() => this.redirectToDemo()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="box vill98">
                      <h4>Blog Posts</h4>
                      <div className="blog_box1">
                        <img src={blog1} />
                        <div className="texcd4">
                          <p>Creating A Personal Connection With Eternaview</p>
                          <span>
                            <img src={arrow_right} />
                          </span>
                        </div>
                      </div>
                      <div className="btn78">
                        <a href="" data-dismiss="modal" onClick={this.goToBlog}>
                          View all
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {this.props.auth.loginUserToken && <div className="modal" id="myModal2">
               <div className="modal-dialog">
                  <div className="modal-content">

                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Choose Your Eternaview</h4>
                     </div>

                     <div className="modal-body">
                        <div className="custtom_according">
                           <div className="bs-example">
                              
                              <div className="row spe6 no-specing">
                                 <div className="col-lg-7 col-md-12">
                                    <div className="tex45">
                                       <h5>Select Category:</h5>
                                    </div>
                                 </div>
                                 <div className="col-lg-5 col-md-12">
                                    <div className="tex45">
                                       <select onChange={this.onSelectchange} name="category" value={category} required>
                                          <option value='0'>Select Category</option>
                                          {
                                             this.state.categories && this.state.categories.map((con, index) => {
                                                return <option key={`category${index}`} value={con.id}>{con.name}</option>
                                             })
                                          }
                                       </select>
                                    </div>
                                 </div>
                              </div>

                              <div className="row spe6">
                                 <div className="col-lg-7 col-md-12">
                                    <div className="tex45">
                                       <h5>Type of Eternaview you want to create?</h5>
                                    </div>
                                 </div>
                                 <div className="col-lg-5 col-md-12">
                                    <div className="tex45">
                                       <div className="checkouter56">
                                          <label className="container9">Video<input type="radio" name="radio" value="0" checked={typecreate == '0'} onChange={this.onTypeSelected} /><span className="checkmark"></span></label>
                                          <label className="container9">Audio-only<input type="radio" name="radio" value="1" checked={typecreate == '1'} onChange={this.onTypeSelected} /><span className="checkmark"></span></label>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              {moduleQuestions.length > 0 && <div className="accordion" id="accordionExample">
                                 {moduleQuestions?.map((subcat, ind) => {
                                    return (<div className="card">
                                       <div className="card-header" id={"headingOne" + (ind + 1)}>
                                          <h2 className="mb-0">
                                             <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + (ind + 1)}>{subcat.subcategory_name}<i className="fa fa-angle-right"></i> </button>
                                          </h2>
                                       </div>

                                       <div id={"collapseOne" + (ind + 1)} className="collapse" aria-labelledby={"headingOne" + (ind + 1)} data-parent="#accordionExample">
                                          <div className="card-body">
                                             {
                                                subcat.questions.map((ques, index) => {
                                                   return (
                                                      <p>{ques.question}</p>
                                                   );
                                                })
                                             }
                                          </div>
                                       </div>
                                    </div>);
                                 })}

                              </div>}
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                     </div>

                  </div>
               </div>
            </div>} */}

        {this.props.auth.loginUserToken && (
          <div id="myModal2" className="modal fade" role="dialog">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                  <h4 className="modal-title">Choose Your Eternaview</h4>
                </div>
                <div className="modal-body custom_body">
                  <div className="spec_outer">
                    <div className="row spe6 no-specing">
                      <div className="col-lg-3 col-md-12">
                        <div className="tex45">
                          <h5>Select Category:</h5>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-12">
                        <div className="tex45">
                          <select
                            onChange={this.onSelectchange}
                            name="category"
                            value={category}
                            required
                          >
                            <option value="0">Select Category</option>
                            {this.state.categories &&
                              this.state.categories.map((con, index) => {
                                return (
                                  <option
                                    key={`category${index}`}
                                    value={con.id}
                                  >
                                    {con.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row spe6">
                      <div className="col-lg-3 col-md-12">
                        <div className="tex45">
                          <h5>Type of Eternaview you want to create?</h5>
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-12">
                        <div className="tex45">
                          <div className="checkouter56">
                            <label className="container9">
                              Video
                              <input
                                type="radio"
                                name="radio"
                                value="0"
                                checked={typecreate == "0"}
                                disabled={
                                  this.state.verifyPlanDetail
                                    .subscribe_plan__plan_type == "audio"
                                    ? true
                                    : false
                                }
                                onChange={this.onTypeSelected}
                              />
                              <span className="checkmark"></span>
                            </label>
                            <label className="container9">
                              Audio-only
                              <input
                                type="radio"
                                name="radio"
                                value="1"
                                checked={typecreate == "1"}
                                disabled={
                                  this.state.verifyPlanDetail
                                    .subscribe_plan__plan_type ==
                                    "video_audio" ||
                                  this.state.verifyPlanDetail
                                    .subscribe_plan__plan_type == "video&audio"
                                    ? true
                                    : false
                                }
                                onChange={this.onTypeSelected}
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row spe6">
                      {moduleQuestions && moduleQuestions.length > 0 && (
                        <div className="col-lg-3 col-md-12">
                          <div className="tex45">
                            <h5>
                              If you would like to scan the questions ahead of
                              time, click here:
                            </h5>
                          </div>
                        </div>
                      )}

                      <div className="col-lg-9 col-md-12">
                        <div
                          className="custtom_according create_custom_acc"
                          id="accorscroll"
                        >
                          <div className="bs-example">
                            <div className="accordion" id="accordionExample2">
                              {moduleQuestions?.map((subcat, ind) => {
                                return (
                                  <div className="card">
                                    <div
                                      className="card-header"
                                      id={"headingOne" + ind}
                                    >
                                      <h2 className="mb-0">
                                        <button
                                          type="button"
                                          onClick={(e) => this.setScroll(e)}
                                          className="btn btn-link"
                                          data-toggle="collapse"
                                          data-target={"#collapseOne" + ind}
                                        >
                                          {subcat.subcategory_name}
                                          <i className="fa fa-angle-down"></i>{" "}
                                        </button>
                                      </h2>
                                    </div>
                                    <div
                                      id={"collapseOne" + ind}
                                      className="collapse"
                                      aria-labelledby={"headingOne" + ind}
                                      data-parent="#accordionExample2"
                                    >
                                      <div className="card-body">
                                        {subcat.questions.map((ques, index) => {
                                          return (
                                            <button>
                                              {ques.question}
                                              <span className="custom_icon"></span>
                                            </button>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="submit_outer45 nex45">
                    {this.state.category != "0" && (
                      <button
                        type="button"
                        className="btn"
                        onClick={this.nextCreate}
                        data-dismiss="modal"
                      >
                        Next
                      </button>
                    )}
                    {this.state.category == "0" && (
                      <button
                        type="button"
                        className="btn"
                        onClick={this.nextCreate}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getCategories: bindActionCreators(getCategories, dispatch),
  getSubCategories: bindActionCreators(getSubCategories, dispatch),
  getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
  getSubCategoriesByCat: bindActionCreators(getSubCategoriesByCat, dispatch),
  scanQuestions: bindActionCreators(scanQuestions, dispatch),
  verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateIntroDating);
