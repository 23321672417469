import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Redirect } from 'react-router-dom';
import { subscribeToPlans } from "../../redux/actions/index";
import p1 from "../../Images/visa1.png";
import p2 from "../../Images/paypal1.png";
import p3 from "../../Images/jcb (1).png";
import p4 from "../../Images/american-express1.png";
import p5 from "../../Images/discover.jpeg";
import { REACT_APP_BASE_ENDPOINT } from "../../config/connection";

const SubscribeForms = ({ history, location, match }) => {
  const dispatch = useDispatch();
  const paramvalue = useParams();
  const [planAmount] = '200';
  const auth = useSelector(state => state);
  const [name, setName] = useState('');
  const [messages, _setMessages] = useState('');
  const [subscription, setSubscription] = useState();
  const [email, setEmail] = useState('');
  const [btnSubmit, setBtnSubmit] = useState(false);
  const [paramUrl, setParamUrl] = useState(match.params.id);
  const return_url=window.location.href;
  const setMessage = (message) => {
    _setMessages(`${messages}\n\n${message}`);
  }
  const query = new URLSearchParams(window.location.search);

  if (query.get('success')) {
      // setSuccess(true);
      // setSessionId(query.get('session_id'));
      //alert('success')
      var priceLookupKey= localStorage.getItem('priceLookupKey')
      var planId= localStorage.getItem('planId')
      var emailId= localStorage.getItem('email')

      // let body = {
      //   price_id: priceLookupKey,
      //   plan_id: planId,
      //   paymentMethodId: paymentMethod.id,
      //   emailId
      // }
      let body = {
        price_id: priceLookupKey,
        plan_id: planId,
        paymentMethodId: 'paymentMethod.id',
        emailId:emailId
      }

      

      dispatch(subscribeToPlans(body, auth.auth.loginUserToken, (result) => {
        console.log(result);
        if (result.subscription) {
          setMessage(`Subscription created with status: ${result.subscription.status}`);
          setSubscription(result.subscription);
          if (result.subscription.status == "active") {
            //history.push(`/create/eternaview/${paramUrl}`);
            window.location.assign('/home/profile');
          }
        }

      }));

      if (subscription) {
        switch (subscription.status) {
          case 'active':
            // Redirect to account page
            setMessage("Success! Redirecting to your account.");
            break;

          case 'incomplete':
            setMessage("Please confirm the payment.");

             
            break;

          default:
            setMessage(`Unknown Subscription status: ${subscription.status}`);
        }
      }
    }else{
    // localStorage.setItem('priceLookupKey',location.state.priceLookupKey)
    // localStorage.setItem('planId',location.state.planId)
    // localStorage.setItem('email',location.state.email)
  }
  // const [priceLookupKey] = useState(location.state.priceLookupKey);
  // const [planId] = useState(location.state.planId);
  

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    return '';
  }
  const updateSubscription = async (e)=>{

    var priceLookupKey= localStorage.getItem('priceLookupKey')
    var planId= localStorage.getItem('planId')
    var email= localStorage.getItem('email')

    let data = await fetch(`${REACT_APP_BASE_ENDPOINT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + auth.auth.loginUserToken
      },
      body: JSON.stringify({
        return_url: return_url,
        plan_id: 'planId'
      }),
    }).then(r => r.json())
      .catch(error => console.log('Error! ' + error.message));

     
  }
  const handleSubmit = async (e) => {
    e.preventDefault();

    const cardElement = elements.getElement(CardElement);
    // alert('hiii'); return;
    setBtnSubmit(true);
    let { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        email: email
      }
    });

    if (error) {
      setMessage(error.message);
      return;
    }

    setMessage(`Payment method created ${paymentMethod.id}`);
    let body = {
      //price_id: priceLookupKey,
      plan_id: 'planId',
      paymentMethodId:' paymentMethod.id',
      email:"asd"
    }
 

    dispatch(subscribeToPlans(body, auth.auth.loginUserToken, (result) => {
      console.log(result);
      if (result.subscription) {
        setMessage(`Subscription created with status: ${result.subscription.status}`);
        setSubscription(result.subscription);
        if (result.subscription.status == "active") {
          history.push(`/create/eternaview/${paramUrl}`);
          // location.history.push('/home/profile');
        }
      }

    }));

    if (subscription) {
      switch (subscription.status) {
        case 'active':
          // Redirect to account page
          setMessage("Success! Redirecting to your account.");
          break;

        case 'incomplete':
          setMessage("Please confirm the payment.");

          const { error } = await stripe.confirmCardPayment(
            subscription.latest_invoice.payment_intent.client_secret,
          )

          if (error) {
            setMessage(error.message);
          } else {
            setMessage("Success! Redirecting to your account.");
            setSubscription({ status: 'active' });
          }
          break;

        default:
          setMessage(`Unknown Subscription status: ${subscription.status}`);
      }
    }
 
  }
//"http://localhost:4242/create-checkout-session" method="POST"
  return (
    <form className="payment_form" onSubmit={updateSubscription} method="POST">
      <div className="row payment_row">
        <div className="col-md-4 offset-md-4 col-sm-12">
          <div className="payamonut_div">
          <form action={`${REACT_APP_BASE_ENDPOINT}/stripe/checkout/session/`} method="POST"> 
            <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
            <input type="hidden" name="return_url" value={return_url} />

            <button id="checkout-and-portal-button" type="submit">
              Checkout
            </button>
          </form>
            <span className="payable_amount"><b>Payable Amount:</b> ${planAmount}</span>
          </div>
        </div>
      </div>
      <div className="row payment_row">
        <div className="col-md-12">
          <div className="credit_debit_outer">
            <div className="credit_debit_title">Enter Debit/Credit Card</div>
            <ul className="debit_credit_ul">
              <li><a href="#"><img src={p1} /></a></li>
              <li><a href="#"><img src={p5} /></a></li>
              <li><a href="#"><img src={p3} /></a></li>
              <li><a href="#"><img src={p4} /></a></li>
            </ul>
            <p className="small_text">Accordingly amount will be deducted from the card you will enter.</p>
          </div>
        </div>
      </div>
      <div className="row payment_row">
        <div className="col-md-6">
          <label>Enter Email</label>
          <input
            type="email"
            value={email}
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            required />
        </div>
        <div className="col-md-6">
          <label>Enter Name</label>
          <input
            type="text"
            value={name}
            placeholder="Enter name"
            onChange={(e) => setName(e.target.value)}
            required />
        </div>
      </div>
      <div className="row payment_row">
        <div className="col-md-12">
          <div className="payment_input">
            <CardElement />
          </div>
        </div>
      </div>

      {btnSubmit == false && <button className="cancel_sub" type="submit">
        Subscribe
      </button>}

      {btnSubmit == true && <button className="cancel_sub" type="button">
        Subscribing...
      </button>}

      <div>{messages}</div>
    </form>
  )
}

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};


const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const SubscribeDone = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state);
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState(''); 
  const [subscription, setSubscription] = useState();
   const [paramUrl, setParamUrl] = useState(props.id);
  useEffect(() => {
    if (auth.auth.loginUserToken == "") {
      props.history.push('/');
    }
    console.log(props.priceLookupKey)
    console.log(props.planId)
    console.log(props.email)
    
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
      var priceLookupKey= localStorage.getItem('priceLookupKey')
      var planId= localStorage.getItem('planId')
      var email= localStorage.getItem('email')

      let body = {
        price_id: priceLookupKey,
        plan_id: planId,
        // paymentMethodId: paymentMethod.id,
        email
      }

      

      dispatch(subscribeToPlans(body, auth.auth.loginUserToken, (result) => {
        console.log(result);
        if (result.subscription) {
          setMessage(`Subscription created with status: ${result.subscription.status}`);
          setSubscription(result.subscription);
          if (result.subscription.status == "active") {
            //history.push(`/create/eternaview/${paramUrl}`);
            window.location.assign('/home/profile');
          }
        }

      }));

      if (subscription) {
        switch (subscription.status) {
          case 'active':
            // Redirect to account page
            setMessage("Success! Redirecting to your account.");
            break;

          case 'incomplete':
            setMessage("Please confirm the payment.");

             
            break;

          default:
            setMessage(`Unknown Subscription status: ${subscription.status}`);
        }
      }
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
    console.log(props);
  }, [sessionId]);

  let stripePromise = null;

  const [publishableKey, setPublishableKey] = useState(null);

  if (!success && message === '') {
     
  } else if (success && sessionId !== '') {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }


  if (publishableKey) {
    stripePromise = loadStripe('pk_test_51JNf1bBcsK7hC85NTATFObKQVyDrs6dGsG8gKvvZW9tZRK3t26d9unVC1M63YfyUrUdh2u4lT85mVgAQLhkPGVWu00hVntFYDe');
  } else {
    // fetch("http://eterna.itechnolabs.tech/config").then(r => r.json()).then(({publishableKey}) => {
    setPublishableKey('pk_test_51JNf1bBcsK7hC85NTATFObKQVyDrs6dGsG8gKvvZW9tZRK3t26d9unVC1M63YfyUrUdh2u4lT85mVgAQLhkPGVWu00hVntFYDe');
    // });
  }


  return (
    <div className="add_subscription_outer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="intract_outer_subscription">
              <Elements stripe={stripePromise}>
                <h2> Checkout</h2>
                <hr />

                <p>
                  Try the successful test card: <span>4242424242424242</span>
                </p>

                <p>
                  Try the test card that requires SCA: <span>4000002500003155</span>
                </p>

                <p>
                  Use any future expiry date, CVC, 5 digit postal code
                </p>

                <hr />

                <SubscribeForms {...props} />
              </Elements>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(SubscribeDone);

 