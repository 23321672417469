import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import lightBulb from "../../Images/lightbulb .png";
import {
  LexRuntimeV2Client,
  RecognizeTextCommand,
  RecognizeUtteranceCommand,
} from "@aws-sdk/client-lex-runtime-v2";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";
import bg29 from "../../Images/bg29.png";
import real_think from "../../Images/real_think.png";
import small_integote from "../../Images/small_integote.png";
import purpal_check from "../../Images/purpal_check.png";
import micck from "../../Images/micck.png";
import mic from "../../Images/mic.gif";
import green from "../../Images/green.png";
import red_mick from "../../Images/red_mick.png";
import hand_key from "../../Images/hand_key.png";
import { toast } from "react-toastify";
import {
  postInteractQuestion,
  getEternaviewDetail,
  likeEternaview,
  getAnsweredQuestions,
  verifyUserSubscription,
  getParticularQuestionDetail,
  getInteractParticularQuestionDetail,
  getQueryDetail,
} from "../../redux/actions";
// import vector_audio from "../../Images/vector_audio.jpg";
import vector_audio from "../../Images/audio.gif";
import hand_outer4 from "../../Images/hand_outer4.png";
import Loader from "../../Images/buffer.gif";
import Moment from "moment";
import { Base64 } from "js-base64";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Recorder from "../../components/Recorder";
import InteractVideo from "./components/interactVideo";
import InteractAudio from "./components/interactAudio";
import sample_audio from "../../Sounds/no_sound.mp3";

import $ from "jquery";
import FacebookPixel from "../../MetaComponents/Facebookpixel";

const client = new LexRuntimeV2Client({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAQUMBY6QYP5X7UIXX",
    secretAccessKey: "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",
  },
});
const input = () => {
  const field = document.querySelector("input");
  // document.querySelector('p').style.display = 'none';
  // field.style.display = 'block'
  field.focus(); // focus the element
};
class InteractOne extends Component {
  constructor(props) {
    //alert(window.location.origin);

    super(props);
    this.myRef = React.createRef();

    this.state = {
      isTipsOpen: false,
      recording: false,
      recordedAudio: {},
      show_input: false,
      show_modal_input: false,
      searchinput: "",
      videoUrl: "",
      videoType: "",
      orgUrl: "",
      detail: {},
      micRecording: false,
      micModalRecording: false,
      moduleQuestions: [],
      showLike: false,
      askQuestionBit: false,
      permission: "",
      base64regex:
        /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/,
      accesspermission: false,
      showHints: false,
      maxInteract: false,
      hideInteractOptions: false,
      no_transcoding: false,
      noInteract: false,
      blobURL: "",
      botResponse: "",
      category: "",
      screen_mode: "landscape",
      showUtterance: false,
      utteranceText: "",
      sizeAlert: "",
      modeAlert: "",
      stylePath: window.location.origin + "/css/intract/" + "mobileView.css",
      shallUpdate: true, //this is taken to prevent from re-rendder issue if you set it false then also set to true on end of task
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.getTextResponseFromRecorder =
      this.getTextResponseFromRecorder.bind(this);
  }
  setScroll(e) {
    /* $('#topicPopup').animate({
      scrollTop: $(e.target).offset().top-500
    }, 1000); */
  }

  componentDidMount() {
    setTimeout(() => {
      {
        this.handleOrientationChange();
      }
    }, 1000);

    setTimeout(() => {
      this.callback_dynamic_css();
    }, 1000);

    window.addEventListener("resize", this.callback_dynamic_css);
    window.addEventListener("resize", this.handleOrientationChange);

    // window.screen.orientation.onchange = function(e) {
    //     if (window.innerHeight > window.innerWidth) {
    //         //portrait
    //         $("#vdosection").addClass('videoPortrait');
    //         $("#vdosection").addClass('videoPortraitNew');
    //         $("#vdosection").removeClass('videoLandscape');
    //         alert("You are in portrait mode");
    //       }

    //       if (window.innerHeight < window.innerWidth) {
    //          //landscape
    //          $("#vdosection").addClass('videoLandscape');
    //          $("#vdosection").removeClass('videoPortrait');
    //          $("#vdosection").removeClass('videoPortraitNew');
    //         alert("You are in landscape mode");
    //       }
    //     }

    setTimeout(function () {
      //  $('html,body').animate({scrollTop:$('#vdosection').offset().top});
    }, 2000);

    console.log(this.state.base64regex.test(this.props.match.params.id));

    if (this.props.match.params.id || this.props.match.params.author) {
      if (
        this.state.base64regex.test(this.props.match.params.id) &&
        this.state.base64regex.test(this.props.match.params.author)
      ) {
        console.log("asd");
        this.props.match.params.id = Base64.decode(this.props.match.params.id);
        this.props.match.params.author = Base64.decode(
          this.props.match.params.author
        );
      }
      this.setState({ permission: this.props.match.params.permission });

      this.getMyDetail(this.props.match.params.permission);
    } else {
      this.getMyDetail("");
    }

    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
      if (res.is_active == false) {
        localStorage.clear();
      } else {
        if (res.data) {
          if (
            res.data.updated_details.no_of_query == 0 &&
            res.data.updated_details.grace_no_of_query == 0
          ) {
            this.setState({ maxInteract: true, hideInteractOptions: true });
          }
        }
      }
    });

    this.props.getQueryDetail(this.props.match.params.id, "", (result) => {
      if (result.no_of_query == 0) {
        this.setState({ maxInteract: true, hideInteractOptions: true });
      }
    });
  }

  getMyDetail(permission) {
    this.props.getEternaviewDetail(
      this.props.match.params.id,
      permission,
      this.props.auth.loginUserToken != ""
        ? this.props.auth.loginUserToken
        : "",
      (res) => {
        if (res.detail) {
          toast(res.detail, {
            position: "top-right",
            autoClose: 3000,
            type: "error",
          });
        }
        if (
          this.props.auth.loginUserToken == "" &&
          res.access_permission == "restricted"
        ) {
          toast("You do not have permission to perform this action.", {
            position: "top-right",
            autoClose: 3000,
            type: "error",
          });
        } else {
          if (res.is_active == false) {
            this.setState({ noInteract: true });
          } else {
            this.setState({
              orgUrl: res.nodding_video,
              detail: res,
              category: res.category_name,
            });
            this.setState({ no_transcoding: false });
            if (res.type != "audio") {
              const transcode_url = res.nodding_video;
              if (transcode_url) {
                let uri = transcode_url;
                let parts = uri.split("?");
                let query = parts[1].split("&"); // "height=1080&width=1920"
                var width = parseInt(query[1].split("=")[1]);
                var height = parseInt(query[0].split("=")[1]);
                //if(width>height)
                if (res.screen_mode != "portrait") {
                  this.setState({ screen_mode: "landscape" });
                  //landscape
                  $("#vdosection").addClass("videoLandscape");
                  $("#vdosection").removeClass("videoPortrait");
                  // alert('landscape');
                  $("#thumbnail_portrait").addClass("thumbLandscape");
                  $("#thumbnail_portrait").removeClass("thumbPortrait");
                } else {
                  this.setState({ screen_mode: "portrait" });
                  //portrait
                  $("#vdosection").addClass("videoPortrait");
                  $("#vdosection").removeClass("videoLandscape");

                  $("#thumbnail_portrait").addClass("thumbPortrait");
                  $("#thumbnail_portrait").removeClass("thumbLandscape");
                  // alert('Portrait');
                }
                // alert(height);
                // alert(width);
              }
            } else {
              document
                .getElementById("audioIteractor")
                .setAttribute("src", sample_audio);
            }

            this.setState({
              videoUrl: res.nodding_video,
              videoType: "application/x-mpegURL",
            });
            if (res.access_permission == "restricted") {
              this.setState({ accesspermission: true });
            }

            this.props.getAnsweredQuestions(
              this.props.match.params.author,
              this.props.match.params.id,
              "",
              (response) => {
                let allquestions = [];
                if (response.data && response.data.length > 0) {
                  response.data.map((con, index) => {
                    if (con.id == res.category_id) {
                      con.modules.map((subcat, ind) => {
                        let cat = subcat.subcategory_name;

                        if (
                          con.category_name == "Dating" &&
                          cat !=
                            "Interaction Facilitation & Pleasantries (required)"
                        ) {
                          allquestions.push(subcat);
                        }

                        if (
                          con.category_name == "Legacy" &&
                          cat != "Interaction Facilitation Part 1 (required)" &&
                          cat != "Interaction Facilitation Part 2" &&
                          cat != "Pleasantries"
                        ) {
                          allquestions.push(subcat);
                        }
                      });
                    }
                  });
                  this.setState({ moduleQuestions: allquestions });
                }
              }
            );
          }
        }
      }
    );
  }

  questionVideo = (id) => {
    /* var player = videojs('vid2');

        player.fill(true); */

    $(".text_to_speech").hide();

    $(".play_pausebutton").hide();

    this.setState({ no_transcoding: false });
    let token = this.props.auth.loginUserToken;

    let url = `author-question/${id}/?author_id=${this.state.detail.author}&query=yes&view_id=${this.props.match.params.id}`;

    if (token) {
      if (localStorage.getItem("super")) {
        url = `author-question/${id}/?author_id=${this.state.detail.author}&view_id=${this.props.match.params.id}&user_type=admin`;
        token = localStorage.getItem("super");
      }
      this.props.getParticularQuestionDetail(url, token, (res) => {
        $(".playerIcon").removeClass("fa-play");
        $(".playerIcon").addClass("fa-pause");
        $(".play_pausebutton").show();

        this.setState({ showHints: false });
        $("#topicPopup").hide();
        if (res.data && res.data.answer.id) {
          this.setState({ recordedAudio: {} });

          this.setState({
            recording: true,
            searchinput: "",
            show_input: false,
          });

          if (res.data.answer.video.trans_coded_url != null) {
            this.setState({
              videoUrl: res.data.answer.video.trans_coded_url,
              videoType: "application/x-mpegURL",
            });

            const transcode_url = res.data.answer.video.trans_coded_url;
            let uri = transcode_url;

            let parts = uri.split("?");

            let query = parts[1].split("&"); // "height=1080&width=1920"
            var width = query[1].split("=")[1];
            var height = query[0].split("=")[1];
            if (width > height) {
              //landscape
              $("#vdosection").addClass("videoLandscape");
              $("#vdosection").removeClass("videoPortrait");
            } else {
              //portrait
              $("#vdosection").addClass("videoPortrait");
              $("#vdosection").removeClass("videoLandscape");
            }
          } else if (res.data.answer.video.type == "audio") {
            this.setState({ videoUrl: res.data.answer.video.media_file });
          } else {
            this.setState({ no_transcoding: true });
          }
        }
      });
    } else {
      if (localStorage.getItem("super")) {
        url = `question/detail/${id}/?author_id=${this.state.detail.author}&user_type=admin&view_id=${this.props.match.params.id}`;
      } else {
        url = `question/detail/${id}/?author_id=${this.state.detail.author}&query=yes&view_id=${this.props.match.params.id}`;
      }

      this.props.getInteractParticularQuestionDetail(url, "", (res) => {
        $(".playerIcon").removeClass("fa-play");
        $(".playerIcon").addClass("fa-pause");
        $(".play_pausebutton").show();

        this.setState({ showHints: false });
        if (res.data && res.data.answer.id) {
          this.setState({ recordedAudio: {} });
          $("#topicPopup").hide();
          if (res.data && res.data.answer.id) {
            this.setState({
              recording: true,
              searchinput: "",
              show_input: false,
            });

            if (res.data.answer.video.trans_coded_url != null) {
              const transcode_url = res.data.answer.video.trans_coded_url;
              let uri = transcode_url;

              let parts = uri.split("?");

              let query = parts[1].split("&"); // "height=1080&width=1920"
              var width = query[1].split("=")[1];
              var height = query[0].split("=")[1];
              if (width > height) {
                //landscape
                $("#vdosection").addClass("videoLandscape");
                $("#vdosection").removeClass("videoPortrait");
              } else {
                //portrait
                $("#vdosection").addClass("videoPortrait");
                $("#vdosection").removeClass("videoLandscape");
              }
              this.setState({
                videoUrl: res.data.answer.video.trans_coded_url,
                videoType: "application/x-mpegURL",
              });
            } else if (res.data.answer.video.type == "audio") {
              this.setState({ videoUrl: res.data.answer.video.media_file });
            } else {
              this.setState({ no_transcoding: true });
            }
          }
        }
      });
    }
  };

  responseReceive(response) {
    if (response.interpretations && response.interpretations.length > 0) {
      this.sendToGet(
        response.inputTranscript,
        response.interpretations,
        "audio"
      );
    }
  }

  interactResponseReceive(response) {
    if (response == true) {
      this.setState({ recording: false, askQuestionBit: false });
    }
  }

  sendToGet(question, interpretations, query_type, query_is_audio = false) {
    let formData = new FormData();

    formData.append("question", question);
    formData.append("language", 1);
    formData.append("apikey", "dsDKL4342jertn6438");
    formData.append("type", "normal");
    formData.append("duration", "0");
    formData.append("interact_user_id", this.props.match.params.author);
    formData.append("interact_view_id", this.props.match.params.id);
    formData.append("interpretations", JSON.stringify(interpretations));
    formData.append("query_type", query_is_audio ? "audio" : query_type); // query_type:audio

    this.props.postInteractQuestion(
      formData,
      this.props.auth.loginUserToken ? this.props.auth.loginUserToken : "",
      (res) => {
        this.setState({ shallUpdate: false });
        this.setState({ askQuestionBit: true, no_transcoding: false });

        this.setState({ recordedAudio: {} });
        this.setState({ showHints: false });
        this.setState({
          searchinput: question,
        });
        $("#keyboardPopup").hide();
        this.setState({ shallUpdate: true });
        if (res.data) {
          this.setState({
            recording: true,
            searchinput: "",
            show_input: false,
            askQuestionBit: false,
          });

          if (res.data && res.data.data.answer.video.type == "audio") {
            // alert("text query"+res.data.data.answer.video.media_file);

            // this.setState({ videoUrl: res.data.data.answer.video.media_file });
            console.log(this.state.videoUrl);
            // $(".audioInterpretor").play();
            //alert("hehe");
            document
              .getElementById("audioIteractor")
              .setAttribute("src", res.data.data.answer.video.media_file);
            document.getElementById("audioIteractor").play();
          } else {
            const transcode_url = res.data.data.answer.video.trans_coded_url;
            let uri = transcode_url;
            let parts = uri.split("?");
            let query = parts[1].split("&");
            var width = query[1].split("=")[1];
            var height = query[0].split("=")[1];
            if (width > height) {
              //landscape
              $("#vdosection").addClass("videoLandscape");
              $("#vdosection").removeClass("videoPortrait");
            } else {
              //portrait
              $("#vdosection").addClass("videoPortrait");
              $("#vdosection").removeClass("videoLandscape");
            }

            this.setState({
              videoUrl: res.data.data.answer.video.trans_coded_url,
              videoType: "application/x-mpegURL",
            });
          }

          $(".playerIcon").removeClass("fa-play");
          $(".playerIcon").addClass("fa-pause");
          $(".play_pausebutton").show();
        }
      }
    );
  }
  hideTranscript() {
    this.setState({
      showUtterance: false,
      utteranceText: "",
    });
  }
  shouldComponentUpdate() {
    return this.state.shallUpdate;
  }
  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  showHintBulb = (e) => {
    if ($("#topicPopup").css("display") == "block") $("#topicPopup").hide();
    else $("#topicPopup").show();

    $("#keyboardPopup").hide();
    // this.setState({ showHints: !this.state.showHints, show_input: false });
  };

  showInput = (e) => {
    if ($("#keyboardPopup").css("display") == "block")
      $("#keyboardPopup").hide();
    else {
      $("#keyboardPopup").show();
      $("#typeInQuetion").focus();
    }

    $("#topicPopup").hide();
    //this.setState({ show_input: !this.state.show_input, showHints: false });
  };
  hideTopicPopup = (e) => {
    e.preventDefault();

    $("#topicPopup").hide();
  };
  showModalInput = (e) => {
    this.setState({ show_modal_input: !this.state.show_modal_input });
  };

  getTextResponseFromRecorder(data) {
    $(".text_to_speech").show();
    // console.log(data);
    this.setState({
      showUtterance: true,
      utteranceText: data.key.slice(1, -1),
    });
    this.sendToGet(data.key.slice(1, -1), data.interpretations, "text", true);
  }
  submitQuestion = async (e, ispassed = false, key = "") => {
    if (!ispassed) e.preventDefault();
    var keyword = ispassed ? key : $("#typeInQuetion").val();
    $(".text_to_speech").hide();

    if (keyword.trim() === "") {
      toast("Please enter your question.", {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
      return false;
    }
    if (document.getElementById("audioIteractor")) {
      document
        .getElementById("audioIteractor")
        .setAttribute("src", sample_audio);
      document.getElementById("audioIteractor").play();
    }

    const command = new RecognizeTextCommand({
      botAliasId: "TSTALIASID",
      botId: this.state.category == "Dating" ? "QPSJB0MH2O" : "RMEHNYXF57",
      localeId: "en_US",
      text: keyword,
      sessionId: "043758711856848",
    });

    const response = await client.send(command);
    if (response.interpretations && response.interpretations.length > 0) {
      $("#typeInQuetion").val("");

      this.sendToGet(keyword, response.interpretations, "text");
    }
  };

  likeView = (e) => {
    e.preventDefault();

    this.props.likeEternaview(
      this.props.match.params.id,
      this.props.auth.loginUserToken,
      (res) => {
        if (!res.detail) {
          this.setState({ showLike: true });
          setTimeout(() => this.setState({ showLike: false }), 3000);
        }
      }
    );
  };

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ maxInteract: false });
  };
  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.submitQuestion(e);
    }
  };
  togglePlayer() {
    var video = $("#secondVideo").find("video").get(0);
    if (video.paused) {
      $("#secondVideo").find("video")[0].play();
      $(".playerIcon").removeClass("fa-play");
      $(".playerIcon").addClass("fa-pause");
    } else {
      $("#secondVideo").find("video")[0].pause();
      $(".playerIcon").removeClass("fa-pause");
      $(".playerIcon").addClass("fa-play");
    }
  }
  togglePlayerAudio() {
    var video = $("#audioIteractor").get(0);
    if (video.paused) {
      $("#audioIteractor")[0].play();
      $(".playerIcon").removeClass("fa-play");
      $(".playerIcon").addClass("fa-pause");
    } else {
      $("#audioIteractor")[0].pause();
      $(".playerIcon").removeClass("fa-pause");
      $(".playerIcon").addClass("fa-play");
    }
  }

  handleOrientationChange = () => {
    const isMobileLandscape = window.matchMedia(
      "(max-width: 845px) and (orientation: landscape)"
    ).matches;
    const mobileViewLandscape = window.matchMedia(
      "(min-device-width: 341px) and (max-device-width: 991px) and (orientation: landscape)"
    );
    const ipadAirLandscape = window.matchMedia(
      "(min-device-width: 768px) and (max-device-width: 1199px) and (min-device-height: 1024px) and (orientation: landscape)"
    );
    this.setState({ isMobileLandscape });
    if (isMobileLandscape) {
      $("html,body").animate({ scrollTop: $("#vdosection").offset().top });
    } else if (ipadAirLandscape.matches) {
      $("html,body").animate({ scrollTop: $("#vdosection").offset().top });
      console.log("Portrait mode");
    } else {
      console.log("not ");
    }
  };

  callback_dynamic_css = () => {
    //  alert(window.innerWidth + ' x ' + window.innerHeight);
    /// toast('SIZE== '+window.innerWidth + ' x ' + window.innerHeight, { position: "top-left", autoClose: 3000, type: 'error', });

    this.setState({
      sizeAlert: "SIZE== " + window.innerWidth + " x " + window.innerHeight,
    });

    const iphone6Portrait = window.matchMedia(
      "(min-device-width: 350px) and (max-device-width: 395px) and (max-device-height: 670px) and (orientation:portrait)"
    );
    const samsungFoldFront = window.matchMedia(
      "(min-device-width: 250px) and (max-device-width: 300px) and (max-device-height: 653px) and (orientation:portrait)"
    );
    const iphone6Landscape = window.matchMedia(
      "(min-device-width: 350px) and (max-device-width: 700px) and (max-device-height: 670px) and (orientation:landscape)"
    );
    const ipadAirPortrait = window.matchMedia(
      "(min-device-width: 740px) and (max-device-width: 1024px) and (max-device-height: 1024px) and (orientation: portrait)"
    );
    const ipadAirLandscape = window.matchMedia(
      "(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape)"
      // "(min-device-width:767px)and (max-device-width:1024px)and (orientation:landscape)"
    );
    const mobileView = window.matchMedia(
      "(min-device-width: 341px) and (max-device-width: 767px) and (max-device-height: 812px) and (orientation: portrait)"
    );
    // const mobileViewLandscape = window.matchMedia(
    //   "(min-device-width: 320px) and (max-device-width: 991px) and (max-device-height: 991px) and (orientation: landscape)"
    // );

    const iphone8plusLandscape = window.matchMedia(
      "(min-device-width: 400px) and (max-device-width:736px) and (max-device-height: 800px) and (orientation:landscape)"
    );
    const iphone8 = window.matchMedia(
      "(min-device-width: 350px) and (max-device-width:700px) and (max-device-height: 700px) and (orientation:landscape)"
    );

    const iphone14proLandscape = window.matchMedia(
      // "(min-device-width:370px)and (max-device-width:850px)and (orientation: landscape)"
      "(min-device-width: 846px) and (orientation: landscape) "
    );
    // if (window.innerWidth == "820" && window.innerHeight == "1180") {
    //   this.setState({ modeAlert: "IPAD AIR PORTRAIT." });

    //   this.setState({
    //     stylePath: window.location.origin + "/css/intract/" + "ipadAir.css",
    //   });

    //   // return import(`../dashboard/ipadAir.css`), console.log("pardeep");
    // }
    if (ipadAirPortrait.matches) {
      this.setState({ modeAlert: "IPAD AIR PORTRAIT." });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "ipadAirPortrait.css",
      });
    } else if (iphone8.matches) {
      this.setState({ modeAlert: "iphone8." });

      this.setState({
        stylePath: window.location.origin + "/css/intract/" + "iphone8.css",
      });
    } else if (iphone6Portrait.matches) {
      this.setState({ modeAlert: "IPhone 6 Portrait" });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "iphone6Landscape.css",
      });
    } else if (iphone6Landscape.matches) {
      this.setState({ modeAlert: "IPhone 6" });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "iphone6Landscape.css",
      });
    } else if (samsungFoldFront.matches) {
      this.setState({ modeAlert: "Samsung fold " });

      this.setState({
        stylePath: window.location.origin + "/css/intract/" + "samsungFold.css",
      });
    } else if (iphone14proLandscape.matches) {
      this.setState({ modeAlert: "IPhone" });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "iphone14landscape.css",
      });
    } else if (iphone8plusLandscape.matches) {
      this.setState({ modeAlert: "Iphone 8 plus" });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "iphone8landscape.css",
      });
    } else if (ipadAirLandscape.matches) {
      this.setState({ modeAlert: "IPAD Landscape." });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "ipadAirLandscape.css",
      });
    } else if (mobileView.matches) {
      this.setState({ modeAlert: "mobileview" });

      this.setState({
        stylePath: window.location.origin + "/css/intract/" + "mobileView.css",
      });
    }
    //  else if (mobileViewLandscape.matches) {
    //   this.setState({ modeAlert: "mobile" });

    //   this.setState({
    //     stylePath:
    //       window.location.origin + "/css/intract/" + "mobileViewLandscape.css",
    //   });
    // }
    //  else if (window.innerWidth == "768" && window.innerHeight == "1024") {
    //   this.setState({ modeAlert: "ipad mini portrait." });
    //   console.log("ipad Portrait");

    //   this.setState({
    //     stylePath: window.location.origin + "/css/intract/" + "ipadAir.css",
    //   });

    //   // return import(`../dashboard/ipadAir.css`), console.log("pardeep");
    // }
    // else if (window.innerWidth == "1180" && window.innerHeight == "820") {
    //   this.setState({ modeAlert: "ipad air landscape" });
    //   console.log("ipad Landscape");

    //   this.setState({
    //     stylePath:
    //       window.location.origin + "/css/intract/" + "ipadAirLandscape.css",
    //   });

    //   //  return import(`../dashboard/ipadAir.css`), console.log("pardeep");
    // }
    else {
      this.setState({ modeAlert: "MOBILE" });

      this.setState({
        stylePath:
          window.location.origin + "/css/intract/" + "mobileViewLandscape.css",
      });

      // return import(`../dashboard/mobileView.css`), console.log("deep");
    }
    console.log(this.state.stylePath, "mobile");
    console.log(window.innerWidth);
    console.log(window.innerHeight);

    //else if (mobileView.matches) {
    /*       if (window.innerWidth == '1180' && window.innerHeight == '820') {
                  return import(`../dashboard/ipadAir.css`), console.log("pardeep");
  
              }
  
              else if (window.innerWidth == '1024' && window.innerHeight == '768') {
                  return import(`../dashboard/ipadAir.css`), console.log("pardeep");
              } */

    //   if () {
    //     // Then trigger an alert
    //     // alert("Media Query Matched!");
    //     import(`../../../public/css/style1.css`);
    //   }
  };

  render() {
    const {
      show_input,
      orgUrl,
      videoUrl,
      videoType,
      recording,
      micRecording,
      moduleQuestions,
      detail,
      showLike,
      showHints,
      no_transcoding,
      blobURL,
      category,
    } = this.state;

    return (
      <div>
        <FacebookPixel trackName={"ViewContent"}/>
        <link rel="stylesheet" type="text/css" href={this.state.stylePath} />

        <section
          ref={this.myRef}
          className="custom_img5 custom_video06 interactOne_video"
          id="vdosection"
        >
          {this.state.showUtterance == true ? (
            <span className="text_to_speech">{this.state.utteranceText}</span>
          ) : (
            ""
          )}

          {this.state.showUtterance == true ? (
            <span className="text_to_speech">{this.state.utteranceText}</span>
          ) : (
            ""
          )}
          {/* {
                        (recording == true && no_transcoding == false  && detail.type == "video") &&
                        <a href="javascript:void(0);" className={`play_pausebutton`} onClick={(e)=>this.togglePlayer()}>
                        <i class="playerIcon fas fa-pause"></i>
                    </a>
    }
                     { (recording == true && no_transcoding == false  && detail.type == "audio") &&
                      <a href="javascript:void(0);" className={`play_pausebutton`} onClick={(e)=>this.togglePlayerAudio()}>
                      <i class="playerIcon fas fa-pause"></i>
                  </a>
                    } */}
          {/* <span className="text_to_speech">Hi, how are you?</span> */}
          {recording == true && no_transcoding == true && (
            <div className="processed_video_outer">
              <img src={Loader} />
              <p>The video is still being processed. </p>
              <p>It should be ready in a couple minutes or less.</p>
            </div>
          )}

          {!this.state.noInteract ? (
            <a className="buttons_atag">
              {/* {micRecording == true && <div className="recording-interact"><img src={mic} hover-tooltip="Click to ask audio query to eternaview." tooltip-position="top"  /></div>} */}
              {micRecording == true && (
                <div className="recording-interact">
                  <img src={mic} tooltip-position="top" />
                </div>
              )}

              {detail && detail.type == "audio" && (
                <InteractAudio
                  setAudioTranscript={(val) => this.hideTranscript()}
                  setInteractResponse={(val) =>
                    this.interactResponseReceive(val)
                  }
                  recording={recording}
                  orgUrl={orgUrl}
                  videoUrl={videoUrl}
                  detail={detail}
                />
              )}

              {detail && detail.type == "video" && (
                <InteractVideo
                  setAudioTranscript={(val) => this.hideTranscript()}
                  setInteractResponse={(val) =>
                    this.interactResponseReceive(val)
                  }
                  recording={recording}
                  orgUrl={orgUrl}
                  videoUrl={videoUrl}
                  videoType={videoType}
                  no_transcoding={no_transcoding}
                  detail={detail}
                />
              )}
            </a>
          ) : (
            <div className="processed_video_outer">
              <p>Sorry, this eternaview is deactivated.</p>
              <p>It is not accessible at the moment.</p>
            </div>
          )}

          <div className="expend_outer">
            <a href="#myModal" data-toggle="modal">
              <i className="fa fa-expand" aria-hidden="true"></i>
            </a>
          </div>

          <div
            id="myModal201"
            className={
              this.state.maxInteract == true
                ? "modal fade show custom_modal"
                : "modal fade show show_custom_modal"
            }
            role="dialog"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close custom_close3"
                    onClick={this.closePopUp}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Sorry, this Eternaview has reached its maximum allowance in
                    terms of the queries that can be made to it. Please let the
                    creator know you would like to speak to their Eternaview so
                    that they may replenish their plan.
                  </p>
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={this.closePopUp}>
                    Okay!
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container intract-one-containter">
            <div className="row intract-one-toolbar-row">
              {this.state.hideInteractOptions == false && (
                <div className="col-md-3 custom_postiiou78">
                  {/* <div className="brea_outer viedo_overlap" hover-tooltip="Click to type your query to eternaview." tooltip-position="top" onClick={this.showInput}><a><i className="far fa-keyboard key_custom"></i></a></div> */}
                  <div
                    className="brea_outer viedo_overlap"
                    onClick={this.showInput}
                  >
                    <a>
                      <i className="far fa-keyboard key_custom"></i>
                    </a>
                  </div>
                  <Recorder
                    setTranscript={(val) => this.hideTranscript()}
                    getTextAudio={(val) =>
                      this.getTextResponseFromRecorder(val)
                    }
                    setBotResponse={(val) => this.responseReceive(val)}
                    client={client}
                    category={category}
                    setBlobURL={blobURL}
                  />
                </div>
              )}

              {show_input == false && <div className="col-md-3"></div>}

              <div className="col-md-3 ask_somethingpopup">
                <div
                  className="input67 video_overlap"
                  id="keyboardPopup"
                  style={{ display: "none" }}
                >
                  <input
                    type="text"
                    placeholder="Type in your question:"
                    name="searchinput"
                    id="typeInQuetion"
                    onKeyDown={this._handleKeyDown}
                  />
                  <div className="btn_outer45">
                    {!this.state.askQuestionBit ? (
                      <button
                        type="button"
                        className="btn"
                        onClick={this.submitQuestion}
                      >
                        Ask Question
                      </button>
                    ) : (
                      <button type="button" className="btn">
                        Asking...
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-1 play_pause_col">
                {/* <a href="javascript:void(0);" className={`play_pausebutton`} onClick={(e)=>this.togglePlayer()}>
                            <i class="playerIcon fas fa-pause"></i>
                        </a> */}
                {recording == true &&
                  no_transcoding == false &&
                  detail.type == "video" && (
                    <a
                      href="javascript:void(0);"
                      className={`play_pausebutton`}
                      onClick={(e) => this.togglePlayer()}
                    >
                      <i class="playerIcon fas fa-pause"></i>
                      {/* <i class="fas fa-pause"></i> */}
                    </a>
                  )}
                {recording == true &&
                  no_transcoding == false &&
                  detail.type == "audio" && (
                    <a
                      href="javascript:void(0);"
                      className={`play_pausebutton`}
                      onClick={(e) => this.togglePlayerAudio()}
                    >
                      <i class="playerIcon fas fa-pause"></i>
                      {/* <i class="fas fa-pause"></i> */}
                    </a>
                  )}
              </div>
              {this.state.noInteract == false && (
                <div className="col-md-2 small_inty6 custom_video89">
                  {/* <img src={small_integote} onClick={this.showHintBulb} data-toggle="tooltip" data-placement="top" title="Click to see the what questions are answered for the eternaview." /> */}
                  <span
                    className="span_tooltip"
                    hover-tooltip="Click any question to hear answer."
                    tooltip-position="top"
                    onClick={this.showHintBulb}
                  >
                    Topics
                  </span>
                </div>
              )}
            </div>
          </div>

          <div
            className="custtom_according interact-hint topic_popup_mainouter"
            id="topicPopup"
            style={{ display: "none" }}
          >
            <a
              id="closeButton11"
              href
              onClick={this.hideTopicPopup}
              className="topic_popup_close"
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </a>
            <div className="bs-example">
              <div className="accordion accordion-title " id="accordionExample">
                {moduleQuestions?.map((subcat, ind) => {
                  return (
                    <div className="card " key={subcat.id}>
                      <div className="card-header  " id={"headingOne" + ind}>
                        <h2 className="mb-0">
                          <button
                            type="button"
                            onClick={(e) => this.setScroll(e)}
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target={"#collapseOne" + ind}
                          >
                            <span className="buttonsubcat_span">
                              {subcat.subcategory_name}
                            </span>
                            <i className="fa fa-angle-down"></i>{" "}
                          </button>
                        </h2>
                      </div>
                      <div
                        id={"collapseOne" + ind}
                        className="collapse accordion-content"
                        aria-labelledby={"headingOne" + ind}
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          {subcat.questions.map((ques, index) => {
                            return (
                              <div key={ques.id}>
                                <button
                                  onClick={() => {
                                    this.questionVideo(ques.id);
                                  }}
                                >
                                  {ques.question}{" "}
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <section
          className="doiect_outer crete29 cus672 create-paid"
          id="thumbnail_portrait"
        >
          <div className="container">
            <div className="thamnail">
              <span
                className="span_tooltip tips-button-interact d-flex"
                hover-tooltip="Quick Tips"
                tooltip-position="top"
                onClick={() => {
                  this.setState({ isTipsOpen: !this.state.isTipsOpen });
                }}
              >
                <img
                  style={{ height: "20px", width: 25,marginRight:5 }}
                  src={lightBulb}
                  onClick={() => {
                    this.setState({ isTipsOpen: !this.state.isTipsOpen });
                  }}
                />
                Tips
              </span>
              {showLike && (
                <p className="custom_light">Your like is submitted!</p>
              )}
              {this.state.noInteract == false && (
                <img src={hand_outer4} onClick={this.likeView} />
              )}
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="cust7">
                  <div className="being56 cre45 mic">
                    <h4>To begin interacting by voice:</h4>
                    {/* <div className="small_ingt52  mick32">
                                        <img src={red_mick} /> */}
                    {/* <audio
                                                ref={a => {
                                                    this.audio = a;
                                                }}
                                            >
                                            </audio> */}
                    {/* </div> */}
                  </div>
                  <div className="told_outer">
                    <img src={purpal_check} />
                    <p>
                      Click on the microphone icon above to begin recording.
                    </p>
                  </div>
                  <div className="told_outer mic_told_outer">
                    <img src={purpal_check} />
                    <p>Speak your question into your device's microphone.</p>
                  </div>
                  <div className="told_outer">
                    <img src={purpal_check} />
                    <p>Click the microphone icon again to stop recording.</p>
                  </div>
                  <div className="told_outer">
                    <img src={purpal_check} />
                    <p>Wait to hear an answer!</p>
                  </div>
                </div>
                <div className="cust7 custom_cust7">
                  <div className="being56 cre45 mic">
                    <h4>To begin interacting by keyboard:</h4>
                    {/* <div className="small_ingt52 small_ing45 brea_outer viedo_overlap"> */}
                    {/* <img src={hand_key} /> */}
                    {/* <i className="far fa-keyboard key_custom"></i> */}
                    {/* <div className="brea_outer viedo_overlap"><i className="far fa-keyboard key_custom"></i></div> */}
                    {/* </div> */}
                  </div>
                  <div className="told_outer">
                    <img src={purpal_check} />
                    <p>Click on the keyboard icon above.</p>
                  </div>
                  <div className="told_outer">
                    <img src={purpal_check} />
                    <p>Type in your question then hit “Ask Question.”</p>
                  </div>
                  <div className="told_outer">
                    <img src={purpal_check} />
                    <p>Wait to hear an answer!</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="right_side_intracy">
                  <div className="mail_outer sawdow_inner grey5 custom_inte45">
                    <h5>Title: {detail.title}</h5>
                    <div className="row border42">
                      <div className="col-lg-6 col-md-12">
                        <div className="left450">
                          <p>Total Time Recorded:</p>
                          <h5>{detail.total_time}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="left450">
                          <p>Date Created:</p>
                          <h5>
                            {Moment(detail.created_on).format("MM-DD-YYYY")}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row border42">
                      <div className="col-lg-6 col-md-12">
                        <div className="left450">
                          <p># Questions Answered:</p>
                          <h5>{detail.total_question_recorded}</h5>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="left450">
                          <p>Last Updated:</p>
                          <h5>
                            {Moment(detail.updated_on).format("MM-DD-YYYY")}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row border42">
                      <div className="col-lg-12 col-md-12">
                        <div className="left450">
                          <p>Bio:</p>
                          <p className="dummy_tex5">
                            {detail.author__bio != ""
                              ? detail.author__bio
                              : "No bio added!"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg_color custom_colore4">
                  <p>
                    <b>Note:</b> You can also just click on the questions in the
                    “Topics” drop-down menu if you prefer to interact that way.
                  </p>
                </div>

                {/*  <div className="bg_color custom_colore4">
                  <p>{this.state.sizeAlert}</p>
                </div>
                <div className="bg_color custom_colore4">
                  <p>{this.state.modeAlert}</p>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <Modal
          style={{ marginTop: "70px", maxWidth: "1000px" }}
          isOpen={this.state.isTipsOpen}
          toggle={() => {
            this.setState({ isTipsOpen: !this.state.isTipsOpen });
          }}
          {...this.props}
        >
          <ModalHeader
            toggle={() => {
              this.setState({ isTipsOpen: !this.state.isTipsOpen });
            }}
          >
            Tips
          </ModalHeader>
          <ModalBody>
            <p>
              {" "}
              An Eternaview is content recorded from a human. It is not an
              avatar or deepfake.{" "}
            </p>
            <p>
              {" "}
              Use the <b>Topics</b> pop-up to explore questions that the
              Eternaview can answer, or ask your own questions in any way you
              prefer.
            </p>
            <p>
              When recording their Eternaview, all creators are given the same
              set of general questions, so it’s best that you also ask general
              questions about them.
            </p>
            <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
              <li>
                For instance, it’s better to ask <b>What was your job?</b>{" "}
                versus <b>Were you ever a vacuum salesman?</b>.
              </li>
              <li>
                Asking general questions about their life and family and friends
                should yield better results than asking about current events,
                math problems or the like.
              </li>
              <li>
                Treat the interaction as if you don’t know the creator
                personally. The creator won’t respond to their name or title and
                will not know your identity.
              </li>
            </ul>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  postInteractQuestion: bindActionCreators(postInteractQuestion, dispatch),
  getEternaviewDetail: bindActionCreators(getEternaviewDetail, dispatch),
  getAnsweredQuestions: bindActionCreators(getAnsweredQuestions, dispatch),
  likeEternaview: bindActionCreators(likeEternaview, dispatch),
  verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
  getParticularQuestionDetail: bindActionCreators(
    getParticularQuestionDetail,
    dispatch
  ),
  getInteractParticularQuestionDetail: bindActionCreators(
    getInteractParticularQuestionDetail,
    dispatch
  ),
  getQueryDetail: bindActionCreators(getQueryDetail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractOne);
