import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import video_mute from "../../Images/video_mute.png";

const videoType = 'video/webm';
const hasGetUserMedia = !!(navigator.getUserMedia || navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia || navigator.msGetUserMedia);

class SystemTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            videos: [],
            src: '',
            hasMicrophone: false,
            hasSpeakers: false,
            hasWebcam: false,
            isMicrophoneAlreadyCaptured: false,
            isWebcamAlreadyCaptured: false,
            isHTTPs: '',
            canEnumerate: false,
            numberRecordings: '',
            videoUrl: ''
        };

    }

    async componentDidMount() {

        navigator.permissions.query({ name: 'microphone' })
            .then((permissionObj) => {
                if (permissionObj.state == 'granted') {
                    this.setState({ hasMicrophone: true });
                }
            })
            .catch((error) => {
                console.log('Got error :', error);
            })

        navigator.permissions.query({ name: 'camera' })
            .then((permissionObj) => {
                if (permissionObj.state == 'granted') {
                    this.setState({ hasWebcam: true });
                }
            })
            .catch((error) => {
                console.log('Got error :', error);
            })

        this.video.src = this.video.srcObject = null;
        this.video.muted = false;
        this.video.volume = 1;

    }

    async getCameraStream() {

        await navigator.mediaDevices.getUserMedia({ video: { pan: true, tilt: true, zoom: true }, audio: true }).then(stream => {

            this.stream = stream;
            this.chunks = [];
            this.video.srcObject = stream;
            this.video.muted = true;
            this.video.volume = 0;

            this.video.play();

            // listen for data from media recorder

            this.mediaRecorder = new MediaRecorder(stream, {
                mimeType: videoType,
            });

            this.mediaRecorder.start(10);

            this.setState({ stream: this.mediaRecorder.stream });

            this.mediaRecorder.ondataavailable = e => {
                if (e.data && e.data.size > 0) {
                    this.chunks.push(e.data);
                }
            };

            return stream;

        }).catch(err => {
            alert("Please enable your webcamera and microphone in order to create eternaview.")
            this.setState({ hasWebcam: false });
            this.setState({ hasMicrophone: false });
            return false;
        });
     
    }


    startRecording(e) {
        e.preventDefault();

        if (this.state.hasMicrophone == false || this.state.hasWebcam == false) {
            toast('Please make sure that the microphone and webcam are allowed on your browser.', {
                position: "top-right",
                autoClose: 5000,
                type: 'warning',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });

            return;
        }

        this.getCameraStream();
    }

    stopRecording(e) {
        e.preventDefault();

        const blob = new Blob(this.chunks, { type: videoType });

        this.setState({ recordedVideo: blob });
        const videoURL = window.URL.createObjectURL(blob);

        let that = this;

        this.setState({ videoUrl: videoURL });

        this.video.src = this.video.srcObject = null;
        this.video.muted = false;
        this.video.volume = 1;
        this.mediaRecorder.stop();

        this.state.stream.getTracks().forEach(track => {
            track.stop();
        });

    }

    render() {
        const { recording, numberRecordings, videoUrl } = this.state;
        return (
            <section className="practing_outer recording99">
                <div className="container">
                    <div className="row">
                        <div className="col-ld-8 col-md-8 offset-lg-2 offset-md-2">
                            <div className="middle_outer56 cust67">
                                <p>Let's record a short test video to confirm your equipment is working ok!</p>
                                <h3>Hit "Start Recording." Then say anything!</h3>
                                <div className="btn7 custom_t6">
                                    {!recording && <button className="btn" onClick={e => this.startRecording(e)}>Start Recording</button>}
                                    {recording && <button className="btn" onClick={e => this.stopRecording(e)}>Stop Recording</button>}
                                </div>

                                <div className="img6">

                                    {numberRecordings == '' && <video
                                        style={{ width: 900 }}
                                        ref={v => {
                                            this.video = v;
                                        }}
                                    >
                                        Video stream not available.
                                    </video>}

                                    {numberRecordings != '' && <video id="video_player" style={{ width: 900 }} src={videoUrl} controls playsInline ></video>}

                                </div>


                                <div className="btn78">
                                    <Link to="/create/practice-test/">Move on to Practice</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const styles = {
    'wrapper': {
        display: 'flex',
        overflow: 'auto'
    },
    'child': {
        flex: 2
    },
    'videos': {
        flex: 1
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemTest);