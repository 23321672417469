import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import small_check from "../../Images/small_check.png";
import video_part from "../../Images/video_part.png";
import left_img1 from "../../Images/left_img1.png";
import legend1 from "../../Images/legend1.png";
import legend2 from "../../Images/legend2.png";
import legend3 from "../../Images/legend3.png";
import legend4 from "../../Images/legend4.png";
import video1 from "../../Images/video1.png";
import video2 from "../../Images/video2.png";
import {
  changeLastModal,
  superUserLogin,
  submitEmailNewsletter,
} from "../../redux/actions";
import { Base64 } from "js-base64";
import { toast } from "react-toastify";
import videojs from "video.js";
import "video.js/dist/video-js.css";

import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from "react-player";

const videoJsOptions1 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/27f133ef-d5bf-4996-9be9-bd33487a0fc4/hls/01_30_23GrandchildGrandparentvideowithblackremovedfromfront.m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions2 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/7fdc171a-2c88-4d3b-99a2-1f8dbbabd531/hls/Love Version 09-15-22 With Death Removed.m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions3 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/45cadbe0-a086-4482-9338-07e23292d82d/hls/Dating Commercial February 10th, 2022.m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions4 = {
  controls: false,
  bigPlayButton: false,
  muted: true,
  autoplay: true,
  responsive: true,
  fluid: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  sources: [
    {
      // src: 'https://d2jgjdx2rckm61.cloudfront.net/58ff84e6-ddb9-4ac8-91fe-d3f75f018ce8/hls/answer_S2VqmYN.m3u8?height=1080&width=1920',
      src: "https://d2jgjdx2rckm61.cloudfront.net/fab193a5-7f35-4e12-9d4c-d00045099cb4/hls/answer_osWYxJM.m3u8?height=1080&width=1920",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions5 = {
  controls: false,
  bigPlayButton: false,
  muted: true,
  autoplay: true,
  responsive: true,
  fluid: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/3b762a32-e55b-4e37-bfb9-ea432ab1ffb9/hls/answer_N9QLp3B.m3u8?height=1080&width=1920",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions6 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

class DatingLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTimePop: false,
      base64regex:
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
      email: "",
    };
    this.onInputchange = this.onInputchange.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("pass") === "match") {
    } else {
      this.props.history.push("/home/securelogin");
    }
    if (this.props.auth.moduleCheck.is_complete == false) {
      this.setState({ firstTimePop: true });
    }

    if (this.state.base64regex.test(this.props.match.params.id)) {
      this.props.match.params.id = Base64.decode(this.props.match.params.id);
      let splitToken = this.props.match.params.id.split("&");
      let newToken = splitToken[0].split("=");
      localStorage.setItem("super", newToken[1]);
      this.props.superUserLogin(newToken[1]);
    }

    this.player1 = videojs(
      this.video1Node,
      videoJsOptions1,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );

    this.player1.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player2 = videojs(
      this.video2Node,
      videoJsOptions2,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );

    this.player2.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player3 = videojs(
      this.video3Node,
      videoJsOptions3,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );

    this.player3.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player4 = videojs(
      this.video4Node,
      videoJsOptions4,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player4.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player5 = videojs(
      this.video5Node,
      videoJsOptions5,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player5.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player6 = videojs(
      this.video6Node,
      videoJsOptions6,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player6.on("deviceReady", () => {
      console.log("device is ready!");
    });
  }

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ firstTimePop: false });
  };

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitNewletterEmail = (e) => {
    e.preventDefault();

    this.props.submitEmailNewsletter(
      { email: this.state.email },
      this.props.auth.loginUserToken ? this.props.auth.loginUserToken : "",
      (result) => {
        if (result.id) {
          toast("Your email has been submitted successfully.", {
            position: "top-right",
            autoClose: 3000,
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          this.setState({ email: "" });
        } else {
          toast(result.email[0], {
            position: "top-right",
            autoClose: 3000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return false;
        }
      }
    );
  };

  componentWillUnmount() {
    this.props.changeLastModal({});
  }

  render() {
    return (
      <div>
        {/*<section className="talk_outer custom_home">
			
                    <div className="container">
                        <div className="custom_heading6">
                            <h4>Talk to Your Loved Ones. <span>Forever!</span></h4>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="img_ouyter">
                                    <img src={left_img1} />
                                    <div className="audeio">
                                        <img src={mic} />
                                        <p>Start talking to this Grandma right now</p>
								        <img className="iny467" src={intergate} ></img> </div>
                                </div>
                            </div>

                            <div className={this.state.firstTimePop == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} id="myModal">
                                <div className="modal-dialog custom_dioalag">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Hey, you started eternaview but not completed yet. Do you want to proceed?</h4>
                                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                                        </div>

                                        <div className="modal-body">
                                            <div className="btn_inner">
                                                <ul>
                                                    <li><button className="btn custom_yes"><Link to={`/create/eternaview/${this.props.auth.moduleCheck.category}+${this.props.auth.moduleCheck.sub_category}+0`}>Yes, lets continue! </Link></button></li>
                                                    <li><button className="btn custom_no" onClick={this.closePopUp}>No, not right now!</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="right_text54">
                                    <h5><span>Eternaview</span> is an innovative technology that allows you to record a video interview of yourself on any device then invite viewers to <span className="talk_name">talk</span> to that recording. </h5>
                                    <div className="after5">
                                        <p>Yes, we said talk! A viewer can ask a question like they would in a normal conversation. Our platform then, through AI, retrieves the best answer from the recorded interview.</p>
                                    </div>
                                    <h4>It is the perfect way for any creator to let family, friends, the love of their life, or potential love of their life feel a personal connection... even when not actually speaking in person.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="heading_text">
                                <h4>To understand how you and your loved ones can <span>have conversations for eternity,</span> just:</h4>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="cilk_outer">
                                    <div className="inner_div6">
                                        <img src={small_check} />
                                        <p>Click your mouse anywhere on the image to begin recording.</p>
                                    </div>
                                    <div className="inner_div6">
                                        <img src={small_check} />
                                        <p>Speak your question into your device's microphone.</p>
                                    </div>
                                    <div className="inner_div6">
                                        <img src={small_check} />
                                        <p>Click your mouse again to stop recording.</p>
                                    </div>
                                    <div className="inner_div6">
                                        <img src={small_check} />
                                        <p>Wait for this real grandma’s answer!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="note_outer">
                                    <p><b>Note:</b> You can also just click on the questions (?) in the drop down menu if you prefer to interact that way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}

        <section className="eterna_outer home-page-banner-heading">
          <div className="container">
            <div className="right_text54 left_ou6">
              <h5>
                <span>Eternaview</span> is an innovative technology that lets
                you record a video interview of yourself on any device then
                invite viewers to{" "}
                <span className="talk_name">
                  <i>talk</i>
                </span>{" "}
                to that recording.{" "}
              </h5>
            </div>
          </div>
        </section>
        <section className="enterlenge_outer">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="main_outer1">
                  <div className="entra_outer">
                    <h3>
                      Eternaview <i>Legacy</i>
                    </h3>
                  </div>
                  <div className="border_white0">
                    <div className="img_ouyter clickTalk">
                      <div className="clickTalkinner">
                        {/* <img src="/static/media/left_img1.dd22c4b1.png" /> */}
                        <Link to={`/home/interact-one/935/455`}>
                          <video
                            id="myVideo4"
                            ref={(node) => (this.video4Node = node)}
                            className="video-js vjs-default-skin custom_video_js"
                            options={videoJsOptions4}
                            playsInline
                            loop
                          ></video>
                        </Link>
                        <Link
                          to={`/home/interact-one/935/455`}
                          className="clickTalka"
                        >
                          <i className="far fa-microphone"></i>
                          <p>Click to talk to this Eternaview now!</p>
                        </Link>
                      </div>
                    </div>
                    <h4>
                      Talk to Loved Ones...
                      <span>
                        <i>Forever!</i>
                      </span>
                    </h4>
                    <div className="cilk_outer custom_circel">
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Structured interview with convenient long-term
                          storage.
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Send invites to family and friends or share on social
                          media.
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Set to Private or Public (it's Private by default).
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Can be done as a Video or Audio-only.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main_outer1">
                  <div className="entra_outer">
                    <h3>
                      Eternaview <i>Dating</i>
                    </h3>
                  </div>
                  <div className="border_white0">
                    <div className="img_ouyter clickTalk">
                      <div className="clickTalkinner">
                        {/* <img src="/static/media/left_img1.dd22c4b1.png" /> */}
                        <Link to={`/home/interact-one/945/456`}>
                          <video
                            id="myVideo4"
                            ref={(node) => (this.video5Node = node)}
                            className="video-js vjs-default-skin custom_video_js"
                            options={videoJsOptions5}
                            playsInline
                            loop
                          ></video>
                        </Link>
                        <Link
                          to={`/home/interact-one/945/456`}
                          className="clickTalka"
                        >
                          <i className="far fa-microphone"></i>
                          <p>Click to talk to this Eternaview now!</p>
                        </Link>
                      </div>
                    </div>
                    <h4>
                      Find Love...
                      <span>
                        <i>in a low-pressure way!</i>
                      </span>
                    </h4>
                    <div className="cilk_outer custom_circel">
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Gain attention of a potential love interest.</p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Share on dating apps or social media.</p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Set to Private or Public (it's Private by default).
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Can be done as a Video or Audio -only.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sky_outer">
              <h4>
                Yes, we said <b>talk!</b> A viewer can ask a question like they
                would in a normal conversation. Our platform then, through AI,
                retrieves the best answer from
                <br /> the recorded interview.
              </h4>
              <p>
                It is the perfect way for any creator to let family, friends,
                the love of their life, or potential love of their life feel a
                <br /> personal connection... even when not actually speaking in
                person.
              </p>
            </div>
          </div>
        </section>
        <section className="inter_outer" id="eterna_work">
          <div className="container">
            <div className="custom_heading6">
              <h4>
                How does <span>Eternaview Work?</span>
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="img_lefr5">
                  {/* <img src={video_part} /> */}
                  <ReactHlsPlayer
                    style={{ height: "100%" }}
                    className="video-js vjs-default-skin custom_video_js"
                    src="https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8"
                    autoPlay={false}
                    controls={true}
                    loop={true}
                    width="100%"
                  />

                  <video
                    style={{ display: "none" }}
                    id="myVideo6"
                    ref={(node) => (this.video6Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions6}
                    playsInline
                  ></video>
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="tect56">
                  <div className="middlr5">
                    <b>1.</b>
                    <p>Register and log in.</p>
                  </div>
                  <div className="middlr5">
                    <b>2.</b>
                    <p>Choose a plan.</p>
                  </div>
                  <div className="middlr5">
                    <b>3.</b>
                    <p>
                      Record yourself on your webcam or mobile phone answering
                      our insightful list of interview questions.
                    </p>
                  </div>
                  <div className="middlr5">
                    <b>4.</b>
                    <p>Share your Eternaview for people to interact with it.</p>
                  </div>
                  <div className="created_inter45">
                    <Link to="/home/create/">Create my Eternaview Now</Link>
                  </div>
                  <div className="pargrapj">
                    <p>
                      That's all it takes for the people who know you (or want
                      to get to know you) to visit your Eternaview recording and
                      begin asking you questions... to hear about you in your
                      own words!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video_points">
          <div className="container">
            <div className="custom_heading6">
              <h4>
                <span>Advantages of Eternaview</span> versus other video options{" "}
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <a href="" data-toggle="modal" data-target="#Legacy">
                  <div className="box-heading">
                    <img src={legend1}></img>
                    <h4>Legacy</h4>
                    <p>
                      Be remembered by <br />
                      family and friends!
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="" data-toggle="modal" data-target="#Dating">
                  <div className="box-heading">
                    <img src={legend2}></img>
                    <h4>Dating</h4>
                    <p>
                      A great way to meet that special someone you don't know
                      yet!
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="" data-toggle="modal" data-target="#Audio-only">
                  <div className="box-heading">
                    <img src={legend3}></img>
                    <h4>Audio-only</h4>
                    <p>A great alternative for the camera-shy!</p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="box-heading">
                    <img src={legend4}></img>
                    <h4>Eternaview Unbound</h4>
                    <p>
                      Whatever your need, let us help you figure out novel ways
                      to use Eternaview.{" "}
                    </p>
                  </div>
                </a>
              </div>
            </div>
            {/* <div className="row demo_video_row">
                            <div className="col-md-4">
                                <div className="vide_tex5 demovideostitle">
                                    <h4>Show your loved ones
                                        what you can do!
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="vide_tex5 demovideostitle">
                                    <h4>We have all led lives
                                        worth remembering!
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="vide_tex5 demovideostitle">
                                    <h4>Looking for a low-pressure way to
meet someone?
                                    </h4>
                                </div>
                            </div>
                        </div> */}
            <div className="row demo_video_row home-hls-love-videos">
              <div className="col-lg-4 col-md-12">
                <div className="vide_tex5 demovideos">
                  <h4>Show your loved ones what you can do!</h4>
                  <video
                    id="myVideo1"
                    ref={(node) => (this.video1Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions1}
                    playsInline
                  ></video>
                  {/* <img src={video1}></img> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="vide_tex5 demovideos">
                  <h4>We have all led lives worth remembering!</h4>
                  <video
                    id="myVideo2"
                    ref={(node) => (this.video2Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions2}
                    playsInline
                  ></video>
                  {/* <img src={video2}></img> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="vide_tex5 demovideos">
                  <h4>Looking for a low-pressure way to meet someone?</h4>
                  <video
                    id="myVideo3"
                    ref={(node) => (this.video3Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions3}
                    playsInline
                  ></video>
                  {/* <img src={video2}></img> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="modal fade custom_modal advantages_popup"
          id="Eternaview"
        >
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Eternaview Unbound</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  Be remembered by <br />
                  family and friends!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade custom_modal advantages_popup"
          id="Audio-only"
        >
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Audio-only</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  <span className="dot"></span>Many of the same benefits of
                  creating a video version of a Legacy or Dating Eternaview.{" "}
                </p>
                <p>
                  <span className="dot"></span>Great if you are shy about how
                  you look on video or cannot easily record a video of yourself.{" "}
                </p>
                <p>
                  <span className="dot"></span>A way for potential dating
                  matches to get to know you before judging based on looks.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade custom_modal advantages_popup" id="Dating">
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Dating</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  <span className="dot"></span>A way for you to highlight
                  yourself to more potential viewers/matches.{" "}
                </p>
                <p>
                  <span className="dot"></span>A way for a viewer to get a
                  better sense of what a potential date with the creator would
                  be like.{" "}
                </p>
                <p>
                  <span className="dot"></span>It is a lower pressure way for a
                  viewer to screen potential matches before going out on a date
                  or even engaging in a two-way video chat.{" "}
                </p>
                <p>
                  <span className="dot"></span>Avoids the need to synchronize
                  schedules.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade custom_modal advantages_popup" id="Legacy">
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Legacy</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  <span className="dot"></span>A structured interview process
                  for capturing your memories, stories, personality, and
                  talents.{" "}
                </p>
                <p>
                  <span className="dot"></span> It’s a much more immersive and
                  personalized interaction for the viewer.{" "}
                </p>
                <p>
                  <span className="dot"></span>Convenient and very long-term
                  storage of a large video file w/ the ability to control who
                  can access it.{" "}
                </p>
                <p>
                  {" "}
                  <span className="dot"></span>It’s fun to create and then
                  interact with it, along with your family and friends.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="etreview_outer">
          <div className="container">
            <div className="cre_outer">
              <h4>
                <span>Love.</span> It is why we created Eternaview.
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <div className="row bg_out6">
                  <div className="col-lg-6 col-md-12">
                    <div className="headiu7">
                      <h4>
                        To Keep Informed of the Exciting Things Happening at
                        Eternaview, <i>Sign Up Here!</i>
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_outer">
                      <input
                        type="email"
                        placeholder="alianto@gmail.com"
                        name="email"
                        required
                        value={this.state.email}
                        onChange={this.onInputchange}
                        maxLength="100"
                      ></input>
                      <a href="" onClick={this.submitNewletterEmail}>
                        Email here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  changeLastModal: bindActionCreators(changeLastModal, dispatch),
  superUserLogin: bindActionCreators(superUserLogin, dispatch),
  submitEmailNewsletter: bindActionCreators(submitEmailNewsletter, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatingLanding);
