import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import video_part from "../../Images/video_part.png";
import { getAboutUs } from '../../redux/actions';

class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
        };
    }

    componentDidMount() {
        this.getData();
    }


    getData() {
        this.props.getAboutUs('', (result) => {
            this.setState({ data: result.content });
        });
    }

    render() {

        return (
            <section class="terms-outer" dangerouslySetInnerHTML={ { __html: this.state.data}}></section>

                // <div class="container">
                //     <div class="row">
                //         <div class="col-md-12">
                //             <div class="terms_inner">
                //                 <div class="terms_title">
                //                     About Us
                //                 </div>
                //                 <div class="terms_content">
                //                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate id tortor vitae imperdiet. Nulla a erat odio. Curabitur eget tellus semper, mollis est in, accumsan erat. Nulla consequat et sapien vitae elementum. Maecenas rutrum ac quam finibus bibendum. Vivamus erat tortor, ultrices in aliquet non, placerat quis libero. Phasellus consequat, quam et convallis tincidunt, turpis elit dictum augue, sed bibendum ante diam non elit. Cras nec est arcu. Integer sit amet justo tristique, pharetra ex at, vulputate purus.</p>

                //                     <p><b>Quisque enim felis</b></p>
                //                     <p>porta in sodales et, blandit non leo. Vestibulum erat orci, dictum quis cursus ac, consectetur sit amet purus. Sed mauris odio, egestas sit amet orci et, ullamcorper.</p>
                //                 </div>
                //                 <section class="inter_outer video_inter_outer"><div class="container"><div class="custom_heading6"><h4>How does <span>Eternaview Work?</span></h4></div><div class="row"><div class="col-lg-7 col-md-12"><div class="img_lefr5"><img src={video_part} /></div></div><div class="col-lg-5 col-md-12"><div class="tect56"><div class="middlr5"><b>1.</b><p>Just set up an account.</p></div><div class="middlr5"><b>2.</b><p>Choose a plan.</p></div><div class="middlr5"><b>3.</b><p>Record yourself on your webcam or mobile phone answering our insightful list of interview questions.</p></div><div class="middlr5"><b>4.</b><p>Send out invites to anyone who might want to interact with your video.</p></div><div class="created_inter45"><a href="/home/create/">Create my Eternaview Now</a></div><div class="pargrapj"><p>That's all it takes for the people who know you (or want to get to know you) to visit your Eternaview recording and begin asking you questions... to hear about you in your own words!</p></div></div></div></div></div></section>
                //                 <div class="terms_content">
                //                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vulputate id tortor vitae imperdiet. Nulla a erat odio. Curabitur eget tellus semper, mollis est in, accumsan erat. Nulla consequat et sapien vitae elementum. Maecenas rutrum ac quam finibus bibendum. Vivamus erat tortor, ultrices in aliquet non, placerat quis libero. Phasellus consequat, quam et convallis tincidunt, turpis elit dictum augue, sed bibendum ante diam non elit. Cras nec est arcu. Integer sit amet justo tristique, pharetra ex at, vulputate purus.</p>

                //                     <p><b>Quisque enim felis</b></p>
                //                     <p>porta in sodales et, blandit non leo. Vestibulum erat orci, dictum quis cursus ac, consectetur sit amet purus. Sed mauris odio, egestas sit amet orci et, ullamcorper.</p>
                //                 </div>
                //             </div>
                //         </div>
                //     </div>
                // </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    getAboutUs: bindActionCreators(getAboutUs, dispatch)
});

export default connect('', mapDispatchToProps)(AboutUs);
