import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

 function Landing(props) {
    const loginUserToken = useSelector(state => state.auth.loginUserToken);


    useEffect(() => {
        if (loginUserToken) {
          props.history.push('/dashboard');
        } else {
          props.history.push('/oauth/sign-in/');
        }
    }, [loginUserToken])
    return (
        <></>
    )
}
export default Landing

