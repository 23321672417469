import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgotPassword, resetPassword } from '../../redux/actions';
import edit from "../../Images/edit.png";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


function ForgotPassword(props) {
  const auth = useSelector(state => state);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const dispatch = useDispatch();

  let [email, setEmail] = useState('')
  let [userid, setUserId] = useState();
  const [loaded, setLoaded] = useState(false);
  const [baseName, setBaseName] = useState('oauth');

  useEffect(() => {
    if (auth.auth.loginUserToken != "") {
      props.history.push('/');
    }
    setLoaded(false);
  }, []);

  useEffect(() => {

    if(splitLocation[1]=='dating')
    {
     
      setBaseName('dating');
    }
    else
    {
        setBaseName('oauth');
    }
  }, []);


  function handleEmailInput(event) {
    setEmail(event.target.value)
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();

      setLoaded(true);
      console.log(loaded);

      let payload = {
        "email": email,
        "baseName":baseName,
      }

      dispatch(forgotPassword(payload, (result) => {
        console.log(loaded);
        setLoaded(false);
        if (result.reset_url) {
          toast(result.msg, {
            position: "top-right",
            autoClose: 3000,
            type: 'success',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return;

        } else {
          toast(result.msg, {
            position: "top-right",
            autoClose: 3000,
            type: 'error',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });

        }
      }));

  };

  return (

    <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-3">
              <div className="login_inner4 reset_outer4">
                <div className="text-center">
                  <h1>Forgot password</h1>
                  <p className="lead">Enter your email to reset your password.</p>
                </div>
                <Form onSubmit={(event) => handleFormSubmit(event)} autoComplete="off">
                  <FormGroup>
                    <Label>Email</Label>
                    <div className="input_outer99 forgot">
                      <Input
                        bsSize="lg"
                        type="email"
                        name="email"
                        placeholder="Enter your email" value={email} onChange={handleEmailInput} required
                      />
                    </div>
                  </FormGroup>
                  <div className="back_login"><p>Back to login? <span><Link to={`/${baseName}/sign-in`}>Log in</Link></span></p></div>
                  <div className="log_outer forgot_btn">
                    {loaded == false && <Button type="submit" color="primary">Reset password now</Button>}
                    {loaded == true && <Button type="button" color="primary">Loading...</Button>}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

    </React.Fragment>);
};

export default ForgotPassword;
