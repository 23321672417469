import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import arrow_right from "../../Images/arrow_right.png";
import righ65 from "../../Images/righ65.png";
import blog1 from "../../Images/blog1.png";
import blog2 from "../../Images/blog2.png";
import blog3 from "../../Images/blog3.png";
import blog4 from "../../Images/blog4.png";
import { blogPosts } from "../../redux/actions/index";


function BlogPosts(props) {
    const dispatch = useDispatch();
    const [blogs, setBlogs] = useState([]);

    const auth = useSelector(state => state);

    useEffect(() => {
        getBlogsListing();
    }, []);

    function getBlogsListing() {
        dispatch(blogPosts('', (result) => {
            setBlogs(result.data);
        }));
    }

    return (
        <section className="blog_outer">
            <div className="container">
                <div className="plan_outer2 textdeco56">
                    <h4>Blog Posts</h4>
                    <p>We provide here a range of ideas including the types of Eternaviews that can be created and how to optimize your Eternaview. For additional information about the types of Eternaviews you can create and how, please check out all the videos on our home page and the FAQ section of our website.</p>
                </div>
                <div className="row spec45 no_smar5">
                    <div className="col-lg-12 col-md-12">
                        <div className="cret3 mart6">
                            <button className="btn">
                                <Link to='/home/create/'>Create my Eternaview!</Link>

                            </button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {blogs && blogs.map(bb => (<div className="col-lg-6 col-md-12">
                        <div className="blog_box1">
                        <Link to={`/home/blogs/${bb.id}`}>
                            {bb.image != '' && <img src={bb.image} />}
                            {/* {bb.image == '' && <img src={blog1} />} */}
                        </Link>                            
                        <div className="texcd4">
                                <p>{bb.title}</p>
                                <Link to={`/home/blogs/${bb.id}`}><span><img src={arrow_right} /></span></Link>
                            </div>
                        </div>
                    </div>))}
                    {/* <div className="col-lg-6 col-md-12">
                        <div className="blog_box1">
                            <img src={blog2} />z
                            <div className="texcd4">
                                <p>How to Optimize Your Recording</p>
                                <span><img src={arrow_right} /></span>
                            </div>
                        </div>
                    </div> */}
                </div>

                {/* <div className="row no_spe">
                    <div className="col-lg-6 col-md-12">
                        <div className="blog_box1">
                            <img src={blog3} />
                            <div className="texcd4">
                                <p>Creating A Personal Connection With Eternaview</p>
                                <span><img src={arrow_right} /></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="blog_box1">
                            <img src={blog4} />
                            <div className="texcd4">
                                <p>Creating A Personal Connection With Eternaview</p>
                                <span><img src={arrow_right} /></span>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="list_outer">
                    <ul>
                        <li className="same_col8">1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>...</li>
                        <li className="next56"><a href="#">Next page <img src={righ65} /></a></li>
                    </ul>
                </div> */}
            </div>
        </section>
    )
};

export default BlogPosts;
