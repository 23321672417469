

//import FacebookLogin from "react-facebook-login";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import GoogleLogin from "react-google-login";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import edit from "../../Images/edit.png";
import { Form } from "reactstrap";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import {
  register,
  getCredentials,
  registerSocialLogin,
  registerSocialLoginTEMP,
  login,
  checkEternaviewCreated,
} from "../../redux/actions/index";
import connection from "../../config/connection";
import Loader from "react-loader";
import facebookImage from '../../Images/facebookLogin.png'

const CustomFBLogin = (props) => {
  console.log(props, 'currentWERTY')
  const dispatch = useDispatch();


  const CLIENT_ID = '643095534158146'; // Replace with your Facebook App ID
  const REDIRECT_URI = 'https://app.eternaview.com/oauth/auth-callbackc-fb'; // Adjust based on your redirect URI
  //const REDIRECT_URI = 'https://9641-106-221-139-51.ngrok-free.app/oauth/auth-callbackc-fb';
  function createFacebookLoginUrl() {
    const scope = encodeURIComponent('email,public_profile'); // Define the permissions your app requires
    return `https://www.facebook.com/dialog/oauth?client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectURL)}&scope=${scope}&response_type=token`;
  }

  let [email, setEmail] = useState("");
  let [emailError, setEmailError] = useState("");
  let [password, setPassword] = useState("");
  let [passwordError, setPasswordError] = useState("");
  let [inputtype, setInputType] = useState(false);
  let [loaded, setLoaded] = useState(true);
  const [baseName, setBaseName] = useState('oauth');
  const [baseAuthName, setBaseAuthName] = useState('oauth');
  const [basePath, setBasePath] = useState(connection.homePage);
  const [redirectURL, setredirURL] = useState(REDIRECT_URI);


  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const responseFacebook = (response) => {
    if (response.accessToken) {

      setLoaded(false);
      const fbresponse = {
        Name: response.name,
        email: response.email,
        token: response.id,
        ProviderId: response.graphDomain,
        accessToken: response.accessToken,
      };

      fbLogin(fbresponse)
    }

    setLoaded(false);
  }


  useEffect(() => {


    setTimeout(
      function () {

        // window.fbAsyncInit = function() {

      /*   alert('init works');
        checkPopupBlocker();

        window.FB.init({
          appId: '643095534158146', // Replace 'YOUR_APP_ID' with your actual app ID
          status: false, // check login status
          cookie: false,
          xfbml: false,
          version: 'v2.7' // Ensure this is the current version
        });
        // Additional initialization code such as event handlers or checking user status
        // };
        alert('init works out');

        // Load the SDK asynchronously
        (function (d, s, id) {
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s); js.id = id;
          js.src = "https://connect.facebook.net/en_US/all.js";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk')); */



      }
        .bind(this),
      3000
    );
  }, []); // This empty array ensures the effect runs only once

  useEffect(() => {

    if(splitLocation[1]=='dating')
    {
     
      setBaseName('dating');
      setBaseAuthName('dating');
      setBasePath(connection.datingHomePage);
      const fb_redirect_url = 'https://app.eternaview.com/dating/auth-callbackc-fb'; // Adjust based on your redirect URI
      setredirURL(fb_redirect_url);
    }
    else
    {
        setBaseAuthName('oauth');
        setBaseName('home');
        setBasePath(connection.homePage);
        const fb_redirect_url = 'https://app.eternaview.com/oauth/auth-callbackc-fb'; // Adjust based on your redirect URI
        setredirURL(fb_redirect_url);
    }
}, []);

  function checkPopupBlocker() {


    var popup = window.open("https://example.com", "_blank");
    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      alert("Pop-up was blocked. Please disable your pop-up blocker and try again.");
    }


    var testPopup = window.open("", "_blank", "width=1,height=1");
    if (testPopup == null || typeof testPopup === 'undefined') {
      // Popup blocker is enabled
      alert("Popup Blocker is enabled");
      return true;
    } else {
      // Popup blocker is not enabled
      testPopup.close();
      alert("Popup Blocker is not enabled");
      return false;
    }
  }


  function loginWithFacebook() {
    /* var clientId = '643095534158146';
    var redirectUri = encodeURIComponent('YOUR_REDIRECT_URI');
    var scope = 'email,public_profile'; // Adjust the scope based on required permissions
    var authUrl = `https://www.facebook.com/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=token`;

    window.location.href = authUrl; */
    window.location.href = createFacebookLoginUrl();

  }

  // Call the function to check for popup blockers
  // Function to handle login
  const handleLogin = () => {
    alert('hit');
    window.FB.login(function (responseData) {
      alert(JSON.stringify(responseData));
      console.log(responseData, 'responseData');
      if (responseData.authResponse) {
        alert(JSON.stringify(responseData.authResponse));

        console.log('Welcome!  Fetching your information.... ');
        window.FB.api('/me', function (response) {
          console.log(response, 'response');

          console.log('Good to see you, ' + response.name + '.');
          alert('Good to see you, ' + response.name + '.');
          alert('response.id = ' + response.id);

          alert('response.accessToken = ' + responseData.accessToken);

          alert('response.authResponse.accessToken = ' + responseData.authResponse.accessToken);
          if (responseData.authResponse.accessToken) {

            setLoaded(false);
            const fbresponse = {
              Name: response.name,
              email: 'sourabh_30_04_2024@yopmail.com', //response.email ,
              token: response.id,
              ProviderId: responseData.authResponse.graphDomain,
              accessToken: responseData.authResponse.accessToken,
            };
            alert('fbresponse  ' + JSON.stringify(fbresponse));

            fbLogin(fbresponse)
          }

        });
      } else {
        alert('User cancelled login or did not fully authorize.');
      }
    });
    alert('hit2');
  }


  const fbLogin = (payload) => {
    alert('dispatch');
    dispatch(getCredentials((res) => {
      console.log(res, 'abhishek')
      if (res.status == true) {
        let payload1 = {
          "username": payload.email,
          "client_id": res.res.client_id,
          "client_secret": res.res.client_secret,
        }
        alert('registerSocialLoginTEMP');
        dispatch(registerSocialLoginTEMP(payload1, (result) => {
          console.log(result, 'AbhishResult')
          setLoaded(true);
          if (result.error) {
            if (result.error == "Your account is inactive.Please check your email to activate your account.") {
              toast("Your account is inactive.Please check your email to activate your account. or contact us.", {
                position: "top-right",
                autoClose: 10000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
              return
            }
            toast(result.error_description, {
              position: "top-right",
              autoClose: 10000,
              type: 'error',
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            if (!result.error_description) {
              toast(result.error, {
                position: "top-right",
                autoClose: 10000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
              });
            }

          } else if (result.access_token) {
            toast('You are successfully logged into application.', {
              position: "top-right",
              autoClose: 10000,
              type: 'success',
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            });
            sessionStorage.setItem("token", result.access_token)
            //   props.props.history.push('/');
            window.location.href = `${basePath}?access_token=${result.access_token}`
          }
        }));
      }
    }));
  }

  const componentClicked = () => {
  }

  return (
    <>
      <a onClick={loginWithFacebook}><img src={facebookImage} />Sign in with Facebook</a>

    </>
  )
}

export default CustomFBLogin