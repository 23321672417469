import React, {useState, useEffect } from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Circles } from "react-loader-spinner";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MoveTOPracticePage = () =>{
    const history = useHistory()
    const auth = useSelector(state=> state);
    const [baseName, setBaseName] = useState('oauth');

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");


    const checkIsUserLoggedIn = () =>{
        const token = sessionStorage.getItem("token");
        if(auth.auth.loginUserToken || token){
            if(splitLocation[1]=='dating')
                {
                    history.push('/dating/create/practice-test/')
                }
                else
                {
                    history.push('/home/create/practice-test/')
                }
        }else{
            if(splitLocation[1]=='dating')
                {
                    history.push('/dating/sign-in/?navto=practice')
                }
                else
                {
                    history.push('/oauth/sign-in/?navto=practice')

                }
        }
    }
    useEffect(()=>{
        checkIsUserLoggedIn()
    },[])
    return(
        <section className="cretae_home5 new_create_home5">
        <Circles wrapperClass="circle-spinner"/>
     </section>
    )
 }

export default MoveTOPracticePage