import React, { useEffect } from 'react';
import { environment } from '../config/connection';
import TagManager from 'react-gtm-module';
const GoogleTagManager = () => {
    useEffect(() => {
        if (environment === 'production') {
            TagManager.initialize({ gtmId: "GTM-P4HDBLVX"});
        }
      }, [environment]);
  return null;
};

export default GoogleTagManager;
