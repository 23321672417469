import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from "react-redux-toastr";
import { Modal, ModalBody,ModalHeader } from 'reactstrap';
import practices_first from "../../Images/practices_first.png";
import play from "../../Images/play.png";
import crete_page from "../../Images/create_page_couple.jpg";
import hand_outer from "../../Images/hand_outer.png";
import { toast } from 'react-toastify';
import arrow_right from "../../Images/arrow_right.png";
import small_check from "../../Images/small_check.png";
import ctre45 from "../../Images/ctre45.png";
import blog1 from "../../Images/blog1.png";
import { getCategories, getCategoriesOpen, getSubCategories, getCreateQuestion, getSubCategoriesByCat, getSubCategoriesByCatOpen, scanQuestions, scanQuestionsOpen, verifyUserSubscription } from "../../redux/actions";
import { blogPosts } from "../../redux/actions/index";
import RegisterComponent from "../../components/Register";
import Multiselect from 'multiselect-react-dropdown';
import { Redirect } from 'react-router-dom';
import videojs from "video.js";
import $ from 'jquery';
import ReactHlsPlayer from 'react-hls-player';
import perview from "../../Images/perview.png";
import Thankyou from "../auth/VerifyEmail";
import Login from '../../components/Login';
import Register from '../../components/Register';
import FacebookPixel from "../../MetaComponents/Facebookpixel";
import connection from "../../config/connection";
import { Circles } from "react-loader-spinner";
const videoJsOptions6 = {
   controls: true,
   bigPlayButton: true,
   autoplay: false,
   responsive: true,
   aspectRatio: '16:9',
   width: "auto",
   height: "auto",
   fluid: true,
   sources: [{
      src: 'https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8',
      type: 'application/x-mpegURL'
   }]
};

const videoJsOptions7 = {
   controls: true,
   bigPlayButton: true,
   autoplay: false,
   responsive: true,
   aspectRatio: '16:9',
   width: "auto",
   height: "auto",
   fluid: true,
   sources: [{
      src: 'https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8',
      type: 'application/x-mpegURL'
   }]
};

class CreateIntro extends Component {
   constructor(props) {
      super(props);

      this.state = {
         currentActiveplan:{},
         isThankyouOpen:false,
         isSignUpOpen:false,
         categories: [],
         subcategories: [],
         category: '0',
         subcategory: '0',
         typecreate: '0',
         data: {},
         blogs: [],
         nextbtn: false,
         selectedOption: null,
         moduleQuestions: [],
         catName: '',
         planVerify: '',
         verifyPlanDetail: {},
         loginModelOpenStatus: false,
         loginRedirectUrl: '',
      };
   }
   setScroll(e) {
      $('#accorscroll').animate({
         scrollTop: $(e.target).offset().top - 500
      }, 1000);
   }

   verifyUserSubscription = (cb) =>{
      this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
         if (res.is_active == false) {
            localStorage.clear();
            window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
            // this.props.history.replace("/");
         } else {
            if (res.data) {
               if (res.data.length > 0) {
                  res.data.map((mod, ind) => {
                        if(mod.updated_details.progress_status != "complete"){
                           this.setState({currentActiveplan:mod})
                           this.loadSelectChange({id:mod.subscribe_plan__category__id})
                         }
                  });
               }
               this.setState({ verifyPlanDetail: res.data });
               if(cb){
                  cb(res.data)
               }
            }
         }

      });
   }

   componentDidMount() {

      
      this.onCreateEternView()
      this.player6 = videojs(this.video6Node, videoJsOptions6, function onPlayerReady() {
         // print version information at startup
         console.log('onPlayerReady', this)
      });

      // device is ready
      this.player6.on('deviceReady', () => {
         console.log('device is ready!');
      });

      this.player7 = videojs(this.video7Node, videoJsOptions7, function onPlayerReady() {
         // print version information at startup
         console.log('onPlayerReady', this)
      });

      // device is ready
      this.player7.on('deviceReady', () => {
         console.log('device is ready!');
      });

      localStorage.removeItem('typecreate');

      this.props.getCategoriesOpen((res) => {
         if (res.data && res.data.length > 0) {
            this.setState({ categories: res.data });
            // this.setState({ subcategories: res.data[0].sub_categories });
            const lagecy_data = res.data.filter((item, i) => item.name == "Legacy");

            console.log(lagecy_data, 'lagecy_data');
            // this.loadSelectChange(lagecy_data[0]);
         }
      });

      if (this.props.auth.loginUserToken != "") {
         this.props.getCategories({ token: this.props.auth.loginUserToken }, (res) => {
            if (res.data && res.data.length > 0) {
               this.setState({ categories: res.data });
               // this.setState({ subcategories: res.data[0].sub_categories });


            }
         });

         this.verifyUserSubscription()

         
      }
      console.log(this.state.categories, 'this.state.categories');

   }

   checkSection = (e) => {
      if(e)
      e.preventDefault();

      if (this.props.auth.loginUserToken == "") {
         //   history.goBack();
         toast('Please log in first in order to create an Eternaview', {
            position: "top-right",
            autoClose: 3000,
            type: 'error',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
         });
         return;
      } else {
         this.props.history.push('/create/practice-test/');
      }
   }

   nextCreate = (param) => {
      let catName = this.state.catName;
      let active_plan=Object.keys(this.state.currentActiveplan).length ? this.state.currentActiveplan :param?param:{}
      if(Object.keys(active_plan).length>0  &&active_plan?.active_status == "active" && active_plan?.status == "paid" ){
         const typecreate = active_plan.subscribe_plan__plan_type == 'video_audio'? 0 :1
         localStorage.setItem('typecreate', typecreate);
         this.props.history.push(
            `/create/eternaview/${this.state.category}+${this.state.subcategory}+${typecreate}+${active_plan.views}`
          );
       }
      else {
         this.props.history.push({ pathname: `/home/pricing-plans`})
      }

   }

   openSignupPopUp = (ret) => {
      // $('#myLoginPopup').hide();
      this.setState({isSignUpOpen:true});
      this.setState({loginModelOpenStatus:false});
      
    }


   closeLoginPopup = (ret) => {
     if(this.state.loginRedirectUrl=='create')
     {
      this.verifyUserSubscription( this.nextCreate)
     }

     else if(this.state.loginRedirectUrl=='practice'){
      this.checkSection();
     }
     else
     {
      window.location.reload();
     }
      $('#myLoginPopup').modal('toggle');

   }

   closeSignupPopup = (ret) => {
      this.setState({isSignUpOpen:false,isThankyouOpen:true})
    }

    openLoginPopUp = () =>{
      this.setState({isThankyouOpen:false})
      this.setState({ loginModelOpenStatus: true });
      this.setState({isSignUpOpen:false})
    }
   

   loadSelectChange = (item) => {
      //  event.preventDefault();
      this.setState({ typecreate: 0 }); // To set video default

      // if(!this.state.verifyPlanDetail.subscribe_plan__category__id || this.state.verifyPlanDetail.subscribe_plan__category__id == event.target.value) {
      this.setState({ ['category']: item.id });
      let cat = item.id;
      // this.setState({'category':cat});
      this.props.getSubCategoriesByCatOpen(item.id, (res) => {
         this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
         this.setState({ subcategory: res.data.sub_categories[0].id });
      });
   }

   onSelectchange = (event) => {
      event.preventDefault();

      if (event.target.name == 'category') {
         // if(!this.state.verifyPlanDetail.subscribe_plan__category__id || this.state.verifyPlanDetail.subscribe_plan__category__id == event.target.value) {
         this.setState({ [event.target.name]: event.target.value });
         let cat = event.target.value;
         this.props.getSubCategoriesByCatOpen(event.target.value, (res) => {
            this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
            this.setState({ subcategory: res.data.sub_categories[0].id });
         });

         this.props.scanQuestionsOpen({}, `&question_scan=True`, (res) => {
            let con = res.data, questions = [], match = res.transition_name;
            if (res.data.length > 0) {
               con.map((data, index) => {
                  if (data.id == cat) {
                     data.modules.map((mod, ind) => {
                        if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                           questions.push(mod);
                        }
                     });
                  }

               });
               this.setState({ moduleQuestions: questions });
            }
         });
         //} else { // when plan category is different
         // if(this.state.verifyPlanDetail.subscribe_plan__plan_type == "video_audio" || this.state.verifyPlanDetail.subscribe_plan__plan_type == "video&audio") {
         //    toast('You cannot select this category because you have subscribed to different plan.', {
         //       position: "top-right",
         //       autoClose: 3000,
         //       type: 'error',
         //       hideProgressBar: false,
         //       closeOnClick: true,
         //       pauseOnHover: true,
         //       draggable: false,
         //       progress: undefined,
         //    });
         //    this.setState({typecreate : '0'});

         // } else if(this.state.verifyPlanDetail.subscribe_plan__plan_type == "audio") {
         //    this.setState({typecreate : '1'});
         // }
         //}


      }
   }


   onTypeSelected = (e) => {
      this.setState({ typecreate: e.target.value });
      this.setState({ nextbtn: true });
   }
  isLoggedIn = (redirectUrl) => {
      if(redirectUrl != "practice"){
         sessionStorage.setItem("giftType",1)
         this.props.history.push('/home/pricing-plans/')     // Remove if section if clients need login pop for create my etenaview button as well
      }else{
         this.setState({ loginRedirectUrl: redirectUrl });
         this.setState({ loginModelOpenStatus: true });
      }

  
   }

   isLoggedIn2 = (e) => {
      e.preventDefault();

      if (this.props.auth.loginUserToken == "") {
         toast('Please log in first in order to create an Eternaview', {
            position: "top-right",
            autoClose: 3000,
            type: 'error',
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
         });
      }
   }

   noRefCheck(selectedList, selectedItem) {
      console.log(selectedList);
      console.log(selectedItem);
   }
   onHandPopClick = () => {
      // let element = document.getElementById('select-category');
      //  element.value = 2;
      let cat = 2;
      this.setState({ category: cat });
      //  alert('dd');
      /*  if(this.props.auth.loginUserToken)
       { */
      this.props.getSubCategoriesByCatOpen(cat, (res) => {
         this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
         this.setState({ subcategory: res.data.sub_categories[0].id });
      });
      this.props.scanQuestionsOpen({}, `&question_scan=True`, (res) => {
         let con = res.data, questions = [], match = res.transition_name;
         if (res.data.length > 0) {
            con.map((data, index) => {
               if (data.id == parseInt(cat)) {

                  data.modules.map((mod, ind) => {
                     if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                        questions.push(mod);
                     }
                  });
               }

            });

            console.log('+++++++++questions++++++++++');
            console.log(questions);
            this.setState({ moduleQuestions: questions });
         }
      });
      /*   } */
      document.getElementById('select-category').value = cat;

      /* Blogs */

      this.props.blogPosts('', (result) => {
         this.setState({ blogs: result.data });
      });


   }
   goToFaq = (e) => {
      e.preventDefault();

      this.props.history.push('/home/faq/');

   }
   goToBlog = (e) => {
      e.preventDefault();

      this.props.history.push('/home/blogs/');
   }

   redirectToDemo = () => {
      window.open('/#eterna_work', '_blank').focus();
   }

   onCreateEternView = () =>{
      this.verifyUserSubscription( this.nextCreate)
      
   }



   render() {
      const { category, subcategory, subcategories, typecreate, selectedOption, moduleQuestions } = this.state;

      return (


         <section className="cretae_home5 new_create_home5">
            <Circles wrapperClass="circle-spinner"/>
             <FacebookPixel trackName={"ViewContent"}/>
            <div className="container">
               <div className="row">
                  <div className="col-lg-6 col-md-12">
                     <div className="videi78">
                        <div className="bor_ox">
                           <img src={crete_page} />
                           <div className="btn7">

                              {this.props.auth.loginUserToken != "" && <button style={{marginTop:5,marginLeft:0}}
                              className="llogin-btn-h signup-h login-button btn new_login_button" onClick={()=> this.onCreateEternView()} >Create my Eternaview!</button>}
                              {this.props.auth.loginUserToken == "" && <button style={{marginTop:5,marginLeft:0}}
                               className="login-btn-h signup-h login-button btn new_login_button" onClick={()=>this.isLoggedIn('create')} >Create my Eternaview!</button>}

                              {/* {this.props.auth.loginUserToken != "" && <Link to="/home/plans/"><button className="btn">Create my Eternaview Now!</button></Link>} */}

                           </div>
                        </div>

                        <ul className="create-into-btn-ul preview_create">


                           <li>
                              {this.props.auth.loginUserToken != "" && <a style={{marginBottom:10}} href="javascript:void();" className="btn-practice-see-demo create-into-btns" onClick={this.checkSection}><img src={practices_first} />Practice  First</a>}
                              {this.props.auth.loginUserToken == "" && <a style={{marginBottom:10}} href="javascript:void();" className="btn-practice-see-demo create-into-btns" onClick={()=>this.isLoggedIn('practice')} data-toggle="modal" data-target="#myLoginPopup"><img src={practices_first} />Practice  First</a>}
                           </li>

                           {/* <li><a href="javascript:void(0);" onClick={() => this.redirectToDemo()}><img src={play}  />See Demo</a></li> */}
                           <li><a style={{marginBottom:10}} href="javascript:void(0);" className="btn-create-see-demo create-into-btns" data-toggle="modal" data-target="#demomodal"><img src={play} />See Demo</a></li>
                           <li><a style={{marginBottom:10}} href="javascript:void(0);" className="btn-create-see-demo create-into-btns" data-toggle="modal" data-target="#myModal2"><img src={perview} />Question Preview</a></li>



                           <div class="modal fade" id="demomodal" tabindex="-1" role="dialog" aria-labelledby="demomodalLabel" aria-hidden="true">
                              <div class="modal-dialog" role="document">
                                 <div class="modal-content">
                                    <div class="modal-header">
                                       <h5 class="modal-title" id="demomodalLabel">How does Eternaview Work?</h5>
                                       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                       </button>
                                    </div>
                                    <div class="modal-body demo-video-body">
                                       <video style={{ height: 'auto' }} id="a" ref={node => this.video6Node = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions6} playsInline></video>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div  className="modal" id="myModal-demo">
                              <div className="modal-dialog modal-dialog-centered">
                                 <div className="modal-content">

                                    <div className="modal-header">
                                       <button
                                        type="button"className="close custom_close3">&times;</button>
                                    </div>

                                    <div className="modal-body">
                                       <section className="doiect_outer">
                                          <div className="container">

                                             <div className="modal-header">
                                                <h4>Directions: </h4>
                                             </div>
                                             <div className="modal-body">
                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Practice First:</strong> Try out the "Practice First" feature from the Create page to ensure your equipment is working properly, and you're satisfied with the video and audio quality.</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Recommended Devices for Video Eternaviews:</strong> We recommend that any video Eternaview is recorded on a Windows PC or Mac, desktop or laptop, using the Google Chrome browser (Safari will generally work as well). Mobile iOS and Android devices should generally work, but for Android we would only do recordings in a landscape (horizontal) orientation at this time.</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Question Modules:</strong> Your Eternaview will consist of approximately 190 questions divided into modules. Feel free to skip irrelevant questions or entire modules.</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Retakes:</strong> You can retake any of your recordings right after you make them and again at the end of the process. For any questions that you skip, you will be given another chance to answer them at the end.</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Take Breaks:</strong> You can create your Eternaview over multiple sittings. After hitting "Submit" on your latest recorded answer, take a break, and all your progress will be saved.</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Be Creative:</strong> Move your recording device around to showcase your answers. Whether it's cooking, gardening, or playing the piano, have fun with it!</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Answer Length:</strong> We recommend you keep your answers to roughly two minutes or less. This is for technical reasons and to maintain viewers’ attention. In no case should you record an answer longer than four minutes.</p>

                                                <p><i class="fa fa-hand-o-right" aria-hidden="true"></i><strong> Audio-only Eternaviews:</strong> Recording of any audio-only Eternaview (as opposed to video) should be done on a Windows PC using the Google Chrome browser.</p>
                                                <button type="button"  //  data-toggle="modal" className="continue-button" //   data-target="#myModal2"  data-dismiss="modal"
                                                  >Continue</button>

                                             </div>

                                          </div>
                                       </section>
                                    </div>

                                    <div className="modal-footer">
                                       {/*                                         <button type="button" data-toggle="modal" data-target="#myModal2" data-dismiss="modal">Continue</button>
 */}                                    </div>
                                 </div>
                              </div>
                           </div>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                     <div className="righty65">
                        <h5>Eternaview is a perfect way to create a recording of your voice, your face, your mannerisms, your personality, your life story. It's for those who love you and for those who will someday wish they knew you. So that they can talk to you - yes, talk to you - not only while you are still here, but also long after you are gone!</h5>

                        {/*                         <p className="bg67">Eternaview is also a great, low-pressure way for you to let a potential romantic or friend connection get to know you!</p>
 */}
                        <div className="create-into-pointers" >
                           <div className="cilk_outer custom_circel ">
                              <div className="inner_div6">
                                 <img src={small_check} />
                                 <p>Simple, automated interview process. Take a break anytime.</p>
                              </div>
                              <div className="inner_div6">
                                 <img src={small_check} />
                                 <p>Answer only what’s relevant to you and edit any time.</p>
                              </div>
                              <div className="inner_div6">
                                 <img src={small_check} />
                                 <p>Creator pays a small one-time creation fee plus a modest annual subscription fee. No charge for viewers.  </p>
                              </div>
                              <div className="inner_div6">
                                 <img src={small_check} />
                                 <p>Hit <strong>“Practice First”</strong> to try out the technology, test your camera/microphone, and see the quality of your image/sound.
                                 </p>
                              </div>
                              {/*  <div className="inner_div6">
                                 <img src={small_check} />
                                 <p>Can be done as video or audio-only. </p>
                              </div> */}
                           </div>
                        </div>

                        <div className="bottom_tex4">
                           <a href="#" onClick={this.onHandPopClick} data-toggle="modal" data-target="#myModal12"><img src={hand_outer} /></a>
                           <h4>Click here to see our Blog, FAQ, and more!</h4>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="modal custom_mdodel112" id="myModal12">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                     </div>
                     <div className="modal-body cu678">
                        <div className="row">
                           <div className="col-lg-6 col-md-12">
                              <div className="box activer4 create-info-faq">
                                 <h4>Frequently Asked Questions</h4>
                                 <div className="few3_outer">
                                    <i className="fas fa-circle"></i>
                                    <p>Q: What is an Eternaview?</p>
                                 </div>
                                 <div className="few3_outer no_bo675">
                                    <i className="fas fa-circle"></i>
                                    <p>Q. Is the response real or some sort of deepfake or other artificially generated response?</p>
                                 </div>
                                 <div className="few3_outer no_bo675">
                                    <i className="fas fa-circle"></i>
                                    <p>Q: Why do I need Eternaview for creating an interview versus other options?</p>
                                 </div>
                                 <div className="few3_outer no_bo675">
                                    <i className="fas fa-circle"></i>
                                    <p>Q: What types of Eternaviews can be created?</p>
                                 </div>
                                 <div className="few3_outer no_bo675">
                                    <i className="fas fa-circle"></i>
                                    <p>Q. Can I see the interview questions before I subscribe?</p>
                                 </div>






                                 <div className="btn78"><a href="" data-dismiss="modal" onClick={this.goToFaq}>View all</a></div>
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-12 ">
                              <div className="box create-info-scan">
                                 <h4>Scan Modules & Questions in Template</h4>
                                 <div className="custtom_according acc_cus1 template_cus">
                                    <div className="bs-example">
                                       <div className="accordion" id="accordionExample">

                                          {/* {this.state.subcategory == '0' &&  */}
                                          <div className="row spe6 no-specing cat_row">
                                             <div className="col-md-12">
                                                <div className="tex45">
                                                   {/* <h5>Select Category:</h5> */}
                                                </div>
                                             </div>
                                             <div className="col-md-12">
                                                <div className="tex45">
                                                   <select onChange={this.onSelectchange} id="select-category" name="category" value={category} required>
                                                      <option value='0'>Select Category</option>
                                                      {
                                                         this.state.categories && this.state.categories.map((con, index) => {
                                                            return <option key={`category${index}`} value={con.id}>{con.name}</option>
                                                         })
                                                      }
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          {/* } */}

                                          {this.state.subcategory != '0' && moduleQuestions?.map((subcat, ind) => {
                                             return (
                                                <div className="card">
                                                   <div className="card-header" id={"headingOne" + ind}>
                                                      <h2 className="mb-0">
                                                         <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + ind}>{subcat.subcategory_name}<i className="fa fa-angle-down"></i> </button>
                                                      </h2>
                                                   </div>
                                                   <div id={"collapseOne" + ind} className="collapse" aria-labelledby={"headingOne" + ind} data-parent="#accordionExample">
                                                      <div className="card-body cus56">
                                                         {
                                                            subcat.questions.map((ques, index) => {
                                                               return (

                                                                  <p>{ques.question} </p>

                                                               );
                                                            })
                                                         }
                                                      </div>
                                                   </div>
                                                </div>
                                             );
                                          })}

                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-lg-6 col-md-12">
                              <div className="box">
                                 <h4>Demo Video</h4>
                                 <div className="img6_outer create-demo-video-body">
                                    {/*                                     <img src={ctre45} onClick={() => this.redirectToDemo()} />
 */}
                                    <video style={{ height: '100%' }} id="a" ref={node => this.video7Node = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions7} playsInline></video>

                                    {/*    <ReactHlsPlayer 
                                    style={{height:'289px'}}
                                       className="video-js vjs-default-skin custom_video_js"
    src="https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8"
    autoPlay={false}
    controls={true}
    loop={false}
    width="100%"
  />  */}
                                 </div>
                              </div>
                           </div>
                           <div className="col-lg-6 col-md-12">
                              <div className="box vill98">
                                 <h4>Blog Posts</h4>
                                 {this.state.blogs && this.state.blogs.length > 0 && (
                                    <div className="blog_box1">
                                       <img src={this.state.blogs[0].image} />
                                       <div className="texcd4">
                                          <p>{this.state.blogs[0].title}</p>
                                          <span><img src={arrow_right} /></span>
                                       </div>
                                    </div>)
                                 }
                                 <div className="btn78"><a href="" data-dismiss="modal" onClick={this.goToBlog}>View all</a></div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>


            {/* {this.props.auth.loginUserToken && <div className="modal" id="myModal2">
               <div className="modal-dialog">
                  <div className="modal-content">

                     <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        <h4 className="modal-title">Choose Your Eternaview</h4>
                     </div>

                     <div className="modal-body">
                        <div className="custtom_according">
                           <div className="bs-example">
                              
                              <div className="row spe6 no-specing">
                                 <div className="col-lg-7 col-md-12">
                                    <div className="tex45">
                                       <h5>Select Category:</h5>
                                    </div>
                                 </div>
                                 <div className="col-lg-5 col-md-12">
                                    <div className="tex45">
                                       <select onChange={this.onSelectchange} name="category" value={category} required>
                                          <option value='0'>Select Category</option>
                                          {
                                             this.state.categories && this.state.categories.map((con, index) => {
                                                return <option key={`category${index}`} value={con.id}>{con.name}</option>
                                             })
                                          }
                                       </select>
                                    </div>
                                 </div>
                              </div>

                              <div className="row spe6">
                                 <div className="col-lg-7 col-md-12">
                                    <div className="tex45">
                                       <h5>Type of Eternaview you want to create?</h5>
                                    </div>
                                 </div>
                                 <div className="col-lg-5 col-md-12">
                                    <div className="tex45">
                                       <div className="checkouter56">
                                          <label className="container9">Video<input type="radio" name="radio" value="0" checked={typecreate == '0'} onChange={this.onTypeSelected} /><span className="checkmark"></span></label>
                                          <label className="container9">Audio-only<input type="radio" name="radio" value="1" checked={typecreate == '1'} onChange={this.onTypeSelected} /><span className="checkmark"></span></label>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              {moduleQuestions.length > 0 && <div className="accordion" id="accordionExample">
                                 {moduleQuestions?.map((subcat, ind) => {
                                    return (<div className="card">
                                       <div className="card-header" id={"headingOne" + (ind + 1)}>
                                          <h2 className="mb-0">
                                             <button type="button" className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + (ind + 1)}>{subcat.subcategory_name}<i className="fa fa-angle-right"></i> </button>
                                          </h2>
                                       </div>

                                       <div id={"collapseOne" + (ind + 1)} className="collapse" aria-labelledby={"headingOne" + (ind + 1)} data-parent="#accordionExample">
                                          <div className="card-body">
                                             {
                                                subcat.questions.map((ques, index) => {
                                                   return (
                                                      <p>{ques.question}</p>
                                                   );
                                                })
                                             }
                                          </div>
                                       </div>
                                    </div>);
                                 })}

                              </div>}
                           </div>
                        </div>
                     </div>

                     <div className="modal-footer">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                     </div>

                  </div>
               </div>
            </div>} */}
          
            <div id="myLoginPopup"
               style={{
                  display: this.state.loginModelOpenStatus === true
                     ? "block"
                     : "none"}}
               className={this.state.loginModelOpenStatus === true
                     ? "modal fade show"
                     : "modal fade hide"} 
               role="dialog">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                         <button  onClick={()=>{this.setState({loginModelOpenStatus:false})}} type="button" className="close" data-dismiss="modal">&times;</button>
                    
                     <div className="modal-body custom_body custom_modal_body">
                        <Login
                           customClass={{modalTop:"modalh4"}}
                           isLoginPopup={true}
                           isSignupPop = {this.openSignupPopUp}
                           closeLoginPopup={this.closeLoginPopup}
                           loginRedirectUrl={this.state.loginRedirectUrl}
                        />
                     </div>
                  </div>
               </div>
            </div>

            <div id="mySignupPopup"
               style={{
                  display: this.state.isSignUpOpen === true
                     ? "block"
                     : "none"}}
               className={this.state.isSignUpOpen === true
                     ? "modal fade show"
                     : "modal fade hide"} 
               role="dialog">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                        <button type="button" className="close" onClick={()=>{this.setState({isSignUpOpen:!this.state.isSignUpOpen})}}>&times;</button>
                     <div className="modal-body custom_body custom_modal_body">
                        <RegisterComponent 
                         customClass={{modalTop:"modalh4"}}
                        openLoginPopUp={this.openLoginPopUp}
                        closeSignupPopup={this.closeSignupPopup}
                        signUpRedirectUrl={this.state.loginRedirectUrl}
                        />
                     </div>
                  </div>
               </div>
            </div>

            <div id="thankyouPopup"
               style={{
                  display: this.state.isThankyouOpen === true
                     ? "block"
                     : "none"}}
               className={this.state.isThankyouOpen === true
                     ? "modal fade show"
                     : "modal fade hide"} 
               role="dialog">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                     <div className="modal-header custom_modal_header">
                        <button type="button" className="close" onClick={()=>{this.setState({isThankyouOpen:!this.state.isThankyouOpen})}}>&times;</button>
                    <h4 className="modal-title"></h4> 
                     </div>
                     <div className="modal-body custom_body custom_modal_body">
                        {
                           this.state.isThankyouOpen&&
                           <Thankyou 
                           customClass={true}
                           openLoginPopUp={this.openLoginPopUp}
                           />
                        }
                       
                     </div>
                  </div>
               </div>
            </div>



            {/* this.props.auth.loginUserToken &&  */} <div id="myModal2" className="modal fade ci-question-preview" role="dialog">
               <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                     <div className="modal-header ci-pop-header">
                        <div className="row pop-header-row">

                           <div className="col-md-4 ci-pop-header-logo ">
                              <h3>Eterna<span>view</span></h3>
                           </div>
                           <div className="col-md-4 ci-pop-header-heading">
                              <h4 style={{fontWeight:600}} className="modal-title">Module & Question Preview</h4>

                           </div>

                           <div className="col-md-4 ci-pop-header-close">
                              <button type="button" className="close" data-dismiss="modal">&times;</button>

                           </div>

                        </div>

                     </div>
                     <div className="modal-body custom_body">
                        <div className="spec_outer">

                           <div className="row spe6 no-specing">
                              <div className="col-lg-3 col-md-12">
                                 <div className="tex45">
                                    <h5>Select Category:</h5>
                                 </div>
                              </div>
                              <div className="col-lg-9 col-md-12">
                                 <div className="tex45">
                                    <select onChange={this.onSelectchange} name="category" value={category} required>
                                       <option value='0'>Select Category</option>
                                       {
                                          this.state.categories && this.state.categories.map((con, index) => {
                                             return <option style={con.name !== 'Legacy'?{fontWeight:"lighter"}:{fontWeight:500}} key={`category${index}`} value={con.id}>{con.name} {con.name === 'Dating' ? '(coming soon)' : ''}</option>
                                          })
                                       }
                                    </select>
                                 </div>
                              </div>
                           </div>



                           <div className="row spe6">
                              <div className="col-lg-3 col-md-12">
                                 <div className="tex45">
                                    <h5>Type of Eternaview you want to create?</h5>
                                 </div>
                              </div>
                              <div className="col-lg-9 col-md-12">
                                 <div className="tex45">
                                    <div className="checkouter56">
                                       <label className="container9">Video<input type="radio" name="radio" value="0" checked={typecreate == '0'} disabled={this.state.verifyPlanDetail.subscribe_plan__plan_type == "audio" ? true : false} onChange={this.onTypeSelected} /><span className="checkmark"></span></label>
                                       <label style={{fontWeight:"lighter"}} className="container9">Audio-only (coming soon)<input type="radio" name="radio" value="1" checked={typecreate == '1'} disabled={(this.state.verifyPlanDetail.subscribe_plan__plan_type == "video_audio" || this.state.verifyPlanDetail.subscribe_plan__plan_type == "video&audio") ? true : false} onChange={this.onTypeSelected} /><span className="checkmark"></span></label>
                                    </div>
                                 </div>
                              </div>
                           </div>

                           <div className="row spe6">
                              {moduleQuestions && moduleQuestions.length > 0 && <div className="col-lg-3 col-md-12">
                                 <div className="tex45">
                                    <h5>If you would like to scan the questions
                                       ahead of time, click here:
                                    </h5>
                                 </div>
                              </div>}

                              <div className="col-lg-9 col-md-12">
                                 <p>A Legacy Eternaview has ~190 questions. Skip any modules or questions that are not relevant to you. Edit any time.</p>
                                 <div className="custtom_according create_custom_acc ci-pq-accorscroll" id="accorscroll">
                                    <div className="bs-example" >
                                       <div className="accordion" id="accordionExample2">
                                          {moduleQuestions?.map((subcat, ind) => {
                                             return (
                                                <div className="card" >
                                                   <div className="card-header" id={"headingOne" + ind}>
                                                      <h2 className="mb-0">
                                                         <button type="button" onClick={(e) => this.setScroll(e)} className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + ind}>{subcat.subcategory_name}<i className="fa fa-angle-down"></i> </button>
                                                      </h2>
                                                   </div>
                                                   <div id={"collapseOne" + ind} className="collapse" aria-labelledby={"headingOne" + ind} data-parent="#accordionExample2">
                                                      <div className="card-body">
                                                         {
                                                            subcat.questions.map((ques, index) => {
                                                               return (
                                                                  <button>{ques.question}<span className="custom_icon"></span></button>
                                                               );
                                                            })
                                                         }
                                                      </div>
                                                   </div>
                                                </div>
                                             );
                                          })}

                                       </div>
                                    </div>
                                 </div>




                                 <div className="submit_outer45 nex45 ci-pq-next">
                                    {this.props.auth.loginUserToken ? (
                                       <>
                                          {this.state.category != '0' && <button type="button" className="btn" onClick={this.nextCreate} data-dismiss="modal">Done</button>}
                                          {this.state.category == '0' && <button type="button" className="btn" onClick={this.nextCreate} >Next</button>}
                                       </>
                                    ) : (
                                       <button type="button" className="btn" data-dismiss="modal">Close</button>

                                    )}


                                 </div>

                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
            {/* } */}

         </section>

      );
   }
}


const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
   getCategories: bindActionCreators(getCategories, dispatch),
   getCategoriesOpen: bindActionCreators(getCategoriesOpen, dispatch),

   getSubCategories: bindActionCreators(getSubCategories, dispatch),
   getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
   getSubCategoriesByCat: bindActionCreators(getSubCategoriesByCat, dispatch),
   getSubCategoriesByCatOpen: bindActionCreators(getSubCategoriesByCatOpen, dispatch),


   scanQuestions: bindActionCreators(scanQuestions, dispatch),
   scanQuestionsOpen: bindActionCreators(scanQuestionsOpen, dispatch),


   verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
   blogPosts: bindActionCreators(blogPosts, dispatch),

});

export default connect(mapStateToProps, mapDispatchToProps)(CreateIntro);
