import React, { useEffect, useState } from "react";
import videojs from "video.js";
import { useRef } from "react";

const DemoVideo = (props) => {
    const videoPlayerRef = useRef(); // Instead of ID
    const videoSrc='https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8';
    const videoJSOptions = {
      controls: true,
      bigPlayButton: true,
      autoplay: false,
      responsive: true,
      aspectRatio: '16:9',
      width: "auto",
      height: "auto",
      fluid: true,
      sources: [{
          src: 'https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8',
          type: 'application/x-mpegURL'
      }]
    };
    
     // Initialise video player
     useEffect(() => {
      const player = videojs(videoPlayerRef.current, videoJSOptions, () => {
        player.src(videoSrc)
        console.log('Player Ready')
      })
      return () => {
        player.dispose()
        console.log('Player Disposed')
      }
    }, [])
  return (
    <div
      id="demomodal"
      style={{
        display: props.isOpen ? "block" : "none",
      }}
      className={
        props.isOpen ? 
     "modal fade show" : "modal fade hide"
      }
      tabindex="-1"
      role="dialog"
      aria-labelledby="demomodalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="demomodalLabel">
              How does Eternaview Work?
            </h5>
            <button
            onClick={()=>{props.handleDemoVideo(false)}}
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body demo-video-body">
            <video
              ref={videoPlayerRef}
              style={{ height: "auto" }}
              id="myVideo6"
              className="video-js vjs-default-skin custom_video_js"
              src="https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8"
              playsInline
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoVideo
