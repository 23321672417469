import * as types from "../constants";

const initialState = {
    loginUserInfo: {},
    fbLoginUserInfo:{},
    loginUserToken: '',
    credentials: {},
    moduleCheck: {}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.LOGIN_USER_INFO: {
            return { ...state, loginUserInfo: action.data }
        }

        case types.FB_LOGIN_USER_INFO: {
            return { ...state, fbLoginUserInfo: action.data }
        }

        case types.SAVE_TOKEN: {
            return { ...state, loginUserToken: action.data }
        }

        case types.SAVE_CREDENTIALS: {
            return { ...state, credentials: action.data }
        }

        case types.LAST_MODULE_CHECK: {
            return { ...state, moduleCheck: action.data }
        }
        default:
            return state;
    }
}