import React from "react";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

const Landing = ({ children }) => (
    <React.Fragment>
      <Navbar {...children} />
      <Content>{children}</Content>
      <Footer />
    </React.Fragment>
);

export default Landing;
