import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import "videojs-contrib-quality-levels"; // videoJs Quality levels **
import "videojs-hls-quality-selector"; // videojs Quality Selector **
import video_recording from "../../Images/video_recording.png";
import hand_outer from "../../Images/hand_outer.png";
import keepasis from "../../Images/keepasis.png";
import mic from "../../Images/mic.gif";
import arrow_right from "../../Images/arrow_right.png";
import ctre45 from "../../Images/ctre45.png";
import { isMobile } from "react-device-detect";
import {
  getCreateQuestion,
  updateAnswer,
  getQuestionById,
  getEditQuestion,
  saveCreateQuestion,
  scanQuestions,
  verifyUserSubscription,
  getParticularQuestionDetail,
} from "../../redux/actions";
import HeaderComponent from "../../components/Header";
import chat_tak from "../../Images/chat_tak.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import RecordRTC, { invokeSaveAsDialog } from "recordrtc";
import getBlobDuration from "get-blob-duration";
import timer1 from "../../Images/Timer1.png";
import timer2 from "../../Images/Timer2.png";
import timer3 from "../../Images/Timer3.png";
import start from "../../Images/start.png";
import ringtone from "../../Sounds/beep-07a.mp3";
import Loader from "react-loader";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { browserName, browserVersion } from "react-device-detect";
import AudioAnalyser from "./AudioAnalyser";
import AudioRecorder from "audio-recorder-polyfill";
import mpegEncoder from "audio-recorder-polyfill/mpeg-encoder";
import blog1 from "../../Images/blog1.png";
import connection from "../../config/connection";
import { blogPosts } from "../../redux/actions/index";
import {
  getCategories,
  getCategoriesOpen,
  getSubCategories,
  getSubCategoriesByCat,
  getSubCategoriesByCatOpen,
  scanQuestionsOpen,
} from "../../redux/actions";

import { stopwatch } from "durations";
import $ from 'jquery';
import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.js';

const videoIOSType = "video/mp4";
const videoWebType = "video/webm";
const audioType = "audio/*";

const iosCheck =
  /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
const details = navigator.userAgent;
const regexp = /android|iphone|kindle|ipad/i;
const isMobileDevice =
  regexp.test(details) ||
  (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
const checkSafari =
  /Safari|safari|iPod/.test(navigator.userAgent) && !window.MSStream;
const watch = stopwatch();

const videoJsOptions = {
  controls: true,
  bigPlayButton: false,
  autoplay: true,
  responsive: true,
  width: 480,
  height: 320,
  fluid: true,
  cumulativeTime: "00:00:00",
};

const videoJsOptions7 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/259abd55-d5eb-48a7-bb72-38d782f825b5/hls/Legacy Eternaview Demo January 8th 2024Todd Revision (1).m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

class EditEternaview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recording: false,
      cumulativeTime: "00:00:00",
      recordingAfter: false,
      videos: [],
      completedata: {},
      data: {},
      blogs: [],
      recordedVideo: {},
      recordedAudio: {},
      category: "",
      subcategory: "",
      question: "",
      numberRecordings: "",
      stream: {},
      submitFlag: false,
      isSubmitted: false,
      videoUrl: "",
      audioUrl: "",
      duration: "",
      seconds: 4,
      typecreate: "",
      timer: 0,
      current_time_recording: "",
      isActive: false,
      isPaused: false,
      loaded: true,
      type: "",
      moduleQuestions: [],
      newRecording: false,
      total_time: false,
      grace_total_time: false,
      viewId: "",
      screen_mode: "",
      isProcessing: false,
      hasMicrophone: false,
      hasWebcam: false,
      videoOptions: {
        video: {
          width: { min: 320, ideal: 1920, max: 1920 },
          height: { min: 180, ideal: 1080, max: 1080 },
          aspectRatio: 1.7777777778,
          facingMode: "user",
          // width: { min: 320, ideal: 580, max: 1920 },
          // height: { min: 240, ideal: 420, max: 1080 },
          // aspectRatio: 1.777777778,
        },
        audio: true,
      },
      videoFacing: "user",
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      recordingMiliSeconds: 0,
      showRecording: false,
      isRetakeSec: false, // used boolean  isRetakeSec to dynamically update its value based on sect 60-120 on video retake
      record_click: "submit",
    };
    this.ManageOrientation = this.ManageOrientation.bind(this);
    this.countRef = React.createRef(null);
    this.baseName = '';
    this.basePath =  connection.homePage;
  }

  format(num) {
    return (num + "").length === 1 ? "0" + num : num + "";
  }

  ManageOrientation = () => {
    var mql = window.matchMedia("(orientation: portrait)");

    // If there are matches, we're in portrait
    if (mql.matches) {
      // Portrait orientation
      // alert("You are in Portrait mode load");
      this.setState({ screen_mode: "portrait" });
    } else {
      // Landscape orientation
      // alert("You are in landscape mode load");
      this.setState({ screen_mode: "landscape" });
    }

    const callBack = (m) => {
      if (m.matches) {
        // Changed to portrait
        // alert("You are in Portrait mode change");
        this.setState({ screen_mode: "portrait" });
      } else {
        // Changed to landscape
        // alert("You are in landscape mode change");
        this.setState({ screen_mode: "landscape" });
      }
    };
    // Add a media query change listener
    mql.addListener(callBack);
  };

  componentDidMount() {

    const { location } = this.props;
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    if(splitLocation[1]=='dating')
    {
      this.baseName = 'dating';
      this.basePath =  connection.datingHomePage;
    }
    else
    {
      this.baseName = 'home';
      this.basePath =  connection.homePage;
    }


    var mql = window.matchMedia("(orientation: portrait)");

    // If there are matches, we're in portrait
    // if (mql.matches) {
    //   // Portrait orientation
    //   // alert("You are in Portrait mode load");
    //   this.setState({ screen_mode: "portrait" });
    // } else {
    //   // Landscape orientation
    //   // alert("You are in landscape mode load");
    //   this.setState({ screen_mode: "landscape" });
    // }

    // Add a media query change listener
    // mql.addListener(function (m) {
    //   if (m.matches) {
    //     // Changed to portrait
    //     // alert("You are in Portrait mode change");
    //     this.setState({ screen_mode: "portrait" });
    //   } else {
    //     // Changed to landscape
    //     // alert("You are in landscape mode change");
    //     this.setState({ screen_mode: "landscape" });
    //   }
    // });

    this.ManageOrientation();

    if (this.props.auth.loginUserToken == "") {
      // this.props.history.replace("/");
      window.location.href = this.basePath
    }
    let split = this.props.match.params.id.split("+");

    this.player = videojs(
      this.videoNode,
      videoJsOptions,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player7 = videojs(
      this.video7Node,
      videoJsOptions7,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player7.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.setState({
      viewId: split[3],
      typecreate: split[4] == "audio" || split[4] == "1" ? 1 : 0,
    });
    console.log(split);

    // this.props.getEditQuestion(split[0], split[1], split[2], this.props.auth.loginUserToken, (res) => {
    this.props.getParticularQuestionDetail(
      `author-question/${split[2]}/?view_id=${split[3]}`,
      this.props.auth.loginUserToken,
      (res) => {
        let temp = res.data;
        this.setState({ completedata: res, data: temp });
        this.setState({
          category: split[0],
          subcategory: split[1],
          question: split[2],
        });
        if (res.data && res.data.answer.video) {
          if (res.data.answer.video.type == "audio") {
            this.setState({
              cumulativeTime: res.total_time,
              audioUrl: res.data.answer.video.media_file,
              typecreate: 1,
              current_time_recording: res.data.answer.video.duration,
            });
          } else {
            if((res.data.answer.video.screen_mode=="landscape" )&& (this.state.screen_mode == "portrait")){
              $.alert({
                title: 'Alert!',
                content: 'For better video experience please rotate your device',
              });
             
            }
            console.log(this.videoNode,"<-  this.videoNode");
            // this.videoNode.src = res.data.answer.video.trans_coded_url;
            this.player.src({
              src: res.data.answer.video.trans_coded_url,
              type: "application/x-mpegURL",
            });
            if(this.player){
              this.videoNode = this.player
            }
            // this.player.hlsQualitySelector({
            //   displayCurrentQuality: true
            // });
            this.player.on('play', function(res) {
             this.player = this.player()
              let qualityLevels =   this.player.qualityLevels();
              qualityLevels.on('addqualitylevel', function(event) {
                let qualityLevel = event.qualityLevel;
                if (qualityLevel.height >= 720) {
                  qualityLevel.enabled = true;
                } else {
                  qualityLevel.enabled = false;
                }
              });
           
            });
            this.setState({
              cumulativeTime: res.total_time,
              videoUrl: res.data.answer.video.trans_coded_url,
              typecreate: res.data.answer.video.type == "video" ? 0 : 1,
              current_time_recording: res.data.answer.video.duration,
            });
          }

          this.setState({
            recording: false,
            numberRecordings: "2",
            isSubmitted: false,
          });
        } else if (res.data) {
          this.setState({ cumulativeTime: res.total_time });
        }
      }
    );

    if (split[4] == 0) {
      this.video.src = this.video.srcObject = null;
      this.video.muted = false;
      this.video.volume = 1;
    } else {
      if (this.audio) {
        this.audio.src = this.audio.srcObject = null;
        this.audio.muted = true;
        this.audio.volume = 0;
      }
    }

    this.props.scanQuestions(
      {},
      `&question_scan=True`,
      this.props.auth.loginUserToken,
      (res) => {
        let con = res.data,
          questions = [],
          match = res.transition_name;

        if (res.data.length > 0) {
          con.map((data, index) => {
            if (data.id == split[0]) {
              data.modules.map((mod, ind) => {
                if (
                  mod.subcategory_name !=
                    "Interaction Facilitation Part 1 (required)" &&
                  mod.subcategory_name != "Interaction Facilitation Part 2" &&
                  mod.subcategory_name != "Pleasantries" &&
                  mod.subcategory_name !=
                    "Interaction Facilitation & Pleasantries (required)"
                ) {
                  questions.push(mod);
                }
              });
            }
          });
          this.setState({ moduleQuestions: questions });
        }
      }
    );

    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
      if (res.data) {
        if (res.data.updated_details.total_time != 0) {
          let remaining_time =
            res.data.subscribe_plan__total_time * 3600 -
            res.data.updated_details.total_time;
          // remaining_time = Math.ceil(remaining_time / 3600);
          if (remaining_time == 1) {
            this.setState({ total_time: true });
          }
        } else if (
          res.data.updated_details.total_time == 0 &&
          res.data.updated_details.grace_time > 0
        ) {
          let remaining_time =
            res.data.subscribe_plan__grace_total_time * 3600 -
            res.data.updated_details.grace_time;
          //  remaining_time = Math.ceil(remaining_time / 3600);
          if (remaining_time == 1) {
            this.setState({ grace_total_time: true });
          }
        }
      }
    });
  }

  onHandPopClick = () => {
    /*  let cat = 2;
         this.setState({category:cat});
   
              this.props.getSubCategoriesByCatOpen(cat, (res) => {
                 this.setState({ subcategories: res.data.sub_categories, catName: res.data.name });
                 this.setState({ subcategory: res.data.sub_categories[0].id });
              }); 
              this.props.scanQuestionsOpen({}, `&question_scan=True`, (res) => {
                 let con = res.data, questions = [], match = res.transition_name;
                 if (res.data.length > 0) {
                    con.map((data, index) => {
                       if (data.id == parseInt(cat)) {
                          
                          data.modules.map((mod, ind) => {
                             if (mod.subcategory_name != "Interaction Facilitation Part 1 (required)" && mod.subcategory_name != "Interaction Facilitation Part 2" && mod.subcategory_name != "Pleasantries" && mod.subcategory_name != "Interaction Facilitation & Pleasantries (required)") {
                                questions.push(mod);
                             }
                          });
                       }
     
                    });
 
                    console.log('+++++++++questions++++++++++');
                    console.log(questions);
                    this.setState({ moduleQuestions: questions });
                 }
              });
        
           document.getElementById('select-category').value=cat;
*/
    /* Blogs */

    this.props.blogPosts("", (result) => {
      this.setState({ blogs: result.data });
    });
  };

  videoOptionsChange = (e) => {
    // alert(e.target.value);
    let videoOptions = {
      video: {
        width: { min: 320, ideal: 1920, max: 1920 },
        height: { min: 180, ideal: 1080, max: 1080 },
        aspectRatio: 1.7777777778,
        facingMode: e.target.value,
        // width: { min: 320, ideal: 580, max: 1920 },
        // height: { min: 240, ideal: 420, max: 1080 },
        // aspectRatio: 1.777777778,
      },
      audio: true,
    };

    this.setState({ videoOptions: videoOptions, videoFacing: e.target.value });
  };

  async getCameraStream() {
    var interval = 0;
    this.setState({ numberRecordings: "", showRecording: true });
    var audio = new Audio(ringtone);
    await navigator.mediaDevices
      .getUserMedia(this.state.videoOptions)
      .then((stream) => {
        this.stream = stream;
        this.chunks = [];
        this.video.srcObject = stream;
        this.video.muted = true;
        this.video.volume = 0;

        this.setState({ seconds: 3 });

        audio.play();

        this.video.play();
        this.setState({ recording: true });

        let myInterval = setInterval(() => {
          if (this.state.seconds > 0) {
            audio.play();
            this.setState({ seconds: this.state.seconds - 1 });
          } else if (this.state.seconds <= 0) {
            clearInterval(myInterval);
            this.setState({ seconds: this.state.seconds - 1 });

            this.countRef.current = setInterval(() => {
              this.setState({ timer: this.state.timer + 1 });
            }, 1000);

            // listen for data from media recorder
            this.setState({ recordingAfter: true });
            this.setState({ isActive: true, isPaused: true });

            var iosCheck =
              /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

            if (iosCheck == false) {
              this.mediaRecorder = new MediaRecorder(stream, {
                mimeType:
                  `${browserName}` == "Safari" || `${browserName}` == "safari"
                    ? videoIOSType
                    : videoWebType,
              });
            } else {
              this.mediaRecorder = new MediaRecorder(stream, {
                mimeType: videoIOSType,
              });
            }

            this.mediaRecorder.start(10);
            watch.start();

            this.setState({ stream: this.mediaRecorder.stream });

            setInterval(() => {
              if (watch.isRunning()) {
                // console.log((watch.duration().hours().toString()).split(".")[0]+":"+(watch.duration().minutes().toString()).split(".")[0]+":"+(((watch.duration().seconds()-interval)).toString()).split(".")[0]);
                this.state.recordingMiliSeconds = watch
                  .duration()
                  .millis()
                  .toString()
                  .split(".")[0];
                this.state.recordingHours = watch
                  .duration()
                  .hours()
                  .toString()
                  .split(".")[0];
                this.state.recordingMinutes = watch
                  .duration()
                  .minutes()
                  .toString()
                  .split(".")[0];


                  let new_time = '';
                  let plus_point =0;
                  if (this.state.record_click == 'retaik') {
                    let nnn = watch.duration().seconds();
  
                 if(nnn  > 58 && nnn < 118){  
                  this.setState({ isRetakeSec: true });  //code: to update interval between 60secto 120 sec  used boolean isRetakeSec
                  }else {
                  this.setState({ isRetakeSec: false });
                  }    
                    if (nnn < 60) {
                     if(interval>0)
                     {
                       plus_point = interval>60?interval:60;
                     }
                     
                      new_time = watch.duration().seconds() - interval + parseInt(plus_point);
                    }
                    else {
                    
                      new_time = watch.duration().seconds() - interval;
                    }
               
                  }
                  else {
                    new_time = watch.duration().seconds() - interval;
                  }
                  this.state.recordingSeconds = ( new_time ).toString().split(".")[0];
                // this.state.recordingSeconds = (
                //   watch.duration().seconds() - interval
                // )
                //   .toString()
                //   .split(".")[0];
                // if (
                //   watch.duration().seconds().toString().split(".")[0] -
                //     interval ==
                //   59
                // ) {
                //   interval = interval + 60;
                // }
                if(this.state.isRetakeSec){     //code: to update interval between 60svec to 120 sec  based on  boolean isRetakeSec
                  interval = 60;
                 }  else if (  !this.state.isRetakeSec &&
                   watch.duration().seconds().toString().split(".")[0] -
                   interval ==
                   59
                 ) {
                   interval = interval + 60;
                 }


                // this.state.recordingSeconds = (
                //   watch.duration().seconds() - interval
                // )
                //   .toString()
                //   .split(".")[0];

                // if (
                //   watch.duration().seconds().toString().split(".")[0] -
                //     interval ==
                //   59
                // ) {
                //   interval = interval + 60;
                // }
              }

              // console.log(interval);
            }, 1000);

            this.mediaRecorder.ondataavailable = (e) => {
              if (e.data && e.data.size > 0) {
                // this.state.recordingMiliSeconds++;
                // if (this.state.recordingMiliSeconds >= 20) {
                //     this.state.recordingSeconds++;
                //     this.state.recordingMiliSeconds = 0;
                // }
                // if (this.state.recordingSeconds >= 60) {
                //     this.state.recordingMinutes++;
                //     this.state.recordingSeconds = 0;
                // }
                // if (this.state.recordingMinutes >= 60) {
                //     this.state.recordingHours++;
                //     this.state.recordingMinutes = 0;
                // }

                this.chunks.push(e.data);

                // this.state.recordingMiliSeconds=Math.round(watch.duration().millis());
                // this.state.recordingHours=Math.round(watch.duration().hours());
                // this.state.recordingMinutes=Math.round(watch.duration().minutes());
                // this.state.recordingSeconds=Math.round(watch.duration().seconds());

                // console.log(` ${watch.duration().hours()} hour,${watch.duration().minutes()} minutes,${watch.duration().seconds()} seconds,`);
              }
            };
            return stream;
          }
        }, 1000);
        // return ()=> {
        //     clearInterval(myInterval);
        // };
      })
      .catch((err) => {
      
        $.alert({
          title: 'Alert!',
          content: 'Please enable your camera and/or microphone in order to create your Eternaview.',
        });
        this.setState({ hasWebcam: false });
        this.setState({ hasMicrophone: false });
        return false;
        //throw new Error("Unable to fetch stream " + err);
      });
    //  return localStream;
  }

  async getAudioStream() {
    this.setState({ numberRecordings: "", showRecording: true });
    var audiosound = new Audio(ringtone);

    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.stream = stream;
        this.chunks = [];
        this.audio.srcObject = stream;

        this.audio.muted = true;

        audiosound.play();
        this.setState({ seconds: 3 });

        this.setState({ recording: true });

        this.myInterval = setInterval(() => {
          if (this.state.seconds > 0) {
            audiosound.play();
            this.setState({ seconds: this.state.seconds - 1 });
          } else if (this.state.seconds <= 0) {
            clearInterval(this.myInterval);
            this.setState({ seconds: this.state.seconds - 1 });
            this.countRef.current = setInterval(() => {
              this.setState({ timer: this.state.timer + 1 });
            }, 1000);
            // listen for data from media recorder
            this.setState({ recordingAfter: true });
            this.setState({ isActive: true, isPaused: true });
            this.setState({ audio: stream });

            // if (iosCheck == true || checkSafari == true) {
            //     AudioRecorder.encoder = mpegEncoder
            //     AudioRecorder.prototype.mimeType = 'audio/mpeg'
            //     window.MediaRecorder = AudioRecorder
            // }

            this.mediaRecorder = new MediaRecorder(stream);

            this.mediaRecorder.start(10);
            watch.start();

            this.setState({ stream: this.mediaRecorder.stream });

            // this.mediaRecorder.ondataavailable = e => {
            //     if (e.data && e.data.size > 0) {
            //         this.chunks.push(e.data);
            //     }
            // };
            var interval = 0;
            setInterval(() => {
              // console.log((watch.duration().hours().toString()).split(".")[0]+":"+(watch.duration().minutes().toString()).split(".")[0]+":"+(((watch.duration().seconds()-interval)).toString()).split(".")[0]);
              this.state.recordingMiliSeconds = watch
                .duration()
                .millis()
                .toString()
                .split(".")[0];
              this.state.recordingHours = watch
                .duration()
                .hours()
                .toString()
                .split(".")[0];
              this.state.recordingMinutes = watch
                .duration()
                .minutes()
                .toString()
                .split(".")[0];
              this.state.recordingSeconds = (
                watch.duration().seconds() - interval
              )
                .toString()
                .split(".")[0];

              if (
                watch.duration().seconds().toString().split(".")[0] -
                  interval ==
                59
              ) {
                interval = interval + 60;
              }

              // console.log(interval);
            }, 1000);

            this.mediaRecorder.addEventListener("dataavailable", (e) => {
              if (e.data && e.data.size > 0) {
                // this.state.recordingMiliSeconds++;
                // if (this.state.recordingMiliSeconds >= 17) {
                //     this.state.recordingSeconds++;
                //     this.state.recordingMiliSeconds = 0;
                // }
                // if (this.state.recordingSeconds >= 60) {
                //     this.state.recordingMinutes++;
                //     this.state.recordingSeconds = 0;
                // }
                // if (this.state.recordingMinutes >= 60) {
                //     this.state.recordingHours++;
                //     this.state.recordingMinutes = 0;
                // }

                this.chunks.push(e.data);
              }
            });

            return stream;
          }
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        
         
        $.alert({
          title: 'Alert!',
          content: 'Please enable your camera and/or microphone in order to create your Eternaview.',
        });
        this.setState({ hasWebcam: false });
        this.setState({ hasMicrophone: false });
        return false;
        //throw new Error("Unable to fetch stream " + err);
      });
  }

  startRecording(e) {
    e.preventDefault();

    this.handleReset();
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }
    if (this.state.typecreate == 0) {
      this.getCameraStream();
    } else {
      this.getAudioStream();
    }
  }

  getTimeDuration=()=>{
    let totalSeconds
        if( (this.state.recordingHours === 0 )&&
          (this.state.recordingMinutes === 0) &&
        (  this.state.recordingSeconds === 0)){
          totalSeconds= new Date(this.state.duration * 1000)
            .toISOString()
            .substr(11, 8)
        }
        else{
          const hours = (this.format(this.state.recordingHours)*3600)
          const minutes = (this.format(this.state.recordingMinutes)*60 )
          totalSeconds = parseInt(this.format(this.state.recordingSeconds)) + parseInt(hours) + parseInt(minutes)
        }
       return totalSeconds
  }

  stopRecording(e) {
    e.preventDefault();
    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }
    this.handlePause();
    this.setState({
      recording: false,
      numberRecordings: "1",
      newRecording: true,
      recordingAfter: false,
      recordingMiliSeconds: 0,
      recordingSeconds: 0,
      recordingMinutes: 0,
      recordingHours: 0,
    });

    var iosCheck =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.setState({ isProcessing: true });
    if (this.state.typecreate == 0) {
      this.video.srcObject = this.video.src = null;
      this.video.muted = false;
      this.video.volume = 1;
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }

      if (iosCheck == false) {
        const blob = new Blob(this.chunks, {
          type:
            `${browserName}` == "Safari" || `${browserName}` == "safari"
              ? videoIOSType
              : videoWebType,
        });

        this.setState({ recordedVideo: blob });
        //  const videoURL = window.URL.createObjectURL(blob);
        const videoURL = window.webkitURL.createObjectURL(blob);

        this.setState({ videoUrl: videoURL });

        let that = this;

        const duration = this.getTimeDuration()
        that.setState({ duration: duration, isProcessing: false });
        // getBlobDuration(blob)
        //   .then(function (duration) {
        //     that.setState({ duration: duration, isProcessing: false });
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
      } else {
        const blob = new Blob(this.chunks, { type: "video/mp4" });

        this.setState({ recordedVideo: blob });
        //  const videoURL = window.URL.createObjectURL(blob);
        const videoURL = window.webkitURL.createObjectURL(blob);

        this.setState({ videoUrl: videoURL });

        let that = this;
        const duration = this.getTimeDuration()
        that.setState({ duration: duration, isProcessing: false });
        // getBlobDuration(blob)
        //   .then(function (duration) {
        //     that.setState({ duration: duration, isProcessing: false });
        //   })
        //   .catch((error) => {
        //     console.error(error);
        //   });
      }

      if (iosCheck == false) {
        this.state.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    } else if (this.state.typecreate == 1 || this.state.typecreate == "1") {
      this.audio.src = this.audio.srcObject = null;
      this.audio.muted = false;
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
      }

      const blob = new Blob(this.chunks, {
        type:
          `${browserName}` == "Safari" ||
          `${browserName}` == "safari" ||
          `${browserName}` == "Mobile Safari"
            ? "audio/mp4"
            : audioType,
      });

      this.setState({ recordedVideo: blob });
      this.setState({ audio: null });

      const audioUrl = window.webkitURL.createObjectURL(blob);

      let that = this;
      const duration = this.getTimeDuration()
      that.setState({ duration: duration, isProcessing: false });
      // getBlobDuration(blob)
      //   .then(function (duration) {
      //     that.setState({ duration: duration, isProcessing: false });
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });

      this.setState({ audioUrl: audioUrl });

      if (iosCheck == false) {
        this.state.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }
  }

  retakeVideo(e) {
    e.preventDefault();
    this.setState({
      showRecording: true,
      duration: "",
      recordingMiliSeconds: 0,
      recordingHours: 0,
      recordingMinutes: 0,
      recordingSeconds: 0,
      numberRecordings: "",
      current_time_recording: "",
      newRecording: false,
      isProcessing: false,
      record_click: 'retaik'
    });
    this.setState({ seconds: 4 });
    this.setState({ timer: 0 });
    $("video").trigger("pause");

    if (watch.isRunning()) {
      watch.stop();
      watch.reset();
    }

    if (this.state.typecreate == 0) {
      // this.getCameraStream();
    } else {
      this.getAudioStream();
    }
  }

  saveToServer = (event) => {
    if (this.state.numberRecordings == "") {
      toast("Please record your answer to submit successfully.", {
        position: "top-right",
        autoClose: 3000,
        type: "warning",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      return;
    }

    if (this.state.duration != "") {
      this.setState({ loaded: false });
      this.setState({ submitFlag: true });
      let formData = new FormData();
      formData.append("video", this.state.recordedVideo);
      formData.append("question_id", this.state.question);
      formData.append("language", this.state.data.language.id);
      formData.append("duration", this.state.duration);
      formData.append("size", this.state.recordedVideo.size);
      formData.append("view_id", this.state.viewId);
      if (this.state.data.answer && this.state.data.answer.id) {
        formData.append("id", this.state.data.answer.id);
      }

      formData.append("mode", "edit");
      if (this.state.typecreate == 0) {
        formData.append("type", "video");
      } else {
        formData.append("type", "audio");
      }

      formData.append("screen_mode", this.state.screen_mode);

      //this.props.updateAnswer(this.state.category, this.state.subcategory, this.state.question, formData, this.props.auth.loginUserToken, (res) => {
      this.props.saveCreateQuestion(
        formData,
        this.props.auth.loginUserToken,
        (res) => {
          this.setState({ videoUrl: "" });
          this.setState({ loaded: true });
          this.setState({ submitFlag: false, isSubmitted: true });
          this.setState({ seconds: 4 });
          this.setState({ timer: 0, showRecording: false });
          if (res.data && res.data.msg) {
            toast("Answer saved.", {
              position: "top-right",
              autoClose: 3000,
              type: "success",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
            });

            this.props.history.replace(
              `/${this.baseName}/create/congratulations/${this.state.category}+${
                this.state.subcategory
              }+${this.state.typecreate == 0 ? "video" : "audio"}+view-${
                this.state.viewId
              }`
            );
          }
        }
      );
    } else {
      toast(
        "Firstly, Please ensure that the video/audio is playing back properly or try creating a new one.",
        {
          position: "top-right",
          autoClose: 3000,
          type: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
    }

    event.preventDefault();
  };

  skipQuestion = (e) => {
    this.setState({ loaded: false, showRecording: false });
    this.props.skipCreateQuestion(
      {
        question_id: this.state.data.id,
        type: this.state.type,
        view_id: this.state.viewId,
      },
      this.props.auth.loginUserToken,
      (res) => {
        this.setState({ loaded: true });
        if (this.state.completedata.next != null) {
          this.setState({ seconds: 4 });
          this.setState({ timer: 0, duration: "" });
          this.getQuestion(
            this.state.category,
            this.state.subcategory,
            "",
            this.state.type,
            this.state.viewId
          );
        } else if (this.state.completedata.next == null) {
          if (
            this.state.completedata.current_module ==
            this.state.completedata.total_sub_module
          ) {
            this.setState({ transition: "yes" });
            this.getQuestion(
              this.state.category,
              this.state.subcategory,
              "",
              this.state.type,
              this.state.viewId
            );
          } else {
            this.getNextModule();
          }
        }
      }
    );

    e.preventDefault();
  };

  takeBreak = (e) => {
    e.preventDefault();
    this.props.history.goBack();
    // this.props.history.replace('/home/create/');
  };

  formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds}`;
  };

  handlePause = () => {
    clearInterval(this.countRef.current);
    this.setState({ isPaused: false });
  };

  handleReset = () => {
    clearInterval(this.countRef.current);
    this.setState({ isActive: false, isPaused: false });
    this.setState({ timer: 0 });
  };

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ total_time: false });
  };

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  redirectToDemo = () => {
    window.open("/#eterna_work", "_blank").focus();
  };

  goToFaq = (e) => {
    e.preventDefault();

    this.props.history.push(`/${this.baseName}/faq/`);
  };
  goToBlog = (e) => {
    e.preventDefault();

    this.props.history.push(`/${this.baseName}/blog/`);
  };

  render() {
    const {
      recording,
      recordingAfter,
      completedata,
      data,
      videoUrl,
      audioUrl,
      numberRecordings,
      seconds,
      loaded,
      moduleQuestions,
      newRecording,
    } = this.state;
    // this.cumulativeTime=this.state.showRecording && this.state.duration == '' ? this.format(this.state.recordingHours)+':'+this.format(this.state.recordingMinutes)+':'+this.format(this.state.recordingSeconds) : (this.state.duration != '' ? new Date(this.state.duration * 1000).toISOString().substr(11, 8) : (this.state.current_time_recording != '' && 'undefined' !== typeof this.state.current_time_recording ? (new Date(this.state.current_time_recording * 1000).toISOString()).substr(11, 8) : ((new Date(this.state.duration * 1000).toISOString()).substr(11, 8))));
    return (
      <div>
        <HeaderComponent
          value={this.state.numberRecordings}
          saveToServer={this.saveToServer}
          skipQuestion={this.skipQuestion}
          props={this.props}
        />
        <section className="practing_outer record2 recored3 paid5 recording1 hide_hand crey78 edit_screen">
          <Loader loaded={loaded}></Loader>
          <div className="container">
            <div className="row">
              <div className="col-ld-10 col-md-10 offset-lg-1 offset-md-1">
                <div className="middle_outer56 text5627 full_intr45">
                  <div className="modal" id="myBreakModal">
                    <div className="modal-dialog custom_dioalag">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h4 className="modal-title">
                            Before moving back, do you wish to submit the
                            recording you just made?
                          </h4>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>

                        <div className="modal-body">
                          <div className="btn_inner">
                            <ul>
                              <li>
                                <button
                                  className="btn custom_yes"
                                  onClick={(e) => this.saveToServer(e)}
                                  data-dismiss="modal"
                                >
                                  Yes, submit!
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn custom_no"
                                  onClick={(e) => this.takeBreak(e)}
                                  data-dismiss="modal"
                                >
                                  No, just move back!
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="myModal201"
                    className={
                      this.state.total_time == true
                        ? "modal fade show custom_modal"
                        : "modal fade show show_custom_modal"
                    }
                    role="dialog"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close custom_close3"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>
                            Please note that you have consumed maximum recording
                            time limit and left with only 1 hour.
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" onClick={this.closePopUp}>
                            Okay!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    id="myModal201"
                    className={
                      this.state.grace_total_time == true
                        ? "modal fade show custom_modal"
                        : "modal fade show show_custom_modal"
                    }
                    role="dialog"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button
                            type="button"
                            className="close custom_close3"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="modal-body">
                          <p>
                            Please note that you have consumed maximum recording
                            time limit along with additional time given as grace
                            allotment and left with only 1 hour.
                          </p>
                        </div>
                        <div className="modal-footer">
                          <button type="button" onClick={this.closePopUp}>
                            Okay!
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3 className="small_heding69">
                    Module {completedata.current_module}
                    {completedata.total_sub_module} : {data?.sub_category?.name}
                  </h3>

                  <div className="tex_fulll2 row">
                    <div className="col-md-3 ">
                      <p className="question_name">
                        Question {completedata.current_page}
                        {completedata.total_question_count}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <h4>{data?.question}</h4>
                    </div>
                    <div className="col-md-3">{""}</div>
                  </div>

                  {/* <div className="tex_fulll2 question_congratulation_edit">
                    <p className="question_name">
                      {" "}
                      Question {completedata.current_page}
                      {completedata.total_question_count}
                    </p>
                    <h4>{data?.question}</h4>
                  </div> */}

                  {numberRecordings == "" && recordingAfter == false && (
                    <div className="same_height">
                      {!recording && numberRecordings == "" ? (
                        <div className="btn7 custom_t6">
                          <button
                            className="btn"
                            onClick={(e) => this.startRecording(e)}
                          >
                            START Recording
                          </button>
                        </div>
                      ) : (
                        <div className="btn7 custom_t6">
                          <button type="button" className="btn" disabled={true}>
                            START Recording
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  {!recording && numberRecordings != "" && (
                    <div className="btn7 custom_t6">
                      <button className="btn" disabled={numberRecordings != ""}>
                        START Recording
                      </button>
                    </div>
                  )}

                  {recording == true && recordingAfter == true && (
                    <div className="btn7 custom_t6 red_coloe5">
                      <button
                        className="btn"
                        onClick={(e) => this.stopRecording(e)}
                      >
                        STOP Recording
                      </button>
                    </div>
                  )}

                  <div className="img6 audio_recorder custom_rec45">
                    {this.state.typecreate == 0 && (
                      <div>
                        {this.state.typecreate == 0 && recordingAfter && (
                          <div className="recording_gif">
                            <img src={mic} />
                          </div>
                        )}
                        <div className="timer_outer">
                          {seconds == 1 && <img src={timer1} />}
                          {seconds == 2 && <img src={timer2} />}
                          {seconds == 3 && <img src={timer3} />}
                          {seconds == 0 && <img src={start} />}
                        </div>

                        <div className="contain-recording-player">
                          <div className="col-md-6 offset-md-3 col-sm-12 submit_retake_col">
                            <div className="btn_outer99 recor67 new_css">
                              <ul className="edit_page_button">
                                {numberRecordings == "" &&
                                  recording == false && (
                                    <div className="videoCheck">
                                      {isMobileDevice && (
                                        <select
                                          className="changeCamera_select"
                                          value={this.state.videoFacing}
                                          onChange={(e) =>
                                            this.videoOptionsChange(e)
                                          }
                                        >
                                          <option value="user">
                                            Front Camera
                                          </option>
                                          <option value="environment">
                                            Rear Camera
                                          </option>
                                        </select>
                                      )}
                                    </div>
                                  )}
                                {this.state.isProcessing == true && (
                                  <li className="Save_outer4">
                                    <button className="btn-secondary">
                                      Processing
                                      <i className="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}
                                {newRecording &&
                                  this.state.isProcessing == false &&
                                  this.state.submitFlag == false && (
                                    <li className="Save_outer4">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.saveToServer(e)}
                                      >
                                        Submit
                                      </button>
                                    </li>
                                  )}

                                {this.state.submitFlag == true && (
                                  <li className="Save_outer4">
                                    <button className="btn">
                                      Submitting...
                                      <i className="fas fa-spinner fa-spin"></i>
                                    </button>
                                  </li>
                                )}

                                {this.state.isSubmitted == false &&
                                  numberRecordings != "" && (
                                    <li className="cancel">
                                      <button
                                        className="btn"
                                        onClick={(e) => this.retakeVideo(e)}
                                      >
                                        Retake
                                      </button>
                                    </li>
                                  )}
                              </ul>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.typecreate == 0 &&
                              numberRecordings == "2"
                                ? ""
                                : "congratulation-video-hide"
                            }
                          >
                            <div data-vjs-player className="edit-video-clas">
                              <video
                                id="myVideo"
                                ref={(node) => (this.videoNode = node)}
                                className="video-js vjs-default-skin custom_video_js edit-videoframe"
                                options={videoJsOptions}
                                playsInline
                                preload="metadata"
                              ></video>
                            </div>
                          </div>
                          {this.state.typecreate == 0 &&
                            numberRecordings == "" && (
                              <div
                                className={
                                  isMobile &&
                                  this.state.screen_mode === "portrait"
                                    ? ""
                                    : "create_VideoFrame"
                                }
                              >
                                <video
                                  style={{
                                    objectFit: "fill",
                                    width: 900,
                                    height: 320,
                                  }}
                                  ref={(v) => {
                                    this.video = v;
                                  }}
                                  playsInline
                                >
                                  Video stream not available.
                                </video>
                              </div>
                            )}

                          {this.state.typecreate == 0 &&
                            numberRecordings != "2" &&
                            numberRecordings != "" && (
                              <video
                                id="video_player"
                                style={{
                                  objectFit: "fill",
                                  width: 900,
                                  height: 320,
                                }}
                                src={videoUrl}
                                controls
                                playsInline
                              ></video>
                            )}
                        </div>


                        {/* {numberRecordings == "" && recording == false && (
                          <div className="videoCheck">
                            {isMobileDevice && (
                              <select
                                className="changeCamera_select"
                                value={this.state.videoFacing}
                                onChange={(e) => this.videoOptionsChange(e)}
                              >
                                <option value="user">Front Camera</option>
                                <option value="environment">Rear Camera</option>
                              </select>
                            )}
                          </div>
                        )} */}

                        {/* <div
                          className={
                            this.state.typecreate == 0 &&
                            numberRecordings == "2"
                              ? ""
                              : "congratulation-video-hide"
                          }
                        >
                          <div data-vjs-player className="edit-video-clas">
                            <video
                              id="myVideo"
                              ref={(node) => (this.videoNode = node)}
                              className="video-js vjs-default-skin custom_video_js edit-videoframe"
                              options={videoJsOptions}
                              playsInline
                            ></video>
                          </div>
                        </div>

                        {this.state.typecreate == 0 &&
                          numberRecordings == "" && (
                            <video
                              style={{objectFit:"fill", width: 900, height: 320 }}
                              ref={(v) => {
                                this.video = v;
                              }}
                              playsInline
                            >
                              Video stream not available.
                            </video>
                          )}

                        {this.state.typecreate == 0 &&
                          numberRecordings != "2" &&
                          numberRecordings != "" && (
                            <video
                              id="video_player"
                              style={{objectFit:"fill", width: 900, height: 320 }}
                              src={videoUrl}
                              controls
                              playsInline
                            ></video>
                          )} */}

                        {/* {(this.state.typecreate == 0 && numberRecordings != '') && <video id="video_player" style={{ width: 900, height: 320 }} ref={v => {
                                                this.videocreated = v;
                                            }} src={videoUrl} controls ></video>} */}
                      </div>
                    )} 

                    {this.state.typecreate == 1 && (
                      <div className="audio_div_box">
                        {localStorage.getItem("typecreate") == 1 &&
                          recordingAfter && (
                            <div className="recording_audio_gif">
                              <img src={mic} />
                            </div>
                          )}

                        {this.state.audio ? (
                          <AudioAnalyser audio={this.state.audio} />
                        ) : (
                          ""
                        )}
                        {/* {this.state.flagFrame == false && <img src={video_recording} />} */}

                        <div className="timer_outer">
                          {seconds == 1 && <img src={timer1} />}
                          {seconds == 2 && <img src={timer2} />}
                          {seconds == 3 && <img src={timer3} />}
                          {seconds == 0 && <img src={start} />}
                        </div>

                        {this.state.typecreate == 1 &&
                          numberRecordings == "" && (
                            <audio
                              ref={(a) => {
                                this.audio = a;
                              }}
                            >
                              <p>Audio stream not available. </p>
                            </audio>
                          )}

                        {this.state.typecreate == 1 &&
                          numberRecordings != "" && (
                            <audio
                              controls
                              src={audioUrl}
                              controlsList="nodownload"
                            />
                          )}
                      </div>
                    )}

                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-6">
                        <div className="text_nam546">
                          <p>
                            Recording Time of This Question:
                            {this.state.showRecording &&
                            this.state.duration == ""
                              ? this.format(this.state.recordingHours) +
                                ":" +
                                this.format(this.state.recordingMinutes) +
                                ":" +
                                this.format(this.state.recordingSeconds)
                              : this.state.duration != ""
                              ? new Date(this.state.duration * 1000)
                                  .toISOString()
                                  .substr(11, 8)
                              : this.state.current_time_recording != "" &&
                                "undefined" !==
                                  typeof this.state.current_time_recording
                              ? new Date(
                                  this.state.current_time_recording * 1000
                                )
                                  .toISOString()
                                  .substr(11, 8)
                              : new Date(this.state.duration * 1000)
                                  .toISOString()
                                  .substr(11, 8)}
                            <br />
                            {/* Cumulative Recording Time: {completedata.total_time_recording ? completedata.total_time_recording : '00:00:00'}</p> */}
                            Cumulative Recording Time:
                            {this.state.cumulativeTime}{" "}
                          </p>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-6">
                        <div className="btn_outer99 recor67">
                          <ul className="edit_page_button">
                            {this.state.isProcessing == true && (
                              <li className="Save_outer4">
                                <button className="btn-secondary">
                                  Processing
                                  <i className="fas fa-spinner fa-spin"></i>
                                </button>
                              </li>
                            )}
                            {newRecording &&
                              this.state.isProcessing == false &&
                              this.state.submitFlag == false && (
                                <li className="Save_outer4">
                                  <button
                                    className="btn"
                                    onClick={(e) => this.saveToServer(e)}
                                  >
                                    Submit
                                  </button>
                                </li>
                              )}
                            {this.state.submitFlag == true && (
                              <li className="Save_outer4">
                                <button className="btn">
                                  Submitting...
                                  <i className="fas fa-spinner fa-spin"></i>
                                </button>
                              </li>
                            )}

                            {this.state.isSubmitted == false &&
                              numberRecordings != "" && (
                                <li className="cancel">
                                  <button
                                    className="btn"
                                    onClick={(e) => this.retakeVideo(e)}
                                  >
                                    Retake
                                  </button>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div> */}
                    </div>

                    {(numberRecordings == "" || numberRecordings == "2") && (
                      <div
                        className="take_break"
                        onClick={(e) => this.takeBreak(e)}
                      >
                        <img src={keepasis} />
                      </div>
                    )}
                    {numberRecordings != "" && numberRecordings != "2" && (
                      <div
                        className="take_break"
                        data-toggle="modal"
                        data-target="#myBreakModal"
                      >
                        <img src={keepasis} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="greand_pop">
          <div className="conatiner">
            <div className="img56">
              {(numberRecordings == "" || numberRecordings == "2") && (
                <a
                  data-toggle="modal"
                  onClick={this.onHandPopClick}
                  data-target="#myModal12"
                >
                  <img src={hand_outer} />
                </a>
              )}

              {numberRecordings != "" && numberRecordings != "2" && (
                <a data-toggle="modal" data-target="#myBreakModal">
                  <img src={hand_outer} />
                </a>
              )}

              <div className="modal" id="myModal12">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                      >
                        &times;
                      </button>
                    </div>
                    <div className="modal-body cu678">
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="box activer4">
                            <h4>Frequently Asked Questions</h4>
                            <div className="few3_outer">
                              <i className="fas fa-circle"></i>
                              <p>Q: What is an Eternaview?</p>
                            </div>
                            <div className="few3_outer no_bo675">
                              <i className="fas fa-circle"></i>
                              <p>
                                Q. Is the response real or some sort of deepfake
                                or other artificially generated response?
                              </p>
                            </div>
                            <div className="few3_outer no_bo675">
                              <i className="fas fa-circle"></i>
                              <p>
                                Q: Why do I need Eternaview for creating an
                                interview versus other options?
                              </p>
                            </div>
                            <div className="few3_outer no_bo675">
                              <i className="fas fa-circle"></i>
                              <p>
                                Q: What types of Eternaviews can be created?
                              </p>
                            </div>
                            <div className="few3_outer no_bo675">
                              <i className="fas fa-circle"></i>
                              <p>
                                Q. Can I see the interview questions before I
                                subscribe?
                              </p>
                            </div>
                            <div className="btn78">
                              <a
                                href=""
                                data-dismiss="modal"
                                onClick={this.goToFaq}
                              >
                                View all
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="box">
                            <h4>Scan Modules & Questions in Template</h4>
                            <div className="custtom_according template_cus_accordian">
                              <div className="bs-example">
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  {moduleQuestions?.map((subcat, ind) => {
                                    return (
                                      <div className="card">
                                        <div
                                          className="card-header"
                                          id={"headingOne" + ind}
                                        >
                                          <h2 className="mb-0">
                                            <button
                                              type="button"
                                              className="btn btn-link"
                                              data-toggle="collapse"
                                              data-target={"#collapseOne" + ind}
                                            >
                                              {subcat.subcategory_name}
                                              <i className="fa fa-angle-down"></i>{" "}
                                            </button>
                                          </h2>
                                        </div>
                                        <div
                                          id={"collapseOne" + ind}
                                          className="collapse"
                                          aria-labelledby={"headingOne" + ind}
                                          data-parent="#accordionExample"
                                        >
                                          <div className="card-body cus56">
                                            {subcat.questions.map(
                                              (ques, index) => {
                                                return <p>{ques.question} </p>;
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <div className="box ">
                            <h4>Demo Video</h4>
                            <div className="img6_outer create-demo-video-body">
                              <video
                                style={{ height: "100%" }}
                                id="a"
                                ref={(node) => (this.video7Node = node)}
                                className="video-js vjs-default-skin custom_video_js"
                                options={videoJsOptions7}
                                playsInline
                              ></video>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="box vill98">
                            <h4>Blog Posts</h4>
                            {this.state.blogs &&
                              this.state.blogs.length > 0 && (
                                <div className="blog_box1">
                                  <img src={this.state.blogs[0].image} />
                                  <div className="texcd4">
                                    <p>{this.state.blogs[0].title}</p>
                                    <span>
                                      <img src={arrow_right} />
                                    </span>
                                  </div>
                                </div>
                              )}
                            <div className="btn78">
                              <a
                                href=""
                                data-dismiss="modal"
                                onClick={this.goToBlog}
                              >
                                View all
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: "flex",
    overflow: "auto",
  },
  child: {
    flex: 2,
  },
  videos: {
    flex: 1,
  },
};

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getCreateQuestion: bindActionCreators(getCreateQuestion, dispatch),
  updateAnswer: bindActionCreators(updateAnswer, dispatch),
  getQuestionById: bindActionCreators(getQuestionById, dispatch),
  getEditQuestion: bindActionCreators(getEditQuestion, dispatch),
  saveCreateQuestion: bindActionCreators(saveCreateQuestion, dispatch),
  scanQuestions: bindActionCreators(scanQuestions, dispatch),
  verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
  getParticularQuestionDetail: bindActionCreators(
    getParticularQuestionDetail,
    dispatch
  ),

  blogPosts: bindActionCreators(blogPosts, dispatch),
  getCategoriesOpen: bindActionCreators(getCategoriesOpen, dispatch),
  getSubCategoriesByCatOpen: bindActionCreators(
    getSubCategoriesByCatOpen,
    dispatch
  ),
  scanQuestionsOpen: bindActionCreators(scanQuestionsOpen, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditEternaview);
