import React from "react";
import { Link } from "react-router-dom";
import connection from "../../config/connection";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";
const Page404 = () => {
  const auth = useSelector((state) => state);

  return(
    <div className="text-center">
    <h1 className="display-1 font-weight-bold">404</h1>
    <p className="h1">Page not found.</p>
    <p className="h2 font-weight-normal mt-3 mb-4">
      The page you are looking for might have been removed.
    </p>
    <Link  onClick={()=>{window.location.href = auth.auth.loginUserToken ? connection.homePage + `?access_token=${auth.auth.loginUserToken}`:connection.homePage}}>
      <Button color="primary" size="lg">
        Return to website
      </Button>
    </Link>
  </div>
  );
}


export default Page404;
