import React from "react";
import {
    FRONTEND_ENDPOINT,
    environment,
    FaceBook_Token,
} from "../config/connection";
import ReactPixel from "react-facebook-pixel";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { sharePixelTrack } from "../redux/actions";



    export const callback_fb_px_event = (event_name,auth,dispatch) => {
    if (environment === "production") {
        ReactPixel.init("1499380397556107");
        ReactPixel.track(event_name, {
            value: 100,
            currency: "USD",
        });
        const facebookObj = {
            access_token: FaceBook_Token,
            pixel_id: "1499380397556107",
            event_name: event_name,
            event_time: Math.floor(Date.now() / 1000),
            event_source_url: "https://www.eternaview.com/",
            action_source: "website",
            fbp: Cookies.get("_fbp"),
        };
        if (auth?.auth.loginUserInfo?.email) {
            facebookObj.email = auth.auth.loginUserInfo.email;
        }
        dispatch(sharePixelTrack(facebookObj, (result) => { }));
    }
};

