import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import small_check from "../../Images/small_check.png";
import FAQ2 from "../../Images/FAQ2.png";
import Login from "../../components/Login";
import RegisterComponent from "../../components/Register";
import Thankyou from "../auth/VerifyEmail";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";
import { isMobile,isTablet } from "react-device-detect";
import video_part from "../../Images/video_part.png";
import left_img1 from "../../Images/left_img1.png";
import legend1 from "../../Images/legend1.png";
import legend2 from "../../Images/legend2.png";
import legend3 from "../../Images/legend3.png";
import legend4 from "../../Images/legend4.png";
import video1 from "../../Images/video1.png";
import oldman from "../../Images/oldman.png";
import baby from "../../Images/baby.png";
import video3 from "../../Images/video3.png";
import video2 from "../../Images/video2.png";
import {
  getSubCategoriesByCatOpen,
  getCategoriesOpen,
  getCategories,
  changeLastModal,
  superUserLogin,
  submitEmailNewsletter,
  verifyUserSubscription,
} from "../../redux/actions";
import { Base64 } from "js-base64";
import { toast } from "react-toastify";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import connection from "../../config/connection";
import ReactHlsPlayer from "react-hls-player";
import ReactPlayer from "react-player";

const videoJsOptions1 = {
  loop:true,
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  // sources: [
  //   {
  //     src: "https://d2jgjdx2rckm61.cloudfront.net/27f133ef-d5bf-4996-9be9-bd33487a0fc4/hls/01_30_23GrandchildGrandparentvideowithblackremovedfromfront.m3u8",
  //     type: "application/x-mpegURL",
  //   },
  // ],
};

const videoJsOptions2 = {
  loop:true,
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  // sources: [
  //   {
  //     src: "https://d2jgjdx2rckm61.cloudfront.net/7fdc171a-2c88-4d3b-99a2-1f8dbbabd531/hls/Love Version 09-15-22 With Death Removed.m3u8",
  //     type: "application/x-mpegURL",
  //   },
  // ],
};

const videoJsOptions3 = {
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/45cadbe0-a086-4482-9338-07e23292d82d/hls/Dating Commercial February 10th, 2022.m3u8",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions4 = {
  controls: false,
  bigPlayButton: false,
  muted: true,
  autoplay: true,
  responsive: true,
  fluid: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  sources: [
    {
      // src: 'https://d2jgjdx2rckm61.cloudfront.net/58ff84e6-ddb9-4ac8-91fe-d3f75f018ce8/hls/answer_S2VqmYN.m3u8?height=1080&width=1920',
      src: "https://d2jgjdx2rckm61.cloudfront.net/fab193a5-7f35-4e12-9d4c-d00045099cb4/hls/answer_osWYxJM.m3u8?height=1080&width=1920",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions5 = {
  controls: false,
  bigPlayButton: false,
  muted: true,
  autoplay: true,
  responsive: true,
  fluid: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  sources: [
    {
      src: "https://d2jgjdx2rckm61.cloudfront.net/3b762a32-e55b-4e37-bfb9-ea432ab1ffb9/hls/answer_N9QLp3B.m3u8?height=1080&width=1920",
      type: "application/x-mpegURL",
    },
  ],
};

const videoJsOptions6 = {
  loop:true,
  controls: true,
  bigPlayButton: true,
  autoplay: false,
  responsive: true,
  aspectRatio: "16:9",
  width: "auto",
  height: "auto",
  fluid: true,
  // sources: [
  //   {
  //     src: "https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo%20tutorial%20for%20Eternaview.m3u8",
  //     type: "application/x-mpegURL",
  //   },
  // ],
};

class NewDefault extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verifyPlanDetail: {},
      category: "0",
      subcategory: "0",
      typecreate: "0",
      categories: [],
      loginRedirectUrl: "",
      isSignUpOpen: false,
      isThankyouOpen: false,
      loginModelOpenStatus: false,
      isFaqOpen: false,
      firstTimePop: false,
      base64regex:
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/,
      email: "",
    };
    this.onInputchange = this.onInputchange.bind(this);
  }

  // After Login

  onCreateEternView = () => {
    this.nextCreate();
  };

  verifyUserSubscription = (cb) => {
    this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
      if (res.is_active == false) {
        localStorage.clear();
        window.location.href = this.props.auth.loginUserToken ? connection.homePage + `?access_token=${this.props.auth.loginUserToken}` :  connection.homePage
        // this.props.history.replace("/");
      } else {
        if (res.data) {
          this.setState({ verifyPlanDetail: res.data });
          if (cb) {
            cb(res.data);
          }
        }
      }
    });
  };

  nextCreate = (param) => {
    let catName = "Legacy";
    let redirection = false;
    let viewId = "";

    if (this.state.category != "0" && this.state.subcategory != "0") {
      localStorage.setItem("typecreate", this.state.typecreate);
      let typecreate = this.state.typecreate == 0 ? "video_audio" : "audio";
      if (this.state.verifyPlanDetail.length > 0 || param?.length > 0) {
        this.state.verifyPlanDetail.map((mod, ind) => {
          if (this.state.category == mod.subscribe_plan__category__id) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate &&
                mod.updated_details.category == this.state.category
              ) {
                redirection = true;
                viewId = mod.views;
                return;
              }
            } else {
              alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              );
            }
          } else if (mod.subscribe_plan__category__id == 9999) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate &&
                mod.updated_details.category == this.state.category
              ) {
                redirection = true;
                viewId = mod.views;
                return;
              }
            } else {
              alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              );
            }
          }
        });
      }

      if (redirection == true) {
        this.props.history.push(
          `/create/eternaview/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}+${viewId}`
        );
      } else {
            sessionStorage.setItem("giftType",1)                     //Git type 1 is for if user is going to buy plan for them selves and 2 is for if they are going to buy plan for someone else
            this.props.history.push('/home/pricing-plans/')
        // this.props.history.push({
        //   pathname: `/home/plans/${this.state.category}+${this.state.subcategory}+${this.state.typecreate}`,
        //   state: { catName },
        // });
      }
    } else {
      if (this.state.nextbtn == true) {
        toast(
          "Please make selection of category and move forward to create eternaview.",
          {
            position: "top-right",
            autoClose: 3000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );
      } else {
        toast("Please select category to move further.", {
          position: "top-right",
          autoClose: 3000,
          type: "error",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    }
  };

  getCategories = () => {
    this.props.getCategories(
      { token: this.props.auth.loginUserToken },
      (res) => {
        if (res.data && res.data.length > 0) {
          this.setState({ categories: res.data });
        }
      }
    );
  };

  loadSelectChange = (item) => {
    this.setState({ typecreate: 0 }); // To set video default
    this.setState({ ["category"]: item.id });
    this.props.getSubCategoriesByCatOpen(item.id, (res) => {
      this.setState({
        subcategories: res.data.sub_categories,
        catName: res.data.name,
      });
      this.setState({ subcategory: res.data.sub_categories[0].id });
    });
  };

  getSubCategories = () => {
    this.props.getCategoriesOpen((res) => {
      if (res.data && res.data.length > 0) {
        this.setState({ categories: res.data });
        const lagecy_data = res.data.filter((item, i) => item.name == "Legacy");
        this.loadSelectChange(lagecy_data[0]);
      }
    });
  };

  componentDidMount() {
    if (this.props.auth.loginUserToken != "") {
      this.verifyUserSubscription();
    }
    this.getCategories();
    this.getSubCategories();
    if (this.props.auth.moduleCheck.is_complete == false) {
      this.setState({ firstTimePop: true });
    }

    if (this.state.base64regex.test(this.props.match.params.id)) {
      this.props.match.params.id = Base64.decode(this.props.match.params.id);
      let splitToken = this.props.match.params.id.split("&");
      let newToken = splitToken[0].split("=");
      localStorage.setItem("super", newToken[1]);
      this.props.superUserLogin(newToken[1]);
    }

    this.player1 = videojs(
      this.video1Node,
      videoJsOptions1,
      function onPlayerReady() {
        //Update Src for Commercial 1
        this.src({
          src: "https://d2jgjdx2rckm61.cloudfront.net/27f133ef-d5bf-4996-9be9-bd33487a0fc4/hls/01_30_23GrandchildGrandparentvideowithblackremovedfromfront.m3u8",
          type: "application/x-mpegURL",
        })
      }
    );

    this.player1.on("deviceReady", () => {
      console.log("device is ready!");
    });
     // Handle error faced while playing video for Commercial 1
    this.player1.on('error', () => {
      console.log('Media load error');
      // You can add your logic here to attempt a reload
      // For example:
      this.reset(); // Reset the player
      this.src({
        src: "https://d2jgjdx2rckm61.cloudfront.net/27f133ef-d5bf-4996-9be9-bd33487a0fc4/hls/01_30_23GrandchildGrandparentvideowithblackremovedfromfront.m3u8",
        type: "application/x-mpegURL",
      })
      this.play(); // Start playing
    });

    this.player2 = videojs(
      this.video2Node,
      videoJsOptions2,
      function onPlayerReady() {
        //Update Src for Commercial 2
        this.src({
          src: "https://d2jgjdx2rckm61.cloudfront.net/7fdc171a-2c88-4d3b-99a2-1f8dbbabd531/hls/Love Version 09-15-22 With Death Removed.m3u8",
          type: "application/x-mpegURL",
        })
        
        console.log("onPlayerReady", this);
      }
    );

    this.player2.on("deviceReady", () => {
      console.log("device is ready!");
    });
    // Handle error faced while playing video for Commercial 2
    this.player2.on('error', () => {
      console.log('Media load error');
      // You can add your logic here to attempt a reload
      // For example:
      this.reset(); // Reset the player
      this.src({
        src: "https://d2jgjdx2rckm61.cloudfront.net/7fdc171a-2c88-4d3b-99a2-1f8dbbabd531/hls/Love Version 09-15-22 With Death Removed.m3u8",
        type: "application/x-mpegURL",
      })
      this.play(); // Start playing
    });
    this.player3 = videojs(
      this.video3Node,
      videoJsOptions3,
      function onPlayerReady() {
        console.log("onPlayerReady", this);
      }
    );

    this.player3.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player3.on('error', () => {
      console.log('Media load error');
      // You can add your logic here to attempt a reload
      // For example:
      this.reset(); // Reset the player
      this.src({
        src: "https://d2jgjdx2rckm61.cloudfront.net/45cadbe0-a086-4482-9338-07e23292d82d/hls/Dating Commercial February 10th, 2022.m3u8",
        type: "application/x-mpegURL",
      })
      this.play(); // Start playing
    });

    this.player4 = videojs(
      this.video4Node,
      videoJsOptions4,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player4.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player4.on('error', () => {
      console.log('Media load error');
      // You can add your logic here to attempt a reload
      // For example:
      this.reset(); // Reset the player
      this.src({
        src: "https://d2jgjdx2rckm61.cloudfront.net/fab193a5-7f35-4e12-9d4c-d00045099cb4/hls/answer_osWYxJM.m3u8?height=1080&width=1920",
        type: "application/x-mpegURL",
      })
      this.play(); // Start playing
    });

    this.player5 = videojs(
      this.video5Node,
      videoJsOptions5,
      function onPlayerReady() {
        // print version information at startup
        console.log("onPlayerReady", this);
      }
    );

    // device is ready
    this.player5.on("deviceReady", () => {
      console.log("device is ready!");
    });

    this.player5.on('error', () => {
      console.log('Media load error');
      // You can add your logic here to attempt a reload
      // For example:
      this.reset(); // Reset the player
      this.src({
        src: "https://d2jgjdx2rckm61.cloudfront.net/3b762a32-e55b-4e37-bfb9-ea432ab1ffb9/hls/answer_N9QLp3B.m3u8?height=1080&width=1920",
        type: "application/x-mpegURL",
      })
      this.play(); // Start playing
    });

    this.player6 = videojs(
      this.video6Node,
      videoJsOptions6,
      function onPlayerReady() {
        //Update Src for Demo video
        this.src( {
          src: "https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo%20tutorial%20for%20Eternaview.m3u8",
          type: "application/x-mpegURL",
        })
      }
    );
  // Handle error faced while playing video Demo video
  this.player6.on('error', () => {
    // You can add your logic here to attempt a reload
    this.reset(); // Reset the player
    this.src( {
      src: "https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo%20tutorial%20for%20Eternaview.m3u8",
      type: "application/x-mpegURL",
    })
    this.play(); // Start playing
  });
   

    // device is ready
    this.player6.on("deviceReady", () => {
      console.log("device is ready!");
    });
  }
  checkSection = (e) => {
    if(e)
    e.preventDefault();

    if (this.props.auth.loginUserToken == "") {
       //   history.goBack();
       toast('Please log in first in order to create an Eternaview', {
          position: "top-right",
          autoClose: 3000,
          type: 'error',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
       });
       return;
    } else {
       this.props.history.push('/create/practice-test/');
    }
 }

  openLoginPopUp = () => {
    this.setState({ isThankyouOpen: false });
    this.setState({ loginModelOpenStatus: true });
    this.setState({ isSignUpOpen: false });
  };

  isLoggedIn = (redirectUrl) => {
    sessionStorage.setItem("giftType",redirectUrl)   
    this.props.history.push('home/pricing-plans/')
    // Uncomment if client as for login pop ups
    // this.setState({ loginRedirectUrl: redirectUrl });

    // this.setState({ loginModelOpenStatus: true });
  };
  openSignupPopUp = (ret) => {
    // $('#myLoginPopup').hide();
    this.setState({ isSignUpOpen: true });
    this.setState({ loginModelOpenStatus: false });
  };

  closeLoginPopup = (ret) => {
    if (this.state.loginRedirectUrl == "create") {
      this.setState({ loginModelOpenStatus: false });
      this.verifyUserSubscription(this.nextCreate);
    }
    else if(this.state.loginRedirectUrl=='practice'){
        this.checkSection();
    }
    else {
      window.location.reload();
    }
  };

  closeSignupPopup = (ret) => {
    this.setState({ isSignUpOpen: false, isThankyouOpen: true });
  };

  closePopUp = (e) => {
    e.preventDefault();

    this.setState({ firstTimePop: false });
  };

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  submitNewletterEmail = (e) => {
    e.preventDefault();

    this.props.submitEmailNewsletter(
      { email: this.state.email },
      this.props.auth.loginUserToken ? this.props.auth.loginUserToken : "",
      (result) => {
        if (result.id) {
          toast("Your email has been submitted successfully.", {
            position: "top-right",
            autoClose: 3000,
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          this.setState({ email: "" });
        } else {
          toast(result.email[0], {
            position: "top-right",
            autoClose: 3000,
            type: "error",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });
          return false;
        }
      }
    );
  };

  componentWillUnmount() {
    this.props.changeLastModal({});
  }

  render() {
    return (
      <div class="legacy-landing-page">
        <section className="eterna_outer_new home-page-banner-heading">
          <div className={ (isMobile||isTablet)?"container default_page_container":"container"}>
            <div className="new_right right_text54 left_ou6">
            <h5>
              <span style={{fontWeight:700,margin:"20px 30px;",fontSize:30}}>   Looking for a better way to preserve and share your life story? </span><br/>
            </h5>
            </div>
            <div className="new_right right_text54 left_ou6">
              <h5 ref={node=>{
                if(node){
                  node.style.setProperty(
                    "font-size",
                    `25px`,
                    "important"
                  );
                }
              }}> 
                <span>Eternaview</span>
                {" "} lets you record a video interview of yourself and then invite viewers to{" "} 
                 {/* is an innovative technology that lets
                you record a video interview of yourself on any device then
                invite viewers to{" "} */}
                <span className="talk_name">
                  <i>talk</i>
                </span>{" "}
                to that recording.{" "}
              </h5>
            </div>
            <div style={{ marginTop: 30, marginBottom: 30 }} className="row btn7">
            <div className="col text-center">
                  <button
                    style={{ cursor: "pointer",fontSize:18 }}
                    className="login-btn-h signup-h col-sm-5 login-button btn new_login_button"
                    onClick={() => this.props.history.push('home/interact-one/935/455')}
                  >
                  Talk to Grandpa Tim’s Eternaview!
                  </button>
                </div>
              {/* {this.props.auth.loginUserToken != "" && (
                <div className="col text-center">
                  <button
                    style={{ cursor: "pointer" }}
                    className="login-btn-h signup-h col-sm-5 login-button btn new_login_button"
                    onClick={() => this.onCreateEternView()}
                  >
                   Create my Eternaview!
                  </button>

                  <button
                    style={{ cursor: "pointer" }}
                    className="login-btn-h signup-h col-sm-5 login-button btn new_login_button"
                    onClick={this.checkSection}
                  >
                  Practice for Free!
                  </button>
                </div>
              )}

              {this.props.auth.loginUserToken == "" && (
                <div className="col text-center"> 

                <button
                  style={{ cursor: "pointer" }}
                  className="login-btn-h signup-h col-sm-5 login-button btn new_login_button"
                  onClick={() => this.isLoggedIn("create")}
                >
                 Create my Eternaview!
                </button>
                <button
                    style={{ cursor: "pointer" }}
                    className="login-btn-h signup-h col-sm-5 login-button btn new_login_button"
                    onClick={() => this.isLoggedIn("practice")}
                  >
                   Practice for Free!
                  </button>
                </div>
         
              )} */}
            </div>
          </div>
        </section>
        <section className="enterlenge_outer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main_outer1">
                  <div className="entra_outer ">
                    <h3>
                      Eternaview <i>Legacy</i>
                    </h3>
                  </div>
                  <div
                    onClick={() => {
                      this.setState({ isFaqOpen: true });
                    }}
                    className="faq-landingPage-button"
                  >
                    <img className="faq-button" src={FAQ2} />
                  </div>
                  <div className="border_white0 eternaview-legacy-outer">
                    <div className="img_ouyter clickTalk">
                      <div className="clickTalkinner">
                        {/* <img src="/static/media/left_img1.dd22c4b1.png" /> */}
                        <Link to={`/home/interact-one/935/455`}>
                          <video
                            preload="metadata"
                            id="myVideo4"
                            ref={(node) => (this.video4Node = node)}
                            className="video-js vjs-default-skin custom_video_js"
                            options={videoJsOptions4}
                            playsInline
                            loop
                          ></video>
                        </Link>
                        <Link
                          to={`/home/interact-one/935/455`}
                          className="clickTalka"
                        >
                          <i className="far fa-microphone"></i>
                          <p className="blink">Click to talk to this Eternaview now!</p>
                        </Link>
                      </div>
                    </div>
                    <h4>
                      Talk to Loved Ones...
                      <span>
                        <i>Forever!</i>
                      </span>
                    </h4>
                    <div className="cilk_outer custom_circel new_custom_circles">
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Structured interview with easy recording on virtually
                          any webcam or camera.
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Immersive and personalized interaction for the viewer.
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Send invites to family and friends or share on social
                          media.
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Set to Private or Public (it's Private by default).
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Convenient long-term storage in the Amazon Web
                          Services cloud.
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Creator can also download a (non-interactive) backup
                          for extra peace of mind.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }} className="col-md-6">
                <div className="main_outer1">
                  <div className="entra_outer">
                    <h3>
                      Eternaview <i>Dating</i>
                    </h3>
                  </div>
                  <div className="border_white0">
                    <div className="img_ouyter clickTalk">
                      <div className="clickTalkinner">
                        {/* <img src="/static/media/left_img1.dd22c4b1.png" /> */}
                        <Link to={`/home/interact-one/945/456`}>
                          <video
                            preload="metadata"
                            id="myVideo4"
                            ref={(node) => (this.video5Node = node)}
                            className="video-js vjs-default-skin custom_video_js"
                            options={videoJsOptions5}
                            playsInline
                            loop
                          ></video>
                        </Link>
                        <Link
                          to={`/home/interact-one/945/456`}
                          className="clickTalka"
                        >
                          <i className="far fa-microphone"></i>
                          <p>Click to talk to this Eternaview now!</p>
                        </Link>
                      </div>
                    </div>
                    <h4>
                      Find Love...
                      <span>
                        <i>in a low-pressure way!</i>
                      </span>
                    </h4>
                    <div className="cilk_outer custom_circel">
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Gain attention of a potential love interest.</p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Share on dating apps or social media.</p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>
                          Set to Private or Public (it's Private by default).
                        </p>
                      </div>
                      <div className="inner_div6">
                        <img src={small_check} />
                        <p>Can be done as a Video or Audio -only.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sky_outer">
              <h4>
                Yes, we said <b>talk!</b> A viewer can ask a question like they
                would in a normal conversation. Our platform then, through AI,
                retrieves the best answer from
                <br /> the recorded interview.
              </h4>
              {/* <p>It is the perfect way for any creator to let family, friends, the love of their life, or potential love of their life feel a<br /> personal connection... even when not actually speaking in person.</p> */}
            </div>
          </div>
        </section>
        <section className="inter_outer" id="eterna_work">
          <div className="container">
            <div className="custom_heading6">
              <h4>
                How does <span>Eternaview</span> work?
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="img_lefr5 home-demo-video-body">
                  {/* <img src={video_part} /> */}
                  {/*      <ReactHlsPlayer 
                                    style={{height:'100%'}}
                                       className="video-js vjs-default-skin custom_video_js"
    src="https://d2jgjdx2rckm61.cloudfront.net/17f80c85-1f0c-45e4-aa32-ade5179daf3b/hls/Demo tutorial for Eternaview.m3u8"
    autoPlay={false}
    controls={true}
    loop={true}
    width="100%"
  />  */}

                  <video
                    preload="metadata"
                    poster={video3}
                    style={{ height: "auto" }}
                    id="a"
                    ref={(node) => (this.video6Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions6}
                    playsInline
                  ></video>
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="tect56">
                  <div className="middlr5">
                    <b>1.</b>
                    <p>Register and log in.</p>
                  </div>
                  <div className="middlr5">
                    <b>2.</b>
                    <p>Choose a plan.</p>
                  </div>
                  <div className="middlr5">
                    <b>3.</b>
                    <p>
                      Record yourself on your webcam or mobile phone answering
                      our insightful list of interview questions.
                    </p>
                  </div>
                  <div className="middlr5">
                    <b>4.</b>
                    <p>Share your Eternaview for people to interact with it.</p>
                  </div>
                  <div
                    style={{ marginTop: 30, marginBottom: 30 }}
                    className="btn7"
                  >
                    {this.props.auth.loginUserToken != "" && (
                      <button
                        style={{ cursor: "pointer" }}
                        className="login-btn-h signup-h login-button btn new_login_button"
                        onClick={() => this.onCreateEternView()}
                      >
                     Create my Eternaview!
                      </button>
                    )}
                    {this.props.auth.loginUserToken == "" && (
                      <button
                        style={{ cursor: "pointer" }}
                        className="login-btn-h signup-h login-button btn new_login_button"
                        onClick={() => this.isLoggedIn(1)}
                        //    onClick={() => this.isLoggedIn("create")}
                      >
                      Create my Eternaview!
                      </button>
                    )}

                    <button
                        style={{ cursor: "pointer" }}
                        className="login-btn-h signup-h login-button btn new_login_button"
                        onClick={() => this.isLoggedIn(2)}
                    >
                      Gift an Eternaview now
                    </button>
                  </div>
                  {/* <div className="created_inter45">
                 
                    {/* <Link to="/home/create/">Create My Eternaview Now !</Link> 
                  </div> */}
                  <div className="pargrapj">
                    <p>
                      That's all it takes for the people who know you (or want
                      to get to know you better) to visit your Eternaview
                      recording and begin asking you questions... to hear about
                      you in your own words!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="video_points">
          <div className="container">
            <div className="custom_heading6" style={{ display: "none" }}>
              <h4>
                <span>Advantages of Eternaview</span> versus other video options{" "}
              </h4>
            </div>
            <div className="row" style={{ display: "none" }}>
              <div className="col-lg-3 col-md-6">
                <a href="" data-toggle="modal" data-target="#Legacy">
                  <div className="box-heading">
                    <img src={legend1}></img>
                    <h4>Legacy</h4>
                    <p>
                      Be remembered by <br />
                      family and friends!
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="" data-toggle="modal" data-target="#Dating">
                  <div className="box-heading">
                    <img src={legend2}></img>
                    <h4>Dating</h4>
                    <p>
                      A great way to meet that special someone you don't know
                      yet!
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="" data-toggle="modal" data-target="#Audio-only">
                  <div className="box-heading">
                    <img src={legend3}></img>
                    <h4>Audio-only</h4>
                    <p>A great alternative for the camera-shy!</p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6">
                <a href="#">
                  <div className="box-heading">
                    <img src={legend4}></img>
                    <h4>Eternaview Unbound</h4>
                    <p>
                      Whatever your need, let us help you figure out novel ways
                      to use Eternaview.{" "}
                    </p>
                  </div>
                </a>
              </div>
            </div>
            {/* <div className="row demo_video_row">
                            <div className="col-md-4">
                                <div className="vide_tex5 demovideostitle">
                                    <h4>Show your loved ones
                                        what you can do!
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="vide_tex5 demovideostitle">
                                    <h4>We have all led lives
                                        worth remembering!
                                    </h4>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="vide_tex5 demovideostitle">
                                    <h4>Looking for a low-pressure way to
meet someone?
                                    </h4>
                                </div>
                            </div>
                        </div> */}
            <div className="row demo_video_row home-hls-love-videos">
              <div className="col-lg-6 col-md-12">
                <div className="vide_tex5 demovideos">
                  <h4>Show your loved ones what you can do!</h4>
                  <video
                    preload="metadata"
                    id="myVideo1"
                    poster={oldman}
                    ref={(node) => (this.video1Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions1}
                    playsInline
                  ></video>
                  {/* <img src={video1}></img> */}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="vide_tex5 demovideos">
                  <h4>We have all led lives worth remembering!</h4>
                  <video
                    preload="metadata"
                    id="myVideo2"
                    poster={baby}
                    ref={(node) => (this.video2Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions2}
                    playsInline
                  ></video>
                  {/* <img src={video2}></img> */}
                </div>
              </div>
              <div style={{ display: "none" }} className="col-lg-4 col-md-12">
                <div className="vide_tex5 demovideos">
                  <h4>Looking for a low-pressure way to meet someone?</h4>
                  <video
                    preload="metadata"
                    id="myVideo3"
                    ref={(node) => (this.video3Node = node)}
                    className="video-js vjs-default-skin custom_video_js"
                    options={videoJsOptions3}
                    playsInline
                  ></video>
                  {/* <img src={video2}></img> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          id="myLoginPopup"
          style={{
            display:
              this.state.loginModelOpenStatus === true ? "block" : "none",
          }}
          className={
            this.state.loginModelOpenStatus === true
              ? "modal fade show"
              : "modal fade hide"
          }
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <button
                  onClick={() => {
                    this.setState({ loginModelOpenStatus: false });
                  }}
                  type="button"
                  className="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              <div className="modal-body custom_body custom_modal_body">
                <Login
                  customClass={{ modalTop: "modalh4" }}
                  isLoginPopup={true}
                  isSignupPop={this.openSignupPopUp}
                  closeLoginPopup={this.closeLoginPopup}
                  loginRedirectUrl={this.state.loginRedirectUrl}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="mySignupPopup"
          style={{
            display: this.state.isSignUpOpen === true ? "block" : "none",
          }}
          className={
            this.state.isSignUpOpen === true
              ? "modal fade show"
              : "modal fade hide"
          }
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    this.setState({ isSignUpOpen: !this.state.isSignUpOpen });
                  }}
                >
                  &times;
                </button>
              <div className="modal-body custom_body custom_modal_body">
                <RegisterComponent
                  customClass={{ modalTop: "modalh4" }}
                  openLoginPopUp={this.openLoginPopUp}
                  closeSignupPopup={this.closeSignupPopup}
                  signUpRedirectUrl={this.state.loginRedirectUrl}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="thankyouPopup"
          style={{
            display: this.state.isThankyouOpen === true ? "block" : "none",
          }}
          className={
            this.state.isThankyouOpen === true
              ? "modal fade show"
              : "modal fade hide"
          }
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header custom_modal_header">
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    this.setState({
                      isThankyouOpen: !this.state.isThankyouOpen,
                    });
                  }}
                >
                  &times;
                </button>
                <h4 className="modal-title"></h4>
              </div>
              <div className="modal-body custom_body custom_modal_body">
                {
                  this.state.isThankyouOpen === true&&  
                  <Thankyou
                  customClass={true}
                  openLoginPopUp={this.openLoginPopUp}
                />
                }
            
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade custom_modal advantages_popup"
          id="Eternaview"
        >
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Eternaview Unbound</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  Be remembered by <br />
                  family and friends!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade custom_modal advantages_popup"
          id="Audio-only"
        >
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Audio-only</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  <span className="dot"></span>Many of the same benefits of
                  creating a video version of a Legacy or Dating Eternaview.{" "}
                </p>
                <p>
                  <span className="dot"></span>Great if you are shy about how
                  you look on video or cannot easily record a video of yourself.{" "}
                </p>
                <p>
                  <span className="dot"></span>A way for potential dating
                  matches to get to know you before judging based on looks.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade custom_modal advantages_popup" id="Dating">
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Dating</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  <span className="dot"></span>A way for you to highlight
                  yourself to more potential viewers/matches.{" "}
                </p>
                <p>
                  <span className="dot"></span>A way for a viewer to get a
                  better sense of what a potential date with the creator would
                  be like.{" "}
                </p>
                <p>
                  <span className="dot"></span>It is a lower pressure way for a
                  viewer to screen potential matches before going out on a date
                  or even engaging in a two-way video chat.{" "}
                </p>
                <p>
                  <span className="dot"></span>Avoids the need to synchronize
                  schedules.{" "}
                </p>
              </div>
            </div>
          </div>
          {
            // Arun Addition
            <Modal
              style={{ marginTop: "70px", maxWidth: "1000px" }}
              isOpen={this.state.isFaqOpen}
              toggle={() => {
                this.setState({ isFaqOpen: !this.state.isFaqOpen });
              }}
              {...this.props}
            >
              <ModalHeader
                toggle={() => {
                  this.setState({ isFaqOpen: !this.state.isFaqOpen });
                }}
              >
                FAQ
              </ModalHeader>
              <ModalBody>
                <h5 className="faq_font">Q. How does it work? </h5>
                <p className="faq_font">
                  A. To understand how an Eternaview works:
                  <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
                    <li>
                      Click on any sample Eternaview - those are the nodding
                      heads you see. To interact, click on the microphone icon
                      to begin speaking your question and again to stop.
                    </li>
                    <li>Check out the Demo video.</li>
                    <li>Record a Practice Eternaview from the Create tab.</li>
                  </ul>
                </p>

                <h5 className="faq_font">Q. Is this an avatar or deepfake? </h5>
                <p className="faq_font">
                  A. No. So keep in mind that your questions can only be
                  answered with responses the person actually recorded. We
                  utilize AI to enable this interactivity, but it is intended to
                  remain a very human experience.
                </p>
                <h5 className="faq_font">
                  Q. Why is there an ongoing subscription cost, and who will pay
                  for it if I pass away?{" "}
                </h5>
                <p className="faq_font">
                  A. The subscription fee helps cover our ongoing cost of
                  maintaining and improving the service without having to charge
                  a very large upfront fee. While a subscription is needed for
                  an Eternaview to continue to be hosted, there is no charge to
                  viewers and no charge for creators to download their
                  Eternaview on to local devices. Downloads will not be
                  AI-enabled for interactivity but can be played back like any
                  other video.
                </p>

                <h6 className="faq_font">
                  If you pass away, your subscription can be renewed
                  indefinitely by:
                </h6>
                <ul
                  className="faq_font"
                  style={{ listStyleType: "disc", marginLeft: "40px" }}
                >
                  <li>
                    Trusted family members you share your userid and password
                    with, or
                  </li>
                  <li>
                    A Designated Potential Trustee (you will be prompted to
                    provide one after you create your Eternaview), or
                  </li>
                  <li>Anyone you add to your invite list, or </li>
                  <li>Immediate or extended family members/descendants.</li>
                </ul>
              </ModalBody>
              <ModalFooter>
                <Button
                  style={{ color: "white", backgroundColor: "#1dbf73" }}
                  onClick={() => {
                    this.props.history.push("/home/faq/");
                  }}
                >
                  Full FAQ
                </Button>{" "}
              </ModalFooter>
            </Modal>
          }
        </div>
        <div className="modal fade custom_modal advantages_popup" id="Legacy">
          <div className="modal-dialog custom_dioalag">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Legacy</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <p>
                  <span className="dot"></span>A structured interview process
                  for capturing your memories, stories, personality, and
                  talents.{" "}
                </p>
                <p>
                  <span className="dot"></span> It’s a much more immersive and
                  personalized interaction for the viewer.{" "}
                </p>
                <p>
                  <span className="dot"></span>Convenient and very long-term
                  storage of a large video file w/ the ability to control who
                  can access it.{" "}
                </p>
                <p>
                  {" "}
                  <span className="dot"></span>It’s fun to create and then
                  interact with it, along with your family and friends.
                </p>
              </div>
            </div>
          </div>
        </div>

        <section className="etreview_outer">
          <div className="container">
            <div className="cre_outer">
              <h4>
                <span>Love.</span> It is why we created Eternaview.
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <div className="row bg_out6">
                  <div className="col-lg-6 col-md-12">
                    <div className="headiu7">
                      <h4>
                        To Keep Informed of the Exciting Things Happening at
                        Eternaview, <i>Sign Up Here!</i>
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="input_outer">
                      <input
                        type="email"
                        placeholder="alianto@gmail.com"
                        name="email"
                        required
                        value={this.state.email}
                        onChange={this.onInputchange}
                        maxLength="100"
                      ></input>
                      <a href="" onClick={this.submitNewletterEmail}>
                        Email here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getSubCategoriesByCatOpen: bindActionCreators(
    getSubCategoriesByCatOpen,
    dispatch
  ),
  getCategoriesOpen: bindActionCreators(getCategoriesOpen, dispatch),
  getCategories: bindActionCreators(getCategories, dispatch),
  changeLastModal: bindActionCreators(changeLastModal, dispatch),
  superUserLogin: bindActionCreators(superUserLogin, dispatch),
  submitEmailNewsletter: bindActionCreators(submitEmailNewsletter, dispatch),
  verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDefault);
