import React, { useEffect } from "react";
import { redeemlogin } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Circles } from "react-loader-spinner";
import connection from "../../config/connection";
const DefaultToWP = () =>{
    const auth = useSelector((state) => state);
    const getLoginDetails = () =>{
       let token = sessionStorage.getItem("token")
        if(auth.auth.loginUserToken || token){
            token = auth.auth.loginUserToken ? auth.auth.loginUserToken : token
            window.location.href = connection.homePage + `?access_token=${token}`
        }else{
                window.location.href = connection.homePage 
        }
    }


    useEffect(()=>{
getLoginDetails()
    },[])
    return(
        <>
        <Circles wrapperClass="circle-spinner"/>
        <section class="thankyou-outer">
            <div class="container inner-container">
                <div class="row">
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-bottom">
                                {/* <p class="p-text">We are just fetching your details just wait for few seconds</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default DefaultToWP