import React, { useState, useEffect, useRef } from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FRONTEND_ENDPOINT } from "../../config/connection";
import "react-toastify/dist/ReactToastify.css";
import Login from "../../components/Login";
import RegisterComponent from "../../components/Register";
import Thankyou from "../auth/VerifyEmail";
import Loader from "react-loader";
import {
  getSubCategoriesByCatOpen,
  getCategoriesOpen,
  getCategories,
  getPlans,
  getPromoCodes,
  verifyUserSubscription,
  createCheckoutSession,
} from "../../redux/actions/index";
import connection from "../../config/connection";
function NewplanScreen(props) {
  // Login/Sign up Pop up
  const [isModelOpen, setisModelOpen] = useState({
    isLoginOpen: false,
    isSignUpOpen: false,
    isThankyouOpen: false,
  });
  const dispatch = useDispatch();
  const paramvalue = useParams();
  const [clickedPlan, setClickedPlan] = useState();
  const [planVerify, setPlanVerify] = useState("");
  const [plans, setPlans] = useState([]);
  const [promocodes, setPromocodes] = useState([]);
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [planAmount, setPlanAmount] = useState(null);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [subCategories, setSubCategories] = useState({
    subCategories: [],
    catName: "",
  });
  const [loaded, setLoaded] = useState(false);
  const [subCategory, setSubcategory] = useState(0);
  const [typecreates, setTypeCreate] = useState();
  const [paramUrl, setParamUrl] = useState("");
  const [catName, setCatName] = useState("Legacy");
  const [showPlanInfo, setShowPlanInfo] = useState(false);
  const auth = useSelector((state) => state);
  const [planDetai, setPlanDetail] = useState(false);
  useEffect(() => {
    verifyUserSubscriptions();
    getCategorie();
    getSubCategories();
    // dispatch(
    //   verifyUserSubscription(auth.auth.loginUserToken, (res) => {
    //     if (res.data) {
    //       setPlanVerify(res.data.active_status);
    //     }
    //   })
    // );

    dispatch(
      getPromoCodes(auth.auth.loginUserToken, (res) => {
        if (res.data && res.data.length) {
          setPromocodes(res.data);
        }
      })
    );

    dispatch(
      getPlans(
        auth.auth.loginUserToken ? auth.auth.loginUserToken : "",
        (result) => {
          setPlans(result.data);
        }
      )
    );

    setParamUrl(paramvalue.id);
  }, [auth.auth.loginUserToken]);

  const verifyUserSubscriptions = (cb) => {
    if (!auth.auth.loginUserToken) {
      auth.auth.loginUserToken = sessionStorage.getItem("token");
    }
    console.log(auth.auth.loginUserToken);
    dispatch(
      verifyUserSubscription(auth.auth.loginUserToken, (res) => {
        if (res.is_active == false) {
          localStorage.clear();
          window.location.href = auth.auth.loginUserToken ? connection.homePage + `?access_token=${auth.auth.loginUserToken}` :  connection.homePage
          // this.props.history.replace("/");
        } else {
          if (res.data) {
            setPlanVerify(res.data);
            if (cb) {
              cb(res.data);
            }
          }
        }
      })
    );
  };

  const nextCreate = (param) => {
    setLoaded(true);
    let catName = subCategories.catName;
    let redirection = false;
    let viewId = "";

    if (category != "0" && subCategory != "0") {
      localStorage.setItem("typecreate", typecreates);
      let typecreate = typecreates == 0 ? "video_audio" : "audio";
      if (planVerify.length > 0 || param?.length > 0) {
        let tempPlan;
        if (planVerify?.length > 0) {
          tempPlan = planVerify;
        } else if (param?.length > 0) {
          tempPlan = param;
        }
        tempPlan.map((mod, ind) => {
          if (category == mod.subscribe_plan__category__id) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate &&
                mod.updated_details.category == category
              ) {
                redirection = true;
                viewId = mod.views;
                return;
              }
            } else {
              alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              );
            }
          } else if (mod.subscribe_plan__category__id == 9999) {
            if (mod.active_status == "active" && mod.status == "paid") {
              if (
                (mod.updated_details.progress_status == "initial" ||
                  mod.updated_details.progress_status == "in-progress") &&
                mod.subscribe_plan__plan_type == typecreate &&
                mod.updated_details.category == category
              ) {
                redirection = true;
                viewId = mod.views;
                return;
              }
            } else {
              alert(
                "Apologies! but it seems like your subscription plan's payment is either inactive or pending"
              );
            }
          }
        });
      }

      if (redirection == true) {
        props.history.push(
          `/create/eternaview/${category}+${subCategory}+${typecreates}+${viewId}`
        );
      } else {
        setPlanDetail(true);
      }
    }
  };

  useEffect(() => {
    if (planDetai) {
      getPlan(
        clickedPlan.plans[0].id,
        clickedPlan.plans[0].plan,
        clickedPlan.plans[0].amount + clickedPlan.plans[1].amount
      );
    }
  }, [planDetai]);

  const getCategorie = () => {
    if (!auth.auth.loginUserToken) {
      auth.auth.loginUserToken = sessionStorage.getItem("token");
    }
    getCategories({ token: auth.auth.loginUserToken }, (res) => {
      if (res.data && res.data.length > 0) {
        setCategories(res.data);
      }
    });
  };
  const getSubCategories = () => {
    dispatch(
      getCategoriesOpen((res) => {
        if (res.data && res.data.length > 0) {
          setCategories(res.data);
          const lagecy_data = res.data.filter(
            (item, i) => item.name == "Legacy"
          );
          loadSelectChange(lagecy_data[0]);
        }
      })
    );
  };

  const loadSelectChange = (item) => {
    setTypeCreate(0);
    setCategory(item.id);
    dispatch(
      getSubCategoriesByCatOpen(item.id, (res) => {
        setSubCategories({
          subCategories: res.data.sub_categories,
          catName: res.data.name,
        });
        setSubcategory(res.data.sub_categories[0].id);
      })
    );
  };

  function getPlan(price, plan, amount) {
    window.fbq("track", "InitiateCheckout");
    setPriceLookupKey(price);
    setPlanId(plan);
    setPlanAmount(amount);

    localStorage.setItem("priceLookupKey", price);
    localStorage.setItem("planId", plan);

    let body = {
      lookup_key: plan,
      domain: `${FRONTEND_ENDPOINT}/home/plans/${paramUrl}`,
      planAmount: amount,
      basePath: `${window.location.origin}/home/plans/${paramUrl}`,
    };
    if (!auth.auth.loginUserToken) {
      auth.auth.loginUserToken = sessionStorage.getItem("token");
    }
    dispatch(
      createCheckoutSession(body, auth.auth.loginUserToken, (result) => {
        window.location.href = result.session_url;
      })
    );
  }
  const checkLoginStatus = (resp) => {
    if (!auth.auth.loginUserToken || !sessionStorage.getItem("token")) {
      openLoginPop();
    } else {
      nextCreate();
    }
  };

  const openLoginPop = () => {
    setisModelOpen((prev) => ({
      ...prev,
      isThankyouOpen: false,
      isSignUpOpen: false,
      isLoginOpen: true,
    }));
  };

  const openSignupPopUp = (ret) => {
    setisModelOpen((prev) => ({
      ...prev,
      isThankyouOpen: false,
      isSignUpOpen: true,
      isLoginOpen: false,
    }));
  };

  const closeLoginPopup = (ret) => {
    if (auth.auth.loginUserToken || sessionStorage.getItem("token")) {
      dispatch(verifyUserSubscriptions(nextCreate));
      // getPlan(
      //     clickedPlan.plans[0].id,
      //     clickedPlan.plans[0].plan,
      //     clickedPlan.plans[0].amount + clickedPlan.plans[1].amount
      //   )
      setisModelOpen((prev) => ({
        ...prev,
        isLoginOpen: false,
      }));
    } else {
      setisModelOpen((prev) => ({
        ...prev,
        isLoginOpen: false,
      }));
    }
  };

  const cloaseAnyModel = () => {
    setisModelOpen((prev) => ({
      ...prev,
      isThankyouOpen: false,
      isSignUpOpen: false,
      isLoginOpen: false,
    }));
  };

  const closeSignupPopup = (ret) => {
    setisModelOpen((prev) => ({
      ...prev,
      isSignUpOpen: false,
      isThankyouOpen: true,
    }));
  };

  return (
    <section className="plan_outer new_plan">
      {loaded && <Loader loaded={loaded} />}

      <div className="container pricing_container" style={{ marginBottom: 10 }}>
        <div className="plan_outer2">
          <h4>Pricing Plans</h4>
          <p>
            Here are our Pricing Plans for creating your Eternaview. It is
            required to purchase one of these plans if you want <br />
            to begin the process of creating your Eternaview (feel free to try
            out a free practice
            <br />
            Eternaview in the meantime).
          </p>
        </div>
        <div className="table-responsive prceing_plan">
          <table className="table table-bordered compatibility">
            <thead>
              <tr>
                <th colSpan="4">
                  <h5 className="custyo">Pricing Plans</h5>
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <div className="table-responsive prceing_plan">
          <table className="table table-bordered col_table_custom">
            <tbody>
              <tr className="first_col">
                <td className="no_color"></td>
                <td className="no_color">
                  What types of Eternaviews is
                  <br /> this plan used for?
                </td>
                <td className="no_color">
                  Upfront Creation Price
                  <br />
                  (one-time fee)
                </td>
                <td className="no_color">
                  Annual Subscription Price
                  <br />
                  (charged day 1 and then every year)
                </td>
              </tr>
              {plans &&
                plans.map((plan) => (
                  <tr className="custom_left tractive">
                    <td
                      className={
                        plan.name_slug != "Legacy"
                          ? "custom_cnter graybox"
                          : "title_td"
                      }

                      // className="title_td"
                    >
                      {plan.name_slug != "Legacy" && <span>Coming Soon</span>}
                      {plan.name} Plan
                      {planVerify.active_status != "active" &&
                      (catName.toLowerCase() == plan.name_slug.toLowerCase() ||
                        (catName.toLowerCase() == "dating" &&
                          plan.name.toLowerCase() == "chat plan")) ? (
                        <div className="custom_cnter text-white">
                          {plan.plans.length == 2 && (
                            <a
                              // className={(isMobile||isTablet) && "purchase_button"}
                              style={
                                isMobile ? { fontSize: 17 } : { fontSize: 12 }
                              }
                              onClick={() => {
                                setClickedPlan(plan);
                                checkLoginStatus(plan);
                              }}
                            >
                             Get started
                            </a>
                          )}
                          {plan.plans.length == 1 && (
                            <a
                              style={
                                isMobile ? { fontSize: 17 } : { fontSize: 12 }
                              }
                              // className={(isMobile||isTablet) && "purchase_button"}
                              onClick={() =>
                                getPlan(
                                  plan.plans[0].id,
                                  plan.plans[0].plan,
                                  plan.plans[0].amount
                                )
                              }
                            >
                             Get started
                            </a>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
{console.log(plan,"<- plan")}
                    {plan.name_slug == "Legacy" && (
                      <td className="custom_cnter">{plan.name}</td>
                    )}

                    {plan.name_slug == "Dating" && (
                      <td
                        className={
                          plan.name_slug == "Dating"
                            ? "custom_cnter graybox"
                            : "custom_cnter"
                        }
                      >
                        Dating
                        {/* <span>Coming Soon</span> */}
                      </td>
                    )}
                    {plan.name_slug == "Audio-only" && (
                      <td
                        className={
                          plan.name_slug == "Audio-only"
                            ? "custom_cnter graybox"
                            : "custom_cnter"
                        }
                      >
                        Audio-only
                        <br /> (i.e. no video) <br /> for either Legacy or
                        Dating
                        {/* <span>Coming Soon</span> */}
                      </td>
                    )}
                    {plan.name_slug == "Audio-only" ||
                    plan.name_slug == "Dating" ||
                    plan.name_slug == "Legacy" ? (
                      ""
                    ) : (
                      <td className={"custom_cnter graybox"}>
                        {plan.name_slug}
                        {/* <span>Coming Soon</span> */}
                      </td>
                    )}
                    <td
                      className={
                        plan.name_slug == "Legacy"
                          ? "custom_cnter"
                          : "custom_cnter graybox"
                      }
                      // className="custom_cnter"
                    >
                      ${plan.plans[1]?.amount}
                    </td>
                    <td
                      className={
                        plan.name_slug == "Legacy"
                          ? "custom_cnter"
                          : "custom_cnter graybox"
                      }
                      // className="custom_cnter"
                    >
                      ${plan.plans[0].amount}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <a
          href="javascript:void(0)"
          onClick={() => {
            {
              if (showPlanInfo == false) {
                setShowPlanInfo(true);
              } else {
                setShowPlanInfo(false);
              }
            }
          }}
          style={{ color: "#921fc4", textDecoration: "underline" }}
        >
          {showPlanInfo == false
            ? "Click here to view full plan details on..."
            : "Click here to hide information"}
        </a>
        <p className="p_tag_Pricing">Compatibility</p>
        <p className="p_tag_Pricing">Features</p>
        <p className="p_tag_Pricing">Usage Allowances</p>
        <div
          className="plan-more-infromation"
          style={{ display: showPlanInfo == false ? "none" : "block" }}
        >
          <div className="table-responsive prceing_plan">
            <table className="table table-bordered compatibility">
              <thead>
                <tr>
                  <th colSpan="4">
                    <h5 className="custyo">Compatibility</h5>
                    <p className="custom67">
                      Initial Recording Can Be Made on and Viewers Can Interact
                      w/the Recording on Any of the Following:
                      <br />
                      (there may be some limitations in the case of very old
                      hardware or software versions)
                    </p>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="table-responsive prceing_plan">
            <table className="table table-bordered col_table_custom">
              <tbody>
                <tr className="first_col">
                  <td className="no_color"></td>
                  <td className="no_color">
                    PC's - desktop or laptop computers-
                    <br /> running Windows and Apple(macOS)
                  </td>
                  {/* <td className="no_color">Chromebooks</td> */}
                  <td className="no_color">
                    Tablets running Android or
                    <br /> Apple(iOS)
                  </td>
                  <td className="no_color">
                    Mobile phones running Android or <br /> Apple(iOS)
                  </td>
                  {/* <td className="no_color">Chromebooks</td> */}
                </tr>
                {plans &&
                  plans.map((plan) => (
                    <tr className="custom_left">
                      <td className="title_td">{plan.name} Plan</td>
                      {/* {plan.name == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name == "Chat Plan" && <td className="custom_cnter">Dating</td>}
                                    {plan.name == "Audio Only Plan" && <td className="custom_cnter">Almost any Audio-<br /> only (i.e. no video) <br /> for either Legacy or Dating</td>} */}
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      {/* <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="bg_gren">
            <p>
              May be compatible with some additional devices as well but we
              cannot confirm in advance.
            </p>
          </div>

          <div className="table-responsive prceing_plan">
            <table className="table table-bordered compatibility">
              <thead>
                <tr>
                  <th colSpan="4">
                    <h5 className="custyo">Features</h5>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="table-responsive prceing_plan">
            <table className="table table-bordered col_table_custom">
              <tbody>
                <tr className="first_col">
                  <td className="no_color"></td>
                  <td className="no_color">
                    Recording through your <br />
                    webcam or mobile camera and/or microphone
                  </td>
                  <td className="no_color">
                    Recording in Full HD (Up to 1080p,
                    <br />
                    depending on resolution of recording device. Certain
                    devices, like iPAD, limited to 720p.)
                  </td>
                  <td className="no_color">
                    Recording is processed for optimal playback on all
                    compatible devices and screens
                  </td>
                  <td className="no_color">
                    Adaptive bit-rate streaming to
                    <br /> optimize performance at
                    <br /> different internet speeds
                  </td>
                  <td className="no_color">
                    Storage on Amazon Web
                    <br /> Services
                  </td>
                  <td className="no_color">
                    Responds to questions spoken by the viewer of your
                    Eternaview
                  </td>
                  <td className="no_color">
                    Responds to questions typed in by the viewer of your
                    Eternaview
                  </td>
                  <td className="no_color">
                    Eternaview indexed by each recorded answer, w/ easy clicking
                    to retrieve any answer
                  </td>
                  <td className="no_color">
                    The ability to set private or
                    <br /> public viewing options and to
                    <br /> send invites
                  </td>
                  <td className="no_color">
                    No charge to the viewers that
                    <br /> you want to view/interact
                    <br /> with your eternaview
                  </td>
                </tr>
                {plans &&
                  plans.map((plan) => (
                    <tr className="custom_left">
                      <td className="title_td">{plan.name} Plan</td>
                      {/* {plan.name == "Legacy" && <td className="custom_cnter">{plan.name}</td>}
                                    {plan.name == "Chat Plan" && <td className="custom_cnter">Dating</td>}
                                    {plan.name == "Audio Only Plan" && <td className="custom_cnter">Almost any Audio-<br /> only (i.e. no video) <br /> for either Legacy or Dating</td>} */}
                      {plan.name_slug == "Legacy" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                      {plan.name_slug == "Dating" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                      {plan.name_slug == "Audio-only" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                        {plan.name_slug == "Audio-only" ||
                      plan.name_slug == "Dating" ||
                      plan.name_slug == "Legacy" ? (
                        ""
                      ) : (
                        <td className={"custom_cnter"}>
                           <i className="fa fa-check" aria-hidden="true"></i>
                          {/* <span>Coming Soon</span> */}
                        </td>
                      )}
                      {plan.name_slug == "Legacy" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                      {plan.name_slug == "Dating" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                      {plan.name_slug == "Audio-only" && (
                        <td className="custom_cnter">N/A</td>
                      )}
                        {plan.name_slug == "Audio-only" ||
                      plan.name_slug == "Dating" ||
                      plan.name_slug == "Legacy" ? (
                        ""
                      ) : (
                        <td className={"custom_cnter"}>
                           <i className="fa fa-check" aria-hidden="true"></i>
                          {/* <span>Coming Soon</span> */}
                        </td>
                      )}
                      {plan.name_slug == "Legacy" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                      {plan.name_slug == "Dating" && (
                        <td className="custom_cnter">
                          <i className="fa fa-check" aria-hidden="true"></i>
                        </td>
                      )}
                      {plan.name_slug == "Audio-only" && (
                        <td className="custom_cnter">N/A</td>
                      )}
                        {plan.name_slug == "Audio-only" ||
                      plan.name_slug == "Dating" ||
                      plan.name_slug == "Legacy" ? (
                        ""
                      ) : (
                        <td className={"custom_cnter"}>
                           <i className="fa fa-check" aria-hidden="true"></i>
                          {/* <span>Coming Soon</span> */}
                        </td>
                      )}
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                      <td className="custom_cnter">
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="table-responsive prceing_plan">
            <table className="table table-bordered compatibility">
              <thead>
                <tr>
                  <th colSpan="4">
                    <h5 className="custyo">Usage Allowances*</h5>
                  </th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="table-responsive prceing_plan">
            <table className="table table-bordered col_table_custom">
              <tbody>
                <tr className="first_col">
                  <td className="no_color"></td>
                  <td className="no_color">
                    # of Eternaviews (i.e. full interviews) you can create per
                    subscription**
                  </td>
                  <td className="no_color">
                    Total length of Eternaview recording (you likely will not
                    need anywhere near this much time)
                  </td>
                  <td className="no_color">
                    # of answers that can be reviewed and re-recorded before
                    submitting that particular answer
                  </td>
                  <td className="no_color">
                    # of answers that can be edited after initial submissions
                  </td>
                  <td className="no_color">
                    # of questions that viewers can ask your Eternaview***
                  </td>
                </tr>
                {plans &&
                  plans.map((plan) => (
                    <tr className="custom_left">
                      <td className="title_td">{plan.name} Plan</td>
                      {/* {plan.name == "Legacy" && <td className="custom_cnter">"{plan.name}"</td>}
                                    {plan.name == "Chat Plan" && <td>"Dating" and Most<br /> "Create Your Own"<br /> Eternaviews</td>}
                                    {plan.name == "Audio Only Plan" && <td>Almost any Audio-<br /> only (i.e. no video) <br /> Eternaview</td>}  */}
                      <td className="custom_cnter">1</td>
                      <td className="custom_cnter">{plan.total_time} hours</td>
                      <td className="custom_cnter">Unlimited</td>
                      <td className="custom_cnter">
                        Up to {plan.no_of_edit} per year
                      </td>
                      <td className="custom_cnter">
                        Up to {plan.no_of_query} per year
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="box455">
            <p>
              *If customers need to increase their usage allowances they will
              have the ability to renew early in order to reset their usage
              allowances.
            </p>
            <p>
              Certain exceptions may apply to Usage Allowances, as detailed in
              our Terms of Use.
            </p>
            <p>
              **Note that a single Eternaview (i.e. interview) consists of your
              answers to anywhere from 75 to 175+ questions that you will be
              shown during the recording process.
            </p>
            <p>
              ***This includes whether questions are asked in spoken or text
              form, whether viewer just clicks directly on the answers they want
              to hear, or any other way individual answers may be played back in
              the future. It also includes any questions the creator asks of
              their own Eternaview.{" "}
            </p>
            {/* <p>Keep in mind that if you make your Eternaview public that there may be more questions asked of it.</p> */}
          </div>
        </div>
        {showPlanInfo && (
          <div className="row contact_outer">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="row bg_color67">
                <div className="col-lg-8">
                  <div className="leg556">
                    <h3>Custom Plan</h3>
                    <p>
                      If you are a celebrity, influencer, corporate exec or
                      otherwise expect very high viewership, please shoot us an
                      email with more details
                    </p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="conty6">
                    <Link to="/home/contact-us/">
                      <button className="btn cont-btn">Contact Us</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        id="myLoginPopup"
        style={{
          display: isModelOpen.isLoginOpen === true ? "block" : "none",
        }}
        className={
          isModelOpen.isLoginOpen === true
            ? "modal fade show"
            : "modal fade hide"
        }
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <button
                onClick={() => {
                  cloaseAnyModel();
                }}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            <div className="modal-body custom_body custom_modal_body">
              <Login
                customClass={{ modalTop: "modalh4" }}
                isLoginPopup={true}
                isSignupPop={openSignupPopUp}
                closeLoginPopup={closeLoginPopup}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="mySignupPopup"
        style={{
          display: isModelOpen.isSignUpOpen ? "block" : "none",
        }}
        className={
          isModelOpen.isSignUpOpen ? "modal fade show" : "modal fade hide"
        }
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
              <button
                type="button"
                className="close"
                onClick={() => {
                  cloaseAnyModel();
                }}
              >
                &times;
              </button>
            <div className="modal-body custom_body custom_modal_body">
              <RegisterComponent
                customClass={{ modalTop: "modalh4" }}
                openLoginPopUp={openLoginPop}
                closeSignupPopup={closeSignupPopup}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        id="thankyouPopup"
        style={{
          display: isModelOpen.isThankyouOpen ? "block" : "none",
        }}
        className={
          isModelOpen.isThankyouOpen ? "modal fade show" : "modal fade hide"
        }
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header custom_modal_header">
              <button
                type="button"
                className="close"
                onClick={() => {
                  cloaseAnyModel();
                }}
              >
                &times;
              </button>
              <h4 className="modal-title"></h4>
            </div>
            <div className="modal-body custom_body custom_modal_body">
              <Thankyou customClass={true} openLoginPopUp={openLoginPop} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NewplanScreen;
