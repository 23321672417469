import React, { Component } from 'react';
// import vector_audio from "../../../Images/vector_audio.jpg";
// import vector_audio from "../../../Images/audio.gif";
import vector_audio from "../../../Images/interact_audio.jpg";
import audio_wave from "../../../Images/waves_banner_gif.gif";
import $ from 'jquery';
import new_audio_wave from "../../../Images/audio_new_gif.gif";
import sample_audio from '../../../Sounds/sample_audio.mp3';

class InteractAudio extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({ recording: this.props.recording, orgUrl: this.props.orgUrl, no_transcoding: this.props.no_transcoding, videoUrl: this.props.videoUrl, videoType: this.props.videoType, isPlaying: false });
    }

    myCallback = () => {
        console.log('Audio has ended');
        this.setState({ recording: false, isPlaying: false });
       
        this.props.setInteractResponse(true);
        this.props.setAudioTranscript();
    };

    startPlaying = () => {
        this.setState({ isPlaying: true });
        
    
    }


    

    render() {

        return (
            <>
                {(this.props.recording == false && this.props.orgUrl == '') && <img className="audio-img-vector" src={vector_audio} />}
                {(this.props.recording == false && this.props.orgUrl != '') && <div><img className="audio-img-vector" src={vector_audio} /></div>}

                {(this.props.recording == true) && <div><img className="audio-img-vector" src={!this.state.isPlaying ? vector_audio : audio_wave} />
                </div>}

                <audio className='audioInterpretor' id="audioIteractor"  playsInline  src={this.props.videoUrl}  autoPlay  onEnded={() => this.myCallback()} onPlay={() => this.startPlaying()} />
            </>
        );
    }
}

export default InteractAudio;