import React from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log(paymentMethod,": paymentMethod");
      if(paymentMethod)
      await props.saveForm(paymentMethod.id)
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="paymentMethodLabel">
          Update Payment Method
        </h5>
        <button
          type="submit" disabled={!stripe}
          className="btn btn-primary"
          onClick={handleSubmit}
        >
         Update Card
        </button>
      </div>
      <div className="modal-body">
        <form>
          <CardElement />
        </form>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={()=>{props.closePayment()}}
        >
          Close
        </button>
      </div>
    </div>

    //   <form onSubmit={handleSubmit}>

    //     <button className='Puchase_now_btn_new mt-4' type="submit" disabled={!stripe}>
    //       Update Card
    //     </button>
    //   </form>
  );
};

export default CheckoutForm;
