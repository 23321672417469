import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import check_outer4 from "../../Images/check_outer4.png";
import ctre45 from "../../Images/ctre45.png";
import audio_wave from "../../Images/audio_wave.gif";
// import audio_wave from "../../Images/audio.gif";
import { getParticularQuestionDetail, scanEditQuestions, checkIfDone, getNoddingVideo, verifyUserSubscription } from "../../redux/actions";
import HeaderComponent from "../../components/Header";
import { toast } from 'react-toastify';
import { withRouter } from "react-router";
// import vector_audio from "../../Images/vector_audio.jpg";
// import vector_audio from "../../Images/audio.gif";
import vector_audio from "../../Images/congratulation_audio.jpg";
import Loader from "../../Images/buffer.gif";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import $ from 'jquery';

const videoJsOptions = {
    controls: true,
    bigPlayButton: false,
    autoplay: true,
    responsive: true,
    width: 480,
    height: 320,
    fluid: true
    // sources: [{
    //     src: 'https://vjs.zencdn.net/v/oceans.mp4',
    //     type: 'video/mp4'
    // }]
};

class Congratulations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clickedQuesDetails:{},
            moduleQuestions: [],
            category: '',
            subcategory: '',
            type: '',
            questionVideo: '',
            flag: '',
            checkif: false,
            nodding_video: '',
            verifyPlanDetail: {},
            no_of_edit: false,
            grace_no_of_edit: false,
            total_time: false,
            sixtyEdit: false,
            ninetyEdit: false,
            no_transcoding: false,
            plan_id: '',
            viewId: '',
            question_id : '',
            subcat_id : '',
            hideEdit: false,
            baseName:"home",
        };
        this.intervalId = null
        this.parentRef = React.createRef();
    }
    scrollToChild = (id)=>{
        const parentDiv = this.parentRef.current;
        const element = document.getElementById(`collapseOne${id}`);
        element.scrollTo({ top: element.offsetTop - parentDiv.offsetTop, behavior: 'smooth',inline: "nearest", })
      }
    setScroll(e)
    {
        $('#accordionExample').animate({
            scrollTop: $(e.target).offset().top-500
        }, 1000);
    }

    getTranscodedURL = (data) =>{
        // const data = this.state.clickedQuesDetails
        let token = this.props.auth.loginUserToken;

        this.setState({ flag: data.id });
        // this.props.getParticularQuestionDetail(`author-question/${data.id}/?view_id=${this.state.verifyPlanDetail.views}`, token, (res) => {
        this.props.getParticularQuestionDetail(`author-question/${data.id}/?view_id=${this.state.viewId}`, token, (res) => {
            if (res.data && res.data.answer.id) {
                this.setState({ no_transcoding: false,clickedQuesDetails:data });
                if (res.data.answer.video.trans_coded_url != null) {
                    this.setState({ questionVideo: res.data.answer.video })
                    this.newNode.src = res.data.answer.video.trans_coded_url;
                    this.newplayer.src({
                        src: res.data.answer.video.trans_coded_url,
                        type: 'application/x-mpegURL'
                    });
                    const interval =  sessionStorage.getItem("questionIntervalID")
                    if(interval) clearInterval(interval)
                } else if (res.data.answer.video.type == "audio") {
                    this.setState({ questionVideo: res.data.answer.video })
                } else {
                    this.setState({ no_transcoding: true });
                }
            }
        });
    }
    
    getNyNoddingVideo(split){
        this.props.getNoddingVideo(split[0], this.state.viewId, this.props.auth.loginUserToken, (res) => {
            // this.props.getNoddingVideo(split[0], this.state.verifyPlanDetail.views, this.props.auth.loginUserToken, (res) => {
                if(res.nodding_video ) {
                    this.setState({ nodding_video: res.nodding_video });
                    if(this.videoNode) {
                        this.videoNode.src = res.nodding_video;
                        this.videoNode.muted = true;
                        this.player.src({
                            src: res.nodding_video,
                            type: 'application/x-mpegURL'
                        });
                    }

                }else{
                    this.intervalId = setInterval(() => {
                        this.availNyNoddingVideo(split)
                    }, 3000);
                }
            });
    }

    availNyNoddingVideo(split){
        this.props.getNoddingVideo(split[0], this.state.viewId, this.props.auth.loginUserToken, (res) => {
            // this.props.getNoddingVideo(split[0], this.state.verifyPlanDetail.views, this.props.auth.loginUserToken, (res) => {
                if(res.nodding_video) {
                    this.setState({ nodding_video: res.nodding_video });
                    if(this.videoNode) {
                        this.videoNode.src = res.nodding_video;
                        this.videoNode.muted = true;
                        this.player.src({
                            src: res.nodding_video,
                            type: 'application/x-mpegURL'
                        });
                    }
                    clearInterval(this.intervalId)

                }
            });
    }
    
    componentDidMount() {

        const { location } = this.props;
        const { pathname } = location;
        const splitLocation = pathname.split("/");
        if(splitLocation[1]=='dating')
        {
          this.setState({ baseName:'dating' });
        }
        else
        {
          this.setState({ baseName:'home' });
        }



        this.player = videojs(this.videoNode, videoJsOptions, function onPlayerReady() {
            // print version information at startup
            console.log('onPlayerReady', this)
        });

        // device is ready
        this.player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        this.newplayer = videojs(this.newNode, videoJsOptions, function onPlayerReady() {
            // print version information at startup
            console.log('onPlayerReady', this)
        });

        // device is ready
        this.newplayer.on('deviceReady', () => {
            console.log('device is ready!');
        });

        let split = (this.props.match.params.id).split("+"); 
        // var view = 0;
        var view = split[split.length - 1];
        // if(split[3] == 'success') {
        //   //  window.location.reload();
        // }
        if(split[3] && split[3].includes("view-")) {
            let random = split[3].split("-");
            view = random[1];

            this.setState({ viewId: random[1]});
        } else {
            this.setState({plan_id : split[3]})
        }

        this.setState({ category: split[0], subcategory: split[1], type: split[2]  });

        if (this.props.auth.loginUserToken != "") {
            let typecreate = (split[2] == "video" || split[2] == 0) ? "video_audio" : "audio";
            this.setState({typecreate}); 
            this.props.verifyUserSubscription(this.props.auth.loginUserToken, (res) => {
                if (res.data && res.data.length > 0) {

                    res.data.map((mod, ind) => {
                        if(view == 0) {
                            if (mod.active_status == "active" && split[0] == mod.subscribe_plan__category__id) {
                                if ((mod.updated_details.progress_status == "initial" || mod.updated_details.progress_status == "in-progress") && mod.subscribe_plan__plan_type == typecreate) {
                                    this.setState({ plan_id: mod.id, viewId: mod.views });
                                    this.setState({verifyPlanDetail : mod});
                                    return;
                                }
                            } else if (mod.active_status == "active" && mod.subscribe_plan__category__id == 9999) {
                                if (mod.updated_details.progress_status == "initial" || mod.updated_details.progress_status == "in-progress" && mod.subscribe_plan__plan_type == typecreate) {
                                    this.setState({ plan_id: mod.id, viewId: mod.views });
                                    this.setState({verifyPlanDetail : mod});
                                    return;
                                }
                            }

                            if (mod.subscribe_plan__category__id == split[0] && mod.updated_details.progress_status == "complete" && mod.subscribe_plan__plan_type == typecreate) {

                                this.setState({verifyPlanDetail : mod});
                                
                                let sixtyVal = mod.subscribe_plan__no_of_edit - (Math.floor(0.60 * mod.subscribe_plan__no_of_edit));
                                if (sixtyVal == mod.updated_details.no_of_edit) {
                                    this.setState({ sixtyEdit: true });
                                }
                                let ninetyVal = mod.subscribe_plan__no_of_edit - (Math.floor(0.90 * mod.subscribe_plan__no_of_edit));
    
                                if (ninetyVal == mod.updated_details.no_of_edit) {
                                    this.setState({ ninetyEdit: true });
                                }

                                if (mod.updated_details.no_of_edit == 0 && mod.updated_details.grace_no_of_edit != 0) {
                                    this.setState({ hundredEdit: true });
                                }

                                if (mod.updated_details.grace_no_of_edit == 0 && mod.updated_details.no_of_edit == 0) {
                                    this.setState({ graceEdit: true, hideEdit:true });
                                }
                                
                            } else if(mod.subscribe_plan__category__id == 9999 && mod.updated_details.progress_status == "complete" && mod.subscribe_plan__plan_type == typecreate && mod.subscribe_plan__no_of_edit > 0) {

                                this.setState({verifyPlanDetail : mod});
                                let sixtyVal = mod.subscribe_plan__no_of_edit - (Math.floor(0.60 * mod.subscribe_plan__no_of_edit));
                                if (sixtyVal == mod.updated_details.no_of_edit) {
                                    this.setState({ sixtyEdit: true });
                                }
                                let ninetyVal = mod.subscribe_plan__no_of_edit - (Math.floor(0.90 * mod.subscribe_plan__no_of_edit));

                                if (ninetyVal == mod.updated_details.no_of_edit) {
                                    this.setState({ ninetyEdit: true });
                                }

                                if (mod.updated_details.no_of_edit == 0 && mod.updated_details.grace_no_of_edit != 0) {
                                    this.setState({ hundredEdit: true });
                                }

                                if (mod.updated_details.grace_no_of_edit == 0 && mod.updated_details.no_of_edit == 0) {
                                    this.setState({ graceEdit: true, hideEdit:true });
                                }
                            }
                        } else if(view == mod.views) {
                            this.setState({ plan_id: mod.id, viewId: mod.views });
                           
                            this.setState({verifyPlanDetail : mod});

                            if ('in-progress' !== mod.updated_details.progress_status) {
                                let sixtyVal = mod.subscribe_plan__no_of_edit - (Math.floor(0.60 * mod.subscribe_plan__no_of_edit));
                                if (sixtyVal == mod.updated_details.no_of_edit) {
                                    this.setState({ sixtyEdit: true });
                                }
                                let ninetyVal = mod.subscribe_plan__no_of_edit - (Math.floor(0.90 * mod.subscribe_plan__no_of_edit));
    
                                if (ninetyVal == mod.updated_details.no_of_edit) {
                                    this.setState({ ninetyEdit: true });
                                }
                                
                                if (mod.updated_details.no_of_edit == 0 && mod.updated_details.grace_no_of_edit != 0) {
                                    this.setState({ hundredEdit: true });
                                }
    
                                if (mod.updated_details.grace_no_of_edit == 0 && mod.updated_details.no_of_edit == 0 && mod.updated_details.progress_status=='complete') {
                                    console.log("updated data",mod.updated_details);
                                    this.setState({ graceEdit: true, hideEdit:true });
                                }
                                
                            }

                        }


                    });


                    // console.log("View id --- ", this.state.viewId , " ---- ", this.state.verifyPlanDetail.views);
                    
                    if(this.state.viewId != "" || this.state.verifyPlanDetail.views) {
                        this.props.scanEditQuestions('edit', this.state.viewId, this.props.auth.loginUserToken, (res) => {

                        // this.props.scanEditQuestions('edit', this.state.verifyPlanDetail.views, this.props.auth.loginUserToken, (res) => {
                           // let con = res.data, questions = [], match = res.transition_name;
            
                            if (res.data && res.data.length > 0) {
                                this.setState({ moduleQuestions: res.data[0].modules });
                            }
                        });
                        console.log("INSIDE Call");
                        this.getNyNoddingVideo(split)
                
                    }
                }
            });
        }
    }

    myCallback = () => {
        console.log('Video has ended');
        this.setState({ questionVideo: '' });
    };

    closePopUp = (e) => {
        e.preventDefault();

        this.setState({ total_time: false, no_of_edit: false, grace_no_of_edit: false, sixtyEdit: false, ninetyEdit: false, hundredEdit: false, graceEdit: false });
        if (this.state.verifyPlanDetail.updated_details.no_of_edit == 0 && this.state.verifyPlanDetail.updated_details.grace_no_of_edit > 0 && this.state.subcat_id != '') {
            // this.props.history.push(`/edit/eternaview/${this.state.category}+${this.state.subcat_id}+${this.state.question_id}+${this.state.verifyPlanDetail.views}+${this.state.type}`);

            this.props.history.push(`/${this.state.baseName}/edit/eternaview/${this.state.category}+${this.state.subcat_id}+${this.state.question_id}+${this.state.viewId}+${this.state.type}`);
        }
    }

 
    questionDetail = (data) => {
        const interval =  sessionStorage.getItem("questionIntervalID")
        if(interval) clearInterval(interval)
        let token = this.props.auth.loginUserToken;
        this.setState({ no_transcoding: false,clickedQuesDetails:data });

        this.setState({ flag: data.id });
        // this.props.getParticularQuestionDetail(`author-question/${data.id}/?view_id=${this.state.verifyPlanDetail.views}`, token, (res) => {
        this.props.getParticularQuestionDetail(`author-question/${data.id}/?view_id=${this.state.viewId}`, token, (res) => {
            if (res.data && res.data.answer.id) {
                if (res.data.answer.video.trans_coded_url != null) {
                    this.setState({ questionVideo: res.data.answer.video })
                    this.newNode.src = res.data.answer.video.trans_coded_url;
                    this.newplayer.src({
                        src: res.data.answer.video.trans_coded_url,
                        type: 'application/x-mpegURL'
                    });
                } else if (res.data.answer.video.type == "audio") {
                    this.setState({ questionVideo: res.data.answer.video })
                } else {
                    this.intervalId = setInterval(() => {
                        this.getTranscodedURL(data)
                      }, 3000); 
                    sessionStorage.setItem("questionIntervalID",this.intervalId)
                    this.setState({ no_transcoding: true });
                    
                }
            }
        });
    };

    goToEditOption = (data, completedata) => {
        if (Object.keys(this.state.verifyPlanDetail).length > 0 && this.state.verifyPlanDetail && this.state.verifyPlanDetail.updated_details.progress_status == 'complete') {
            if (this.state.verifyPlanDetail.updated_details.no_of_edit > 0 || this.state.verifyPlanDetail.updated_details.grace_no_of_edit > 0) {
                // this.props.history.push(`/edit/eternaview/${this.state.category}+${completedata.id}+${data.id}+${this.state.verifyPlanDetail.views}+${this.state.type}`);

                this.props.history.push(`/${this.state.baseName}/edit/eternaview/${this.state.category}+${completedata.id}+${data.id}+${this.state.viewId}+${this.state.type}`);

            } else if (this.state.verifyPlanDetail.updated_details.no_of_edit == 0 && this.state.verifyPlanDetail.updated_details.grace_no_of_edit > 0) {
                this.setState({ no_of_edit: true });
                this.setState({question_id : data.id, subcat_id : completedata.id});

            } else if (this.state.verifyPlanDetail.updated_details.no_of_edit == 0 && this.state.verifyPlanDetail.updated_details.grace_no_of_edit == 0) {
                
                this.setState({ grace_no_of_edit: true });
            }
        } else {
            // this.props.history.push(`/edit/eternaview/${this.state.category}+${completedata.id}+${data.id}+${this.state.verifyPlanDetail.views}+${this.state.type}`);
            this.props.history.push(`/${this.state.baseName}/edit/eternaview/${this.state.category}+${completedata.id}+${data.id}+${this.state.viewId}+${this.state.type}`);
        }
    };

    goToForm = () => {
       // if (this.state.checkif == false) {
        if(this.state.viewId != '' && this.state.verifyPlanDetail.updated_details.progress_status != 'complete') {
            this.props.history.replace(`/${this.state.baseName}/create/congratulation/form/${this.state.category}+${this.state.subcategory}+${this.state.type}+${this.state.plan_id}+${this.state.viewId}`);
        } else {
            this.props.history.push(`/${this.state.baseName}/myeternaviews/`);
            //this.props.history.push('/home/myeternaviews/');
        }
    };

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    render() {
        const { moduleQuestions, questionVideo, flag, no_transcoding, typecreate } = this.state;
        return (
            <div>
            <HeaderComponent value={100} props={this.props} />
            <section className="congration_outer congratulations_outer">
                <div className="container">
                    <div className="congr5_outer check672">
                        <img src={check_outer4} />
                        <h4>Congratulations</h4>
                        <p>You are welcome to review and/or edit any of your answers just by clicking on the question. Otherwise, you can<br /> move on to invite the folks you want to be able to watch your Eternaview now or in the future!
                        </p>
                    </div>

                    <div id="myModal201" className={this.state.no_of_edit == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>You have reached the maximum number of edits allowed per your subscription plan. We will give you {this.state.verifyPlanDetail.subscribe_plan__no_of_edit} additional edits for free, but if you would like to make additional edits then you can please wait until the next time your subscription automatically renews and your usage allowances reset, or you can renew your subscription early.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="myModal201" className={this.state.grace_no_of_edit == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Sorry but you have now reached the maximum number of edits that your Eternaview is entitled to receive per your subscription plan. This functionality will be restricted until the next time your subscription is renewed. We encourage you to renew early to restore this functionality and reset all of your usage allowances.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="myModal201" className={this.state.sixtyEdit == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Please be aware that you have done 60% of the total number of edits allowed per your subscription plan. If you ever need more, you will have the option to renew your plan early and reset your usage allowances.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="myModal201" className={this.state.ninetyEdit == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Please be aware that you have done 90% of the total number of edits allowed per your subscription plan. If you ever need more, you will have the option to renew your plan early and reset your usage allowances.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="myModal201" className={this.state.hundredEdit == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>You have reached the maximum number of edits allowed per your subscription plan. We will give you a few additional edits for now. But if you would like to make further edits, please wait until the next time your subscription automatically renews and your usage allowances reset, or you can renew your subscription early.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="myModal201" className={this.state.graceEdit == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Sorry but you have now reached the maximum number of edits that your Eternaview is entitled to receive per your subscription plan. This functionality will be restricted until the next time your subscription is renewed. We encourage you to renew early to restore this functionality and reset all of your usage allowances.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="myModal201" className={this.state.total_time == true ? "modal fade show custom_modal" : "modal fade show show_custom_modal"} role="dialog">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button type="button" className="close custom_close3" data-dismiss="modal">&times;</button>
                                </div>
                                <div className="modal-body">
                                    <p>Please note that you have consumed maximum recording time limit and left with only 1 hour.</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" onClick={this.closePopUp}>Okay!</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-5 col-md-12">
                            <div className="img6_outer congrtx_img custom_vide45">
                                <div className="video_loader_outer">

                                    {no_transcoding == true && <div className="processed_video_outer">
                                        <img src={Loader} />
                                        <p>Your video is getting processed</p>
                                        <p>Please wait</p>
                                    </div>}

                                    {(questionVideo == '' && typecreate == 'audio' && this.state.nodding_video == "") && <img src={vector_audio} />}

                                    {(questionVideo != '' && questionVideo.type == "audio") && <div><img src={audio_wave} /><audio src={questionVideo.media_file} autoPlay onEnded={() => this.myCallback()}></audio></div>}

                                    <div className={(!questionVideo.type && this.state.nodding_video != "" && no_transcoding == false) ? "" : "congratulation-video-hide"}><div data-vjs-player>
                                        <video id="myVideo" ref={node => this.videoNode = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions} playsInline></video>
                                    </div></div>

                                    <div className={(questionVideo != '' && questionVideo.type == "video" && no_transcoding == false) ? "" : "congratulation-video-hide"}><div data-vjs-player>
                                        <video id="myVideo" ref={node => this.newNode = node} className="video-js vjs-default-skin custom_video_js" options={videoJsOptions} playsInline></video>
                                    </div></div>
                                </div>

                                {moduleQuestions.length > 0 && <button className="btn" onClick={this.goToForm}>Move on to invites!</button>}
                                
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12" >
                            <div className="custtom_according congrats-scroll" ref={this.parentRef}  >
                                <div className="bs-example"   id="accorscroll2">
                                    <div className="accordion" id="accordionExample" >
                                        {moduleQuestions?.map((subcat, ind) => {
                                            return (
                                                <div className="card">
                                                    <div className="card-header" id={"headingOne" + ind}  onClick={()=>{this.scrollToChild(ind)}}>
                                                        <h2 className="mb-0">
                                                            <button type="button" 
                                                            // onClick={(e)=>this.setScroll(e)} 
                                                             className="btn btn-link" data-toggle="collapse" data-target={"#collapseOne" + ind}><span className="sub_cat_span">{subcat.subcategory_name}</span><i className="fa fa-angle-down"></i> </button>
                                                        </h2>
                                                    </div>
                                                    <div id={"collapseOne" + ind} className="collapse" aria-labelledby={"headingOne" + ind} data-parent="#accordionExample">
                                                        <div className="card-body">
                                                            {
                                                                subcat.questions.map((ques, index) => {
                                                                    return (
                                                                        <div>
                                                                            {ques.is_skip == false ? (<button className={ques.id == flag ? "active_btn" : ""} onClick={() => { this.questionDetail(ques) }}>{ques.question} <span className="custom_icon">
                                                                            {!this.state.hideEdit ? <i className="fas fa-edit" onClick={() => { this.goToEditOption(ques, subcat) }}></i> : ''}
                                                                            </span></button>) :

                                                                            (<button className="skip-class">{ques.question} <span className="custom_icon">
                                                                                {!this.state.hideEdit ? <i className="fas fa-edit" onClick={() => { this.goToEditOption(ques, subcat) }}></i> : ''}
                                                                                </span></button>)}
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({ auth: state.auth });
const mapDispatchToProps = dispatch => ({
    scanEditQuestions: bindActionCreators(scanEditQuestions, dispatch),
    getParticularQuestionDetail: bindActionCreators(getParticularQuestionDetail, dispatch),
    checkIfDone: bindActionCreators(checkIfDone, dispatch),
    getNoddingVideo: bindActionCreators(getNoddingVideo, dispatch),
    verifyUserSubscription: bindActionCreators(verifyUserSubscription, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Congratulations));
