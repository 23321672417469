import React, { useState } from "react";
import { Button } from "reactstrap";

const PricingFaq = (props) => {
  return (
    <section class="terms-outer">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="terms_inner">
              <div
                style={{ marginBottom: 25, padding: "padding: 15px 0px" }}
                class="terms_title"
              >
                Pricing FAQ
              </div>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: Are all the plans the same, except for term and price?{" "}
              </h5>
              <p className="faq_font">A. Yes.</p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: Why is there a One-time Creation fee?{" "}
              </h5>
              <p className="faq_font">
                A. The One-time Creation fee helps cover video processing costs
                for your Eternaview and a range of other costs. You will not be
                charged this fee again if and when you renew your plan beyond
                the initial term.
              </p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q. Why is there an ongoing subscription cost?{" "}
              </h5>
              <p className="faq_font">
                A.We have significant ongoing costs relating to storage,
                streaming, AI, etc. <br /> <br />
                Creators of Eternaviews will often want them to be accessible
                for a very long time - sometimes forever. Charging an ongoing
                subscription payment provides us the resources to maintain your
                Eternaview longer term. <br />
                <br />
              </p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: Can I purchase the service forever?
              </h5>
              <p className="faq_font">
                A.Right now we provide a 1 year or longer term 5 and 20 year
                option. <br />
                <br />
                While you can renew your subscription when it expires, we don’t
                offer a forever plan at this time. An Eternaview is a complex
                service, and the exact nature of that service could change
                somewhat over time. For instance, we may want to offer
                additional bells and whistles in the future. Therefore, the
                terms are finite so that we can keep our prices reasonable. It
                also gives us the flexibility to offer you a continually
                improving service over time.
              </p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: Can I download my Eternaview?
              </h5>
              <p className="faq_font">
                A.Yes, with all plans you can download your Eternaview at no
                additional charge. These are your videos - it’s your life - and
                we want you to have the piece of mind and flexibility of having
                your own copy on your own device.
                <br />
                <br />
                The downloaded Eternaview will consist of a separate video for
                each of your answers. It will not be interactive - i.e. people
                can’t have a conversation with it - and it will not be as easy
                to share with family and friends. But you can play back those
                videos at any time, like any other video.
              </p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: What happens to my Eternaview if you go out of business?
              </h5>
              <p className="faq_font">
                A.While it is our goal to be here for the long-term, we take our
                responsibility to you very seriously and have to acknowledge
                that no business can assure how long they will be around. As
                such, included with every subscription, we also provide you the
                ability to download your Eternaview on your own device. That
                should give you the piece of mind of having a copy of your
                videos to play any time. You may also be able to utilize those
                videos with other AI-powered conversational services in the
                future.
                <br />
                <br />
                We will reserve a sufficient amount of funds such that if
                Eternaview ever shuts down, you will be entitled to a pro rata
                refund based on the remaining term of your subscription.
                <br />
                <br />
                Were our company ever to be acquired, we would also hope to work
                with any acquirer towards a seamless transition for the
                continued storage and/or usage of your Eternaview.
              </p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: Am I better off buying this service from a large tech company
                that is very likely to be around for decades or centuries?
              </h5>
              <p className="faq_font">
                A.We are not aware of any large tech companies offering this
                service. Even if they did, it’s important to keep in mind that
                large tech companies regularly discontinue services they offer.
                To get a sense of what we mean, just check out
                www.killedbygoogle.com or killedbymicrosoft.info
              </p>

              <h5 style={{ fontWeight: "bold" }} className="faq_font">
                Q: Who can extend the subscription at the end of the term and
                who will pay for the subscription if I’m not here?
              </h5>
              <p className="faq_font">
                A. A subscription can be renewed indefinitely by:
                <ul style={{ listStyleType: "disc", marginLeft: "40px" }}>
                  <li>The creator of the Eternaview;</li>
                  <li>
                    The person who gifted the Eternaview (if it was a gift);
                  </li>
                  <li>
                    A Designated Potential Trustee (you will be prompted to
                    provide one after you create your Eternaview);
                  </li>
                  <li>Anyone you add to your invite list;  or </li>
                  <li>Immediate or extended family members/descendants.</li>
                </ul>
              </p>
           
              <div class="terms_content"></div>
            </div>
            <div className="text-right mb-4">
              <Button
                style={{ color: "white", backgroundColor: "#1dbf73" }}
                onClick={() => {
                  props.history.push("/home/faq/");
                }}
              >
                Full FAQ
              </Button>{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingFaq;
