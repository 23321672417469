import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import le1 from "../../Images/le1.png";
import le2 from "../../Images/le2.png";
import le3 from "../../Images/le3.png";
import audio_signal from "../../Images/audio_signal.png";
// import vector_audio from "../../Images/audio.gif";
import vector_audio from "../../Images/interact_audio.jpg";
// import vector_audio from "../../Images/vector_audio.jpg";
import { getPublicEternaviews } from "../../redux/actions";
import Moment from "moment";
import videojs from "video.js";
import "video.js/dist/video-js.css";

class Interact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legacyViewsData: [],
      datingViewsData: [],
    };
  }

  componentDidMount() {
    this.getEternaviews();

    if (localStorage.getItem("pass") === "match") {
    } else {
      this.props.history.push("/home/securelogin");
    }
  }

  getEternaviews() {
    this.props.getPublicEternaviews((res) => {
      console.log(res, "res");
      if (res.data && res.data.length > 0) {
        res.data.map((view, index) => {
          if (view.name == "Legacy") {
            this.setState({ legacyViewsData: view.views });
          } else if (view.name == "Dating") {
            this.setState({ datingViewsData: view.views });
          }
        });

        this.state.legacyViewsData.map((leg, ind) => {
          if (leg.nodding_video != "") {
            leg.videoJsOptions = {
              // lookup the options in the docs for more options
              playsinline: true,
              autoplay: true,
              muted: true,
              loop: true,
              controls: false,
              responsive: true,
              preload: true,
              bigPlayButton: false,
              fluid: true,
              sources: [
                {
                  src: leg.nodding_video,
                  type: "application/x-mpegURL",
                },
              ],
            };

            leg.player = videojs(
              leg.legNode,
              leg.videoJsOptions,
              function onPlayerReady() {
                // print version information at startup
                console.log("onPlayerReady", this);
              }
            );

            // device is ready
            leg.player.on("deviceReady", () => {
              console.log("device is ready!");
            });
          }
        });

        this.state.datingViewsData.map((dat, ind) => {
          if (dat.nodding_video != "") {
            dat.videoJsOptions = {
              // lookup the options in the docs for more options
              playsinline: true,
              autoplay: true,
              muted: true,
              loop: true,
              controls: false,
              responsive: true,
              preload: true,
              bigPlayButton: false,
              fluid: true,
              sources: [
                {
                  src: dat.nodding_video,
                  type: "application/x-mpegURL",
                },
              ],
            };

            dat.player = videojs(
              dat.legNode,
              dat.videoJsOptions,
              function onPlayerReady() {
                // print version information at startup
                console.log("onPlayerReady", this);
              }
            );

            // device is ready
            dat.player.on("deviceReady", () => {
              console.log("device is ready!");
            });
          }
        });
      }
    });
  }

  // navigateInteract(view) {
  //     console.log(view);
  //     this.props.history.push(`/home/interact-one/${view.id}/${view.author}`);
  // }

  render() {
    const { legacyViewsData, datingViewsData } = this.state;
    return (
      <section className="intract_outer custom_intract">
        <div className="container">
          <div className="plan_outer2 inteact5">
            <h4>
            If you're looking to see examples of Eternaviews you can create and how interaction works,
            you've come to the right place!</h4>
            <p className="click_view_pp">Click on any of these sample Eternaviews to test out the technology.</p>
            {/* <p>
              If someone you know already created an Eternaview, you may have
              received an emailed invitation or otherwise had a link shared with
              you. If so, please click that link and it will bring you to that
              Eternaview.
            </p>
            <p>
              You can also click on any of these sample Eternaviews to test out
              the technology.
            </p> */}
            <br />
            {/* {!this.props.auth.loginUserToken && <p><b>Please login to interact to public eternaviews.</b></p>} */}
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="intervir45">
                <h5>Legacy: </h5>
                <hr className="custom_hr"></hr>
                <p className="custom_heifght">
                  Interviewing a loved one for posterity
                </p>
              </div>
              {legacyViewsData &&
                legacyViewsData.map((view, index) => {
                  return (
                    <Link
                      to={{
                        pathname: `/home/interact-one/${view.id}/${view.author}`,
                      }}
                    >
                      <div
                        key={view.id}
                        className="mail_outer sawdow_inner grey5"
                      >
                        <div className="interact_div_video_upper">
                          {view.author__avatar == "" &&
                            view.nodding_video == "" && (
                              <img src={vector_audio} />
                            )}
                          {view.author__avatar != "" &&
                            view.nodding_video == "" && (
                              <img src={view.author__avatar} />
                            )}
                          {view.nodding_video != "" && (
                            <video
                              id="myVideo"
                              className="video-js vjs-default-skin interact-public"
                              ref={(node) => (view.legNode = node)}
                              options={view.videoJsOptions}
                            ></video>
                          )}
                          <Link
                            to={{
                              pathname: `/home/interact-one/${view.id}/${view.author}`,
                            }}
                            className="clickTalka mobile_interact_eternaview"
                          >
                            <i className="far fa-microphone"></i>
                            <p>Click to view!</p>
                          </Link>
                        </div>
                        {view.title.length > 30 ? (
                          <h5>{view.title.substring(0, 25)}...</h5>
                        ) : (
                          <h5>{view.title}</h5>
                        )}

                        <div className="row border42">
                          <div className="col-lg-6 col-md-12">
                            <div className="left450">
                              <p>Date created</p>
                              <h5>
                                {Moment(view.created_on).format("MM-DD-YYYY")}
                              </h5>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="left450">
                              <p>Last updated</p>
                              <h5>
                                {Moment(view.updated_on).format("MM-DD-YYYY")}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="row border42">
                          <div className="col-lg-6 col-md-12">
                            <div className="left450">
                              <p># of questions answered</p>
                              <h5>{view.total_question_answer}</h5>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="left450">
                              <p>Total Time Recorded</p>
                              <h5>{view.total_duration}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="row border42">
                          <div className="col-lg-12 col-md-12">
                            <div className="left450">
                              <p>Bio:</p>
                              <p className="dummy_tex5">
                                {view.author__bio != ""
                                  ? view.author__bio
                                  : "No bio added yet!"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="intervir45 dating56">
                <h5>Dating: </h5>
                <hr className="custom_hr"></hr>
                <p className="custom_heifght">
                  A low-pressure way to get a sense of a person you may want to
                  meet
                </p>
              </div>

              {datingViewsData.map((dating, index) => {
                return (
                  <Link
                    to={{
                      pathname: `/home/interact-one/${dating.id}/${dating.author}`,
                    }}
                  >
                    <div
                      key={dating.id}
                      className="mail_outer sawdow_inner grey5"
                    >
                      <div className="interact_div_video_upper">
                        {dating.author__avatar == "" &&
                          dating.nodding_video == "" && (
                            <img src={vector_audio} />
                          )}
                        {dating.author__avatar != "" &&
                          dating.nodding_video == "" && (
                            <img src={dating.author__avatar} />
                          )}

                        {dating.nodding_video != "" && (
                          <video
                            id="myVideo"
                            className="video-js vjs-default-skin interact-public"
                            ref={(node) => (dating.legNode = node)}
                            options={dating.videoJsOptions}
                          ></video>
                        )}
                        <Link
                          to={`/home/interact-one/934/394`}
                          className="clickTalka mobile_interact_eternaview"
                        >
                          <i className="far fa-microphone"></i>
                          <p>Click to view!</p>
                        </Link>
                      </div>
                      {dating.title.length > 30 ? (
                        <h5>{dating.title.substring(0, 25)}...</h5>
                      ) : (
                        <h5>{dating.title}</h5>
                      )}

                      <div className="row border42">
                        <div className="col-lg-6 col-md-12">
                          <div className="left450">
                            <p>Date created</p>
                            <h5>
                              {Moment(dating.created_on).format("MM-DD-YYYY")}
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="left450">
                            <p>Last updated</p>
                            <h5>
                              {Moment(dating.updated_on).format("MM-DD-YYYY")}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="row border42">
                        <div className="col-lg-6 col-md-12">
                          <div className="left450">
                            <p># of questions answered</p>
                            <h5>{dating.total_question_answer}</h5>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                          <div className="left450">
                            <p>Total Time Recorded</p>
                            <h5>{dating.total_duration}</h5>
                          </div>
                        </div>
                      </div>
                      <div className="row border42">
                        <div className="col-lg-12 col-md-12">
                          <div className="left450">
                            <p>Bio:</p>
                            <p className="dummy_tex5">
                              {dating.author__bio != ""
                                ? dating.author__bio
                                : "No bio added yet!"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal">
                    Open modal
                </button>   */}

        <div className="modal" id="myModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body">
                <div className="custtom_according">
                  <div className="bs-example">
                    <div className="accordion" id="accordionExample">
                      <div className="card">
                        <div className="card-header" id="headingOne1">
                          <h2 className="mb-0">
                            <button
                              type="button"
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#collapseOne1"
                            >
                              Icebreakers, Transitions, etc.
                              <i className="fa fa-angle-right"></i>{" "}
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseOne1"
                          className="collapse"
                          aria-labelledby="headingOne1"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body cus56">
                            <p>Please sit quietly... </p>
                            <p>Please say "Hello"</p>
                            <p>Please say "I Love You"</p>
                            <p>Please say "Goodbye</p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo2">
                          <h2 className="mb-0">
                            <button
                              type="button"
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#collapseTwo2"
                            >
                              Childhood<i className="fa fa-angle-right"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo2"
                          className="collapse"
                          aria-labelledby="headingTwo2"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <p>
                              Please sit quietly... <br />
                              Please say "Hello"
                              <br />
                              Please say "I Love You"
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree3">
                          <h2 className="mb-0">
                            <button
                              type="button"
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseThree3"
                            >
                              Icebreakers, Transitions, etc.
                              <i className="fa fa-angle-right"></i> What is CSS?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree3"
                          className="collapse"
                          aria-labelledby="headingThree3"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <p>
                              Please sit quietly... <br />
                              Please say "Hello"
                              <br />
                              Please say "I Love You"
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingFour4">
                          <h2 className="mb-0">
                            <button
                              type="button"
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#collapseFour4"
                            >
                              Childhood<i className="fa fa-angle-right"></i>
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour4"
                          className="collapse"
                          aria-labelledby="headingFour4"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <p>
                              Please sit quietly... <br />
                              Please say "Hello"
                              <br />
                              Please say "I Love You"
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
  getPublicEternaviews: bindActionCreators(getPublicEternaviews, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Interact);
