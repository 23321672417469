import React from "react";

import Header from "../components/Header";
import Content from "../components/Content";

const HeaderLayout = ({ children }) => (
  <React.Fragment>

    <Content>{children}</Content>
  </React.Fragment>
);

export default HeaderLayout;
