import React, { Component } from 'react';
import RecorderJS from 'recorder-js';
import $ from 'jquery';
import { LexRuntimeV2Client, RecognizeTextCommand, RecognizeUtteranceCommand } from "@aws-sdk/client-lex-runtime-v2"; // ES Modules import
import { getAudioStream, exportBuffer } from '../utilities/audio';
import red_mick from "../Images/red_mick.png";
import { browserName, browserVersion } from "react-device-detect";
import { saveAs } from 'file-saver';
import sample_audio from '../Sounds/no_sound.mp3';

const pako = require('pako');

class Recorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stream: null,
      recording: 0,
      recorder: null
    };
    this.startRecord = this.startRecord.bind(this);
    this.stopRecord = this.stopRecord.bind(this);
  }

  async componentDidMount() {

   this.onloadTextDummyQuery();

    let stream;

    try {
      stream = await getAudioStream();
    } catch (error) {
      // Users browser doesn't support audio.
      // Add your handler here.
      console.log(error);
    }

    this.setState({ stream });
  }

  onloadDummyQuery() {
    console.log('onloadDummyQuery audio');

    let blobaudio =  {size: 20850, type: 'application/octet-stream'};
    console.log('++++++++++++blobaudio++++++++++++++++');
    const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
      type: "application/json",
    });
    console.log(audio);
    console.log('++++++++++++wwwww++++++++++++++++');

    // Process the audio here.
    const command = new RecognizeUtteranceCommand({
      "botAliasId": "TSTALIASID",
      "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
      "localeId": "en_US",
      "inputStream": audio,
      "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
      "sessionId": "043758711856891",
      "responseContentType": "audio/mpeg"
    });  
    
    const response =   this.props.client.send(command);
    console.log('responseresponse');
    console.log(response);
    if (response.interpretations && response.interpretations.length > 0) {
      var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
      var inputTranscript = this.decompressGzip(response.inputTranscript);
    }


  }


  onloadTextDummyQuery() {
    console.log('onloadTextDummyQuery');

    let blobaudio =  {size: 20850, type: 'application/octet-stream'};
    console.log('++++++++++++blobaudio++++++++++++++++');
    const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
      type: "application/json",
    });
    console.log(audio);
    console.log('++++++++++++wwwww++++++++++++++++');

    // Process the audio here.
    const command = new RecognizeUtteranceCommand({
      "botAliasId": "TSTALIASID",
      "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
      "localeId": "en_US",
      "inputStream": 'text',
      "requestContentType": "text/plain; charset=utf-8",
      "sessionId": "043758711856891",
      "responseContentType": "audio/mpeg"
    });  
    
    const response =   this.props.client.send(command);
    console.log('responseresponse');
    console.log(response);
    if (response.interpretations && response.interpretations.length > 0) {
      var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
      var inputTranscript = this.decompressGzip(response.inputTranscript);
    }


  }


  startRecord() {
    this.setState({ recording : 1});
    
    const { stream } = this.state;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const recorder = new RecorderJS(audioContext,);
    recorder.init(stream);

    this.setState(
      {
        recorder
      },
      () => {
        recorder.start();
      }
    );
  }

  async stopRecord() {
    if(document.getElementById('audioIteractor'))
    {

    
    document.getElementById('audioIteractor').setAttribute('src',sample_audio);
    document.getElementById('audioIteractor').play();
    }
    const { recorder } = this.state;
    const { buffer } = await recorder.stop();
    const audio = exportBuffer(buffer[0]);
    const blobURL = URL.createObjectURL(audio)
    console.log('++++++++++++audio++++++++++++++++');
    console.log(audio);

    // Process the audio here.
    const command = new RecognizeUtteranceCommand({
      "botAliasId": "TSTALIASID",
      "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
      "localeId": "en_US",
      "inputStream": audio,
      "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
      "sessionId": "043758711856891",
      "responseContentType": "audio/mpeg"
    });  
   

    this.setState({
      recording: 2
    });
    const response =  await this.props.client.send(command);
     if (response.interpretations && response.interpretations.length > 0) {
      var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
      var inputTranscript = this.decompressGzip(response.inputTranscript);
      var obj={
              event:this,
              ispassed:true,
              key:inputTranscript,
              interpretations:interpretations
            }
            this.setState({
                  recording: 0
                });
            this.props.getTextAudio(obj);
          }

    // fetch('https://dict.shinedashboard.com/decoder.php', {
    //   method: 'POST', // or 'PUT'
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(response),
    // })
    //   .then(response => response.json())
    //   .then(data => {
    //     console.log('Success:', data);
    //     this.setState({
    //       recording: 0
    //     });
    //     this.props.setBotResponse(data)
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //     this.props.setBotResponse(error)
    //   });
    

    //const response = await this.props.client.send(command);

   


    

    // if (response.interpretations && response.interpretations.length > 0) {

    //   var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
    //   var inputTranscript = this.decompressGzip(response.inputTranscript);
    //   this.setState({
    //     recording: 0
    //   });
    //   console.log('intereps are',interpretations);
    //   this.props.setBotResponse({interpretations, inputTranscript});
    // }


    $('.playerIcon').removeClass('fa-play');
    $('.playerIcon').addClass('fa-pause');
    $(".play_pausebutton").show();
  
    $('#topicPopup').hide();


   }


  decompressGzip (response) {
    // Decode base64 (convert ascii to binary)
    var strData     = atob(response);

    // Convert binary string to character-number array
    var charData    = strData.split('').map(function(x){return x.charCodeAt(0);});

    // Turn number array into byte-array
    var binData     = new Uint8Array(charData);

    // Pako magic
    var data        = pako.inflate(binData);

    // Convert gunzipped byteArray back to ascii string:
    var strData     = String.fromCharCode.apply(null, new Uint16Array(data));

    // Output to console
    return strData;
  }

  render() {
    const { recording, stream } = this.state;

    // Don't show record button if their browser doesn't support it.
    if (!stream) {
      return null;
    }

    return (
      <div>
        {recording == 0 && <div className="right_mike4" onClick={this.startRecord}>
          <img src={red_mick} />
          <span>Click To Talk</span>
        </div>}
        {recording == 1 && <div className="right_mike4 bg_hover" onClick={this.stopRecord}>
          <img src={red_mick} />
          <span>Click To Stop</span>
        </div>}
        {recording == 2 && <div className="right_mike4">
          <img src={red_mick} />
          <span>Thinking...</span>
        </div>}
        {recording == 3 && <div className="right_mike4">
          <img src={red_mick} />
          <span>Responding...</span>
        </div>}
      </div>
    );
  }
}

export default Recorder;