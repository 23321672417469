import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import thankyou_icon from "../../Images/thnku-icon.png";
import thankyou_text from "../../Images/thnku-text.png";
import FacebookPixel from '../../MetaComponents/Facebookpixel';
function Thankyou(props) {
    const dispatch = useDispatch();
    const paramvalue = useParams();
    const [paramUrl, setParamUrl] = useState('');
    const auth = useSelector(state => state);

    const handleLogin = () =>{
        if(props.openLoginPopUp){
            props.openLoginPopUp()
        }
        else{
            props.history.push("/oauth/sign-in/")
        }

    }
    useEffect(() => {
   /*      if (auth.auth.loginUserToken == "") {
            props.history.push('/');
        }

        setParamUrl(paramvalue.id);
 */
    }, []);

    return (
        <>
             <FacebookPixel trackName={"CompleteRegistration"}/>
        {/* <script>
  fbq('track', 'CompleteRegistration');
</script> */}

        <section class="thankyou-outer">
            <div class="container inner-container">
                <div class={props.customClass?"row thankyou-popup-custom":"row" } >
                    <div class="col-md-8 offset-md-2 col-sm-12">
                        <div class="login-inner thankyou-outer">
                            <div class="thankyou-upper">
                                <img src={thankyou_icon} alt="" />
                            </div>
                            <div class="thankyou-bottom">
                                <img src={thankyou_text} alt="" />
                                <p class="p-text">Thank you. Please click the verification link sent to your email address.</p>
                                {/* <Link to={`/oauth/sign-in/`}> */}
                                    <button data-toggle="modal" onClick={()=>{handleLogin()}} class="Puchase_now_btn_new">Back to Login</button>
                                    {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
};

export default Thankyou;
