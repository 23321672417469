import React from "react";

const Topics = (props) => {
  return (
    <div style={props.deviceDetails.deviceOrentation === "portrait" && props.detail?.screen_mode == "landscape" ?{top:45,bottom:"inherit"}:{}}  className="topic_div" id="topic_div">
      <a id="closeButton11" className="topic_close">
        <i onClick={props.toggleTopicsDiv} className="fa fa-times" aria-hidden="true"></i>
      </a>
      <div className="bs-example">
        <div className="accordion accordion-title " id="accordionExample">
          {props?.moduleQuestions.map((curr) => (
            <div className="card">
              <button
               className="custom_accordion_button"
               onClick={()=>{props.questionVideo(curr.id)
                props.toggleTopicsDiv()
              }}
              >
              {curr.question}{" "}
            </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Topics;
