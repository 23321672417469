import React, { Component } from 'react';
import RecorderJS from 'recorder-js';
import $ from 'jquery';
import { LexRuntimeV2Client, RecognizeTextCommand, RecognizeUtteranceCommand } from "@aws-sdk/client-lex-runtime-v2"; // ES Modules import
import { getAudioStream, exportBuffer } from '../utilities/audio';
import red_mick from "../Images/red_mick.png";
import { browserName, browserVersion } from "react-device-detect";
import { saveAs } from 'file-saver';
import { convertAudioToText,getChatGptIntents,whisperAudioToText } from '../redux/actions';
import sample_audio from '../Sounds/no_sound.mp3';
import moment from 'moment';
import { connect } from "react-redux";
import * as lamejs from 'lamejs';

import { bindActionCreators } from "redux";

import 'jquery-confirm/dist/jquery-confirm.min.css';
import 'jquery-confirm/dist/jquery-confirm.min.js';

const pako = require('pako');

class RecorderTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chat_gpt_call: 0,
            sessionUID: null,
            gptResp :null,
            audioContext: null,
            analyser: null,
            mediaStream: null,
            isAnalyzing: false,
            audioStatus: 'Click "Start Analysis" to begin.',
            audioThreshold: 0.2, // Adjust this threshold as needed
            recorderbtnActive:false,
            stream: null,
            recording: 0,
            recorder: null,
            botAliasId:'TSTALIASID',
        };
        this.gptRes = null
        this.startRecord = this.startRecord.bind(this);
        this.stopRecord = this.stopRecord.bind(this);
        this.boatID='RMEHNYXF57';// lagecy default
    }   

    async componentDidMount() {

        this.onloadTextDummyQuery();

        let stream;

        try {
            stream = await getAudioStream();
        } catch (error) {
            // Users browser doesn't support audio.
            // Add your handler here.
            console.log(error);
        }

        this.setState({ stream });
    }

    onloadDummyQuery() {
        // console.log('onloadDummyQuery audio');

        let blobaudio = { size: 20850, type: 'application/octet-stream' };
        // console.log('++++++++++++blobaudio++++++++++++++++');
        const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
            type: "application/json",
        });
        // console.log(audio);
        // console.log('++++++++++++wwwww++++++++++++++++');

        // Process the audio here.
        const command = new RecognizeUtteranceCommand({
            "botAliasId": "TSTALIASID",
            "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
            "localeId": "en_US",
            "inputStream": audio,
            "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
            "sessionId": "043758711856891",
            "responseContentType": "audio/mpeg"
        });

        const response = this.props.client.send(command);
        // console.log('resp ->',response);
        // console.log(response);
        if (response.interpretations && response.interpretations.length > 0) {
            var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
            var inputTranscript = this.decompressGzip(response.inputTranscript);
        }


    }


    onloadTextDummyQuery() {
        // console.log('onloadTextDummyQuery');

        let blobaudio = { size: 20850, type: 'application/octet-stream' };
        // console.log('++++++++++++blobaudio++++++++++++++++');
        const audio = new Blob([JSON.stringify(blobaudio, null, 2)], {
            type: "application/json",
        });
        // console.log(audio);
        // console.log('++++++++++++wwwww++++++++++++++++');
        //alert(this.props.baseName)
        if(this.props.baseName=='home')
            {
                this.boatID = 'RMEHNYXF57';
                this.setState({ boatID: "RMEHNYXF57" });
            }
            else if(this.props.baseName=='dating')
            {
                this.boatID = 'QPSJB0MH2O';
                this.setState({ boatID: "QPSJB0MH2O" });
            }
            else if(this.props.baseName=='squirrel')
            {
                this.boatID = 'ZZQU09YS8F';
                this.setState({ boatID: "ZZQU09YS8F" });
            }
            else
            {
                this.boatID = 'RMEHNYXF57';
                this.setState({ boatID: "RMEHNYXF57" });
            }
        // Process the audio here.
        const command = new RecognizeUtteranceCommand({
            "botAliasId": "TSTALIASID",
           // "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
            "botId":this.boatID,
            "localeId": "en_US",
            "inputStream": 'text',
            "requestContentType": "text/plain; charset=utf-8",
            "sessionId": "043758711856891",
            "responseContentType": "audio/mpeg"
        });

        const response = this.props.client.send(command);
        // console.log(response,"<- Dummy Query response ");
        if (response.interpretations && response.interpretations.length > 0) {
            var interpretations = JSON.parse(this.decompressGzip(response.interpretations));
            var inputTranscript = this.decompressGzip(response.inputTranscript);
        }


    }


    startRecord() {
        const { stream } = this.state;

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
        this.setState({ recording: 1,recorderbtnActive:true,sessionUID: Math.floor(Math.random() * 1000000) });


        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const recorder = new RecorderJS(audioContext,);
        recorder.init(stream);

        this.setState(
            {
                recorder
            },
            () => {
                recorder.start();
            }
        );
        this.startAudioAnalysis();

    })
    .catch((error) => {
     // console.error('Error accessing the microphone:', error);
      $.alert({
        title: 'Alert!',
        content: 'Please make sure your microphone is connected and enabled.',
      });
    });
    }



  startAudioAnalysis = () => {
    this.setState({
      isAnalyzing: true,
      audioStatus: 'Listening for audio...',
    });

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const analyser = audioContext.createAnalyser();

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        analyser.fftSize = 256; // Adjust as needed
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        analyser.smoothingTimeConstant = 0.85;
        audioContext.createMediaStreamSource(stream).connect(analyser);
        let last_low_audioLevel = new Date()
        let last_high_audioLevel  = new Date()
        const updateAudioLevel = () => {
          analyser.getByteFrequencyData(dataArray);
          const audioLevel = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength / 256;
         
          if (audioLevel < this.state.audioThreshold) {
            last_low_audioLevel= new Date()
            var startTime = moment(last_high_audioLevel, 'DD-MM-YYYY hh:mm:ss');
            var endTime = moment(last_low_audioLevel, 'DD-MM-YYYY hh:mm:ss');
            var secondsDiff = endTime.diff(startTime, 'seconds');
            if(secondsDiff>4){
                this.stopRecord()
            }
          }else{
            last_high_audioLevel  = new Date()
          }

          if (this.state.isAnalyzing) {
            requestAnimationFrame(updateAudioLevel);
          }
        };

        updateAudioLevel();

        this.setState({
          audioContext,
          analyser,
          mediaStream: stream,
        });
      })
      .catch((error) => {
        console.error('Error accessing the microphone:', error);
      });
  }

  stopAudioAnalysis = () => {
    this.state.mediaStream.getTracks().forEach(track => track.stop());
    this.state.audioContext.close();

    this.setState({
      isAnalyzing: false,
      audioStatus: 'Analysis stopped.',
    });
  }


    async stopRecord() {
        this.stopAudioAnalysis()
        this.setState({ recorderbtnActive:false });

        if (document.getElementById('audioIteractor')) {


            document.getElementById('audioIteractor').setAttribute('src', sample_audio);
            document.getElementById('audioIteractor').play();
        }

      
        const { recorder } = this.state;
        // const test = await recorder.stop();
        const recorderOutput = await recorder.stop();
        const audioBuffer = recorderOutput?.buffer[0];
        const { buffer } = await recorder.stop();
        const audio = exportBuffer(audioBuffer);
        const blobURL = URL.createObjectURL(audio)
        // console.log(audio,"<- audio");
        //  Audio process with gpt audio to text conversion
        
        let formData = new FormData();
        formData.append('audio', recorderOutput.blob);
        formData.append('login_uuid',this.state.sessionUID)
      // alert(this.props.category);
        if(this.props.isPractice)
        {
            formData.append('type',  'Practice');
            this.setState({ boatID: "RMEHNYXF57" });
        }
        else  if(this.props.category=='Legacy')
        {
            formData.append('type',  'legacy');
            this.setState({ boatID: "RMEHNYXF57" });
        }
        else if(this.props.category=='Dating')
        {
            formData.append('type',  'Dating');
            this.setState({ boatID: "QPSJB0MH2O" });
            this.setState({ botAliasId: "YJ0MHSCRFG" });
        }
        else if(this.props.category=='Squirrel')
        {
            formData.append('type',  'Squirrel');
            this.setState({ boatID: "ZZQU09YS8F" });
        }
        else
        {
            formData.append('type',  'legacy');
            this.setState({ boatID: "RMEHNYXF57" });
        }
        const command = new RecognizeUtteranceCommand({
            "botAliasId": this.state.botAliasId,
           // "botId": this.props.category == 'Dating' ? "QPSJB0MH2O" : "RMEHNYXF57", // legacy - RMEHNYXF57 (Practice ones of legacy one), dating - QPSJB0MH2O
           "botId":this.state.boatID,
            "localeId": "en_US",
            "inputStream": audio,
            "requestContentType": "audio/x-l16; sample-rate=16000; channel-count=1",
            "sessionId": "043758711856891",
            "responseContentType": "audio/mpeg"
        });
        
        this.setState({
            recording: 2
        });
       
         await this.props.convertAudioToText(formData,this.props.auth.loginUserToken,async(res)=>{
            this.gptRes = res
          this.setState({gptResp:res})
        })
        await this.props.whisperAudioToText(formData,this.props.auth.loginUserToken,async(res)=>{
            this.props.textFromWhisper(res)
        //     this.gptRes = res
        //   this.setState({gptResp:res})
        })
        
        const recursiveLexCall = async () =>{
            try{
                const thirdApiResponse =await this.props.client.send(command);
                // const [audioToTextResponse, thirdApiResponse] = await Promise.all([audioToTextPromise, thirdApiPromise]);
                if(thirdApiResponse.interpretations && thirdApiResponse.interpretations.length > 0 ){
                    var interpretations = JSON.parse(this.decompressGzip(thirdApiResponse.interpretations));
                    var inputTranscript = this.decompressGzip(thirdApiResponse.inputTranscript);
                    this.processDataWitWhisper(inputTranscript,interpretations)
                    this.setState({chat_gpt_call : 0})
                    $('.playerIcon').removeClass('fa-play');
                    $('.playerIcon').addClass('fa-pause');
                    $(".play_pausebutton").show();
            
                    $('#topicPopup').hide();
                }
               
            }catch(err){
                this.setState({chat_gpt_call : this.state.chat_gpt_call + 1})
                recursiveLexCall()
            }
         }
        recursiveLexCall()
    }

    async processDataWitWhisper(inputTranscript,interpretations,audioToTextResponse){
        const obj = {
            event: this,
            ispassed: true,
            key: inputTranscript,
            interpretations: interpretations,
            // chatgpt_intent:audioToTextResponse.data.intents,
            login_uuid:this.state.sessionUID
        }
        this.setState({
            recording: 0
        });
        this.props.getTextAudio(obj);
    }

    decompressGzip(response) {
        // Decode base64 (convert ascii to binary)
        var strData = atob(response);

        // Convert binary string to character-number array
        var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });

        // Turn number array into byte-array
        var binData = new Uint8Array(charData);

        // Pako magic
        var data = pako.inflate(binData);

        // Convert gunzipped byteArray back to ascii string:
        var strData = String.fromCharCode.apply(null, new Uint16Array(data));

        // Output to console
        return strData;
    }

    render() {
        const { recording, stream } = this.state;
        // Don't show record button if their browser doesn't support it.
        // if (!stream) {
        //     return null;
        // }

        return (
            <>
                {recording == 0 && <a className={this.state.recorderbtnActive?"click_talk_btn click_talk_btn_recording" : "click_talk_btn"} href="javascript:void(0)" onClick={this.startRecord}>
                    <i className="far fa-microphone"></i>
                    Click To Talk
                </a>}
                {recording == 1 && <a className={this.state.recorderbtnActive?"click_talk_btn click_talk_btn_recording bg_hover" : "click_talk_btn"} href="javascript:void(0)" onClick={this.stopRecord}>
                    <i className="far fa-microphone"></i>
                    Click To Stop
                </a>}
                {recording == 2 && <a className="click_talk_btn" href="javascript:void(0)">
                    <i className="far fa-microphone"></i>
                    Thinking...
                </a>}
                {recording == 3 && <a className="click_talk_btn" href="javascript:void(0)">
                    <i className="far fa-microphone"></i>
                    Responding...
                </a>}
            </>
        );
    }
}

const mapStateToProps = (state) => ({ auth: state.auth });
const mapDispatchToProps = (dispatch) => ({
    getChatGptIntents:bindActionCreators(getChatGptIntents, dispatch),
    convertAudioToText: bindActionCreators(convertAudioToText, dispatch),
    whisperAudioToText: bindActionCreators(whisperAudioToText, dispatch),
});


export default connect(mapStateToProps, mapDispatchToProps) (RecorderTest);