import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "react-toastify/dist/ReactToastify.css";
import { Button, Form } from "reactstrap";

import {
  FRONTEND_ENDPOINT,
  environment,
  FaceBook_Token,
} from "../../config/connection";
import ReactPixel from "react-facebook-pixel";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { sharePixelTrack } from "../../redux/actions";
import { callback_fb_px_event } from "../../MetaComponents/FBMetaFunction";


const GiftPopUp1 = (props) => {

  const dispatch = useDispatch();
  const auth = useSelector(state => state);

  const location = useLocation()
  const [recordedBy,setRecordedBy] = useState()
  const [isOpen,setIsOpen] = useState(props.isOpen)

  const onChange = (val) =>{
    sessionStorage.setItem("giftType",val)
    setRecordedBy(val)
  }
  
  const continueTONext = () =>{
    callback_fb_px_event('AddToCart',auth,dispatch);
    setIsOpen(false)
  }

  useEffect(()=>{
    const params = new URLSearchParams(location.search);
    const giftParam = params.get('gift');
    if(giftParam == "true") {
    setRecordedBy(2)
    sessionStorage.setItem("giftType",2) 
    }
    else{
    const type = sessionStorage.getItem("giftType")
    if(type) setRecordedBy(type)
    else{
      sessionStorage.setItem("giftType",1)
      setRecordedBy(1)
    }
    }
   
    },[])


  return (
    <div
      id="giftsPopup1"
      style={{
        display: isOpen ? "block" : "none",
      }}
      className={
        isOpen ? 
     "modal fade show" : "modal fade hide"
      }
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered gift_popup_div">
        <div className="modal-content">
            <button
              type="button"
              className="close gift_header_cross"
              onClick={()=>{setIsOpen(false)}}
            >
              &times;
            </button>
          <div className="modal-body custom_body custom_modal_body">
            <div className="login-popup login-popup-custom gift_custom_login_popoup pop_gift">
              <Form autoComplete="off">
                <div className="login_inner4">
                  <h4 className={"gift_popup_title"}>
                    Recording can be done any time. Who will be recording their
                    stories?
                  </h4>
                  <div className="input_outer99 gift_popup_two_input">
                    <input
                      onChange={()=>{onChange(1)}}
                      checked={recordedBy == 1? true :false}
                      autoFocus={true}
                      type="checkbox"
                      name="email"
                      required
                      placeholder="Email"
                    />I will
                  </div>

                  <div className="input_outer99 gift_popup_two_input">
                    <input
                      onChange={()=>{onChange(2)}}
                      checked={recordedBy == 2? true :false}
                      type="checkbox"
                      name="password"
                      placeholder="Password"
                      required
                    />Someone else will (it’s a gift)
                  </div>
                  <p className="new_user5">
                    <a
                      data-toggle="modal"
                      style={{ cursor: "pointer" }}
                      className="Puchase_now_btn_new"
                      onClick={continueTONext}
                    >
                      {" "}
                      Continue to Plan Selection
                    </a>
                  </p>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GiftPopUp1;
